import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";

const CustomerReview = () => {

    const videos = [
        "https://www.youtube.com/embed/9d-TIDD623o?si=c7KQR5oByRDu5nb3",
        "https://www.youtube.com/embed/9d-TIDD623o?si=c7KQR5oByRDu5nb3",
        "https://www.youtube.com/embed/9d-TIDD623o?si=c7KQR5oByRDu5nb3",
    ];


    return (
        <div>
            <div style={{ background: "#fff092" }}>
                <div className='customer-title-1'>
                    <h3 className='customer-main-title'>VIDEOS</h3>
                    <div className='link-youtube-frame'>
                        <div className="customer-link-detail">
                            <iframe className="frame-yt-video " src="https://www.youtube.com/embed/9d-TIDD623o?si=c7KQR5oByRDu5nb3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        <div className="customer-link-detail">
                            <iframe className="frame-yt-video " src="https://www.youtube.com/embed/9d-TIDD623o?si=c7KQR5oByRDu5nb3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                        <div className="customer-link-detail">
                            <iframe className="frame-yt-video " src="https://www.youtube.com/embed/9d-TIDD623o?si=c7KQR5oByRDu5nb3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>

                    </div>

                    <div className="video-slider-container">
                        <Swiper
                            modules={[Navigation, Pagination]}
                            spaceBetween={10}
                            slidesPerView={1}
                            pagination={{ clickable: true }}
                            loop={true}
                            navigation={{
                                nextEl: ".custom-next",
                                prevEl: ".custom-prev",
                            }}
                            className="video-slider"
                        >
                            {videos.map((video, index) => (
                                <SwiperSlide key={index} className="video-slide">
                                    <div className="video-frame-wrapper">
                                        <iframe
                                            className="video-frame"
                                            src={video}
                                            title={`YouTube video ${index + 1}`}
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            referrerPolicy="strict-origin-when-cross-origin"
                                            allowFullScreen
                                        ></iframe>
                                    </div>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        {/* Custom Navigation Buttons */}
                        <button className="custom-prev">‹</button>
                        <button className="custom-next">›</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CustomerReview