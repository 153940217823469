import React, { useState } from "react";
import coupon1 from "../../Assets/Icons/coupon.png";

const AddCouponModal = ({ coupons, handleClick  , ApplyCoupon}) => {


  return (
    <div className="modal-back">
      <div className="form-container-1">
      <div className="coupon-searchbox">
          <div className="inputBox_container">
            <svg
              className="search_icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 48 48"
              alt="search icon"
            >
              <path d="M46.599 46.599a4.498 4.498 0 0 1-6.363 0l-7.941-7.941C29.028 40.749 25.167 42 21 42 9.402 42 0 32.598 0 21S9.402 0 21 0s21 9.402 21 21c0 4.167-1.251 8.028-3.342 11.295l7.941 7.941a4.498 4.498 0 0 1 0 6.363zM21 6C12.717 6 6 12.714 6 21s6.717 15 15 15c8.286 0 15-6.714 15-15S29.286 6 21 6z"></path>
            </svg>
            <input
              className="inputBox"
              id="inputBox"
              type="text"
              placeholder="Search For Products"
            />
          </div>
          <button className="search-coupon-btn-1">Apply</button>
        </div>
        <i
          style={{
            cursor: "pointer",
            fontSize: "16px",
            top: "5px",
            right: "8px",
          }}
          onClick={handleClick}
          className="fa-solid fa-xmark modal-cross"
        ></i>
        <div className="coupon-box-container">
          {coupons.map((coupon, index) => (
            <div className="coupon-box-1" key={index}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <img className="coupon-image-1" src={coupon1} alt="" />{" "}
                {coupon.discountType == "1" ? (
                  <div className="coupon-txt-box-1">
                    <p className="coupon-off-button">
                      Flat {coupon.discountValue} %
                    </p>
                    <p style={{ margin: "0" }}>
                      Flat {coupon.discountValue} % off
                    </p>
                  </div>
                ) : (
                  <div className="coupon-txt-box-1">
                    <p className="coupon-off-button">
                      Flat {coupon.discountValue}
                    </p>
                    <p style={{ margin: "0" }}>
                      Flat {coupon.discountValue} off
                    </p>
                  </div>
                )}
                <button
                  className="coupon-apply-btn-1"
                  onClick={() => ApplyCoupon(coupon)}
                >
                  APPLY
                </button>
              </div>
              <div
                className=""
                style={{ padding: "5px 0px 10px 0px", width: "100%" }}
                s
              >
                <div className="space-between">
                  <label
                    style={{
                      display: "flex",
                      alignItems: "center",
                      padding: "8px 0",
                    }}
                    className="couponName"
                  >
                    {coupon.couponName}
                  </label>
                </div>
                <div>
                  <label style={{ color: "grey", padding: "2px 0px" }}>
                    {coupon.couponMethod}
                  </label>
                  {coupon.couponTitleOrCode != "" && (
                    <>
                      <br />
                      <label style={{ color: "grey", padding: "0" }}>
                        <>
                          {coupon.couponMethodId == 1 ? (
                            <label htmlFor="">
                              Use code {coupon.couponTitleOrCode} & get flat{" "}
                              {coupon.discountValue}% off on this order.
                            </label>
                          ) : (
                            <label htmlFor="">
                              Use Title {coupon.couponTitleOrCode} & get flat{" "}
                              {coupon.discountType == "1" ? (
                                <>{coupon.discountValue}%</>
                              ) : (
                                <>{coupon.discountValue}</>
                              )}
                              off on this order.
                            </label>
                          )}
                        </>
                      </label>
                    </>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
  {/* <div className="coupon-box-container">
        <div className="coupon-box-1"> <img className="coupon-image-1" src={coupon} alt="" /> 
        <div className="coupon-txt-box-1">
          <p className="coupon-off-button">Flat 1000 </p>
          <p style={{margin:"0"}}>Flat Rs. 1000 off</p>
        </div>
          <button className="coupon-apply-btn-1">APPLY</button>
        
         </div>
        <div className="coupon-box-1"> <img className="coupon-image-1" src={coupon} alt="" /> 
        <div className="coupon-txt-box-1">
          <p className="coupon-off-button">Flat 500 </p>
          <p style={{margin:"0"}}>Flat Rs. 500 off</p>
        </div>
          <button className="coupon-apply-btn-1">APPLY</button>
        
         </div>
        <div className="coupon-box-1"> <img className="coupon-image-1" src={coupon} alt="" /> 
        <div className="coupon-txt-box-1">
          <p className="coupon-off-button">Flat 300 </p>
          <p style={{margin:"0"}}>Flat Rs. 300 off</p>
        </div>
          <button className="coupon-apply-btn-1">APPLY</button>
        
         </div>
        <div className="coupon-box-1"> <img className="coupon-image-1" src={coupon} alt="" /> 
        <div className="coupon-txt-box-1">
          <p className="coupon-off-button">Flat 200 </p>
          <p style={{margin:"0"}}>Flat Rs. 200 off</p>
        </div>
          <button className="coupon-apply-btn-1">APPLY</button>
        
         </div>
        <div className="coupon-box-1"> <img className="coupon-image-1" src={coupon} alt="" /> 
        <div className="coupon-txt-box-1">
          <p className="coupon-off-button">Flat 2000 </p>
          <p style={{margin:"0"}}>Flat Rs. 2000 off</p>
        </div>
          <button className="coupon-apply-btn-1">APPLY</button>
        
         </div>
    </div>      */}
      </div>
    </div>
  );
};

export default AddCouponModal;
