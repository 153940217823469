import React, { useEffect, useState,useRef } from "react";
import AddAddress from "../../../API/API_SERVISES/AddAddress";
import StateInfoApi from "../../../API/API_SERVISES/StateInfo";
import { useContext } from "react";
import MyContext from "../../MyContext";

const AddAddressModalProfle = (props) => {
  // const [myAccountID, setMyAccountID] = useContext(MyContext);
  const [myAccountID, setMyAccountID] = useState();


  const handleEscapeKey = () => {
    props.modalclose();
  };

  const [stateInfo, setStateInfo] = useState([]);
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const data = await StateInfoApi.GetAllState();
  //       setStateInfo(data);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };
  //   fetchData();
  // }, []);


  const [selectedCountry, setSelectedCountry] = useState(1112);
  const [selectedState, setSelectedState] = useState();

  const [countryMain, setCountryMain] = useState([
    {
      id: 1001,
      name: "Afghanistan",
    },
    {
      id: 1002,
      name: "Albania",
    },
    {
      id: 1003,
      name: "Algeria",
    },
    {
      id: 1004,
      name: "American Samoa",
    },
    {
      id: 1005,
      name: "Andorra",
    },
    {
      id: 1006,
      name: "Angola",
    },
    {
      id: 1007,
      name: "Anguilla",
    },
    {
      id: 1008,
      name: "Antarctica",
    },
    {
      id: 1009,
      name: "Antigua and Barbuda",
    },
    {
      id: 1010,
      name: "Argentina",
    },
    {
      id: 1011,
      name: "Armenia",
    },
    {
      id: 1012,
      name: "Aruba",
    },
    {
      id: 1013,
      name: "Australia",
    },
    {
      id: 1014,
      name: "Austria",
    },
    {
      id: 1015,
      name: "Azerbaijan",
    },
    {
      id: 1016,
      name: "Bahrain",
    },
    {
      id: 1017,
      name: "Bangladesh",
    },
    {
      id: 1018,
      name: "Barbados",
    },
    {
      id: 1019,
      name: "Belarus",
    },
    {
      id: 1020,
      name: "Belgium",
    },
    {
      id: 1021,
      name: "Belize",
    },
    {
      id: 1022,
      name: "Benin",
    },
    {
      id: 1023,
      name: "Bermuda",
    },
    {
      id: 1024,
      name: "Bhutan",
    },
    {
      id: 1025,
      name: "Bolivia",
    },
    {
      id: 1026,
      name: "Bosnia and Herzegovina",
    },
    {
      id: 1027,
      name: "Botswana",
    },
    {
      id: 1028,
      name: "Bouvet Island",
    },
    {
      id: 1029,
      name: "Brazil",
    },
    {
      id: 1030,
      name: "British Indian Ocean Territory",
    },
    {
      id: 1031,
      name: "Virgin Islands, British",
    },
    {
      id: 1032,
      name: "Brunei Darussalam",
    },
    {
      id: 1033,
      name: "Bulgaria",
    },
    {
      id: 1034,
      name: "Burkina Faso",
    },
    {
      id: 1035,
      name: "Myanmar",
    },
    {
      id: 1036,
      name: "Burundi",
    },
    {
      id: 1037,
      name: "Cambodia",
    },
    {
      id: 1038,
      name: "Cameroon",
    },
    {
      id: 1039,
      name: "Canada",
    },
    {
      id: 1040,
      name: "Cape Verde",
    },
    {
      id: 1041,
      name: "Cayman Islands",
    },
    {
      id: 1042,
      name: "Central African Republic",
    },
    {
      id: 1043,
      name: "Chad",
    },
    {
      id: 1044,
      name: "Chile",
    },
    {
      id: 1045,
      name: "China",
    },
    {
      id: 1046,
      name: "Christmas Island",
    },
    {
      id: 1047,
      name: "Cocos Values(Keeling) Islands",
    },
    {
      id: 1048,
      name: "Colombia",
    },
    {
      id: 1049,
      name: "Comoros",
    },
    {
      id: 1050,
      name: "Congo, The Democratic Republic of the",
    },
    {
      id: 1051,
      name: "Congo",
    },
    {
      id: 1052,
      name: "Cook Islands",
    },
    {
      id: 1053,
      name: "Costa Rica",
    },
    {
      id: 1054,
      name: "Côte d`Ivoire",
    },
    {
      id: 1055,
      name: "Croatia",
    },
    {
      id: 1056,
      name: "Cuba",
    },
    {
      id: 1057,
      name: "Cyprus",
    },
    {
      id: 1058,
      name: "Czech Republic",
    },
    {
      id: 1059,
      name: "Denmark",
    },
    {
      id: 1060,
      name: "Djibouti",
    },
    {
      id: 1061,
      name: "Dominica",
    },
    {
      id: 1062,
      name: "Dominican Republic",
    },
    {
      id: 1063,
      name: "Timor-Leste",
    },
    {
      id: 1064,
      name: "Ecuador",
    },
    {
      id: 1065,
      name: "Egypt",
    },
    {
      id: 1066,
      name: "El Salvador",
    },
    {
      id: 1067,
      name: "Equatorial Guinea",
    },
    {
      id: 1068,
      name: "Eritrea",
    },
    {
      id: 1069,
      name: "Estonia",
    },
    {
      id: 1070,
      name: "Ethiopia",
    },
    {
      id: 1072,
      name: "Falkland Islands Values(Malvinas)",
    },
    {
      id: 1073,
      name: "Faroe Islands",
    },
    {
      id: 1074,
      name: "Fiji",
    },
    {
      id: 1075,
      name: "Finland",
    },
    {
      id: 1076,
      name: "France",
    },
    {
      id: 1077,
      name: "French Guiana",
    },
    {
      id: 1078,
      name: "French Polynesia",
    },
    {
      id: 1079,
      name: "French Southern Territories",
    },
    {
      id: 1080,
      name: "Gabon",
    },
    {
      id: 1081,
      name: "Georgia",
    },
    {
      id: 1082,
      name: "Germany",
    },
    {
      id: 1083,
      name: "Ghana",
    },
    {
      id: 1084,
      name: "Gibraltar",
    },
    {
      id: 1085,
      name: "Greece",
    },
    {
      id: 1086,
      name: "Greenland",
    },
    {
      id: 1087,
      name: "Grenada",
    },
    {
      id: 1088,
      name: "Guadeloupe",
    },
    {
      id: 1089,
      name: "Guam",
    },
    {
      id: 1090,
      name: "Guatemala",
    },
    {
      id: 1091,
      name: "Guinea",
    },
    {
      id: 1092,
      name: "Guinea-Bissau",
    },
    {
      id: 1093,
      name: "Guyana",
    },
    {
      id: 1094,
      name: "Haiti",
    },
    {
      id: 1095,
      name: "Heard Island and McDonald Islands",
    },
    {
      id: 1096,
      name: "Holy See Values(Vatican City State)",
    },
    {
      id: 1097,
      name: "Honduras",
    },
    {
      id: 1098,
      name: "Hong Kong",
    },
    {
      id: 1099,
      name: "Hungary",
    },
    {
      id: 1100,
      name: "Iceland",
    },
    {
      id: 1101,
      name: "India",
    },
    {
      id: 1102,
      name: "Indonesia",
    },
    {
      id: 1103,
      name: "Iran, Islamic Republic of",
    },
    {
      id: 1104,
      name: "Iraq",
    },
    {
      id: 1105,
      name: "Ireland",
    },
    {
      id: 1106,
      name: "Israel",
    },
    {
      id: 1107,
      name: "Italy",
    },
    {
      id: 1108,
      name: "Jamaica",
    },
    {
      id: 1109,
      name: "Japan",
    },
    {
      id: 1110,
      name: "Jordan",
    },
    {
      id: 1111,
      name: "Kazakhstan",
    },
    {
      id: 1112,
      name: "Kenya",
    },
    {
      id: 1113,
      name: "Kiribati",
    },
    {
      id: 1114,
      name: "Korea, Democratic People`s Republic of",
    },
    {
      id: 1115,
      name: "Korea, Republic of",
    },
    {
      id: 1116,
      name: "Kuwait",
    },
    {
      id: 1117,
      name: "Kyrgyzstan",
    },
    {
      id: 1118,
      name: "Lao People`s Democratic Republic",
    },
    {
      id: 1119,
      name: "Latvia",
    },
    {
      id: 1120,
      name: "Lebanon",
    },
    {
      id: 1121,
      name: "Lesotho",
    },
    {
      id: 1122,
      name: "Liberia",
    },
    {
      id: 1123,
      name: "Libyan Arab Jamahiriya",
    },
    {
      id: 1124,
      name: "Liechtenstein",
    },
    {
      id: 1125,
      name: "Lithuania",
    },
    {
      id: 1126,
      name: "Luxembourg",
    },
    {
      id: 1127,
      name: "Macao",
    },
    {
      id: 1128,
      name: "Macedonia, Republic of",
    },
    {
      id: 1129,
      name: "Madagascar",
    },
    {
      id: 1130,
      name: "Malawi",
    },
    {
      id: 1131,
      name: "Malaysia",
    },
    {
      id: 1132,
      name: "Maldives",
    },
    {
      id: 1133,
      name: "Mali",
    },
    {
      id: 1134,
      name: "Malta",
    },
    {
      id: 1135,
      name: "Marshall Islands",
    },
    {
      id: 1136,
      name: "Martinique",
    },
    {
      id: 1137,
      name: "Mauritania",
    },
    {
      id: 1138,
      name: "Mauritius",
    },
    {
      id: 1139,
      name: "Mayotte",
    },
    {
      id: 1140,
      name: "Mexico",
    },
    {
      id: 1141,
      name: "Micronesia, Federated States of",
    },
    {
      id: 1142,
      name: "Moldova",
    },
    {
      id: 1143,
      name: "Monaco",
    },
    {
      id: 1144,
      name: "Mongolia",
    },
    {
      id: 1145,
      name: "Montserrat",
    },
    {
      id: 1146,
      name: "Morocco",
    },
    {
      id: 1147,
      name: "Mozambique",
    },
    {
      id: 1148,
      name: "Namibia",
    },
    {
      id: 1149,
      name: "Nauru",
    },
    {
      id: 1150,
      name: "Nepal",
    },
    {
      id: 1151,
      name: "Netherlands Antilles",
    },
    {
      id: 1152,
      name: "Netherlands",
    },
    {
      id: 1153,
      name: "New Caledonia",
    },
    {
      id: 1154,
      name: "New Zealand",
    },
    {
      id: 1155,
      name: "Nicaragua",
    },
    {
      id: 1156,
      name: "Niger",
    },
    {
      id: 1157,
      name: "Nigeria",
    },
    {
      id: 1158,
      name: "Niue",
    },
    {
      id: 1159,
      name: "Norfolk Island",
    },
    {
      id: 1160,
      name: "Northern Mariana Islands",
    },
    {
      id: 1161,
      name: "Norway",
    },
    {
      id: 1162,
      name: "Oman",
    },
    {
      id: 1163,
      name: "Pakistan",
    },
    {
      id: 1164,
      name: "Palau",
    },
    {
      id: 1165,
      name: "Palestinian Territory, Occupied",
    },
    {
      id: 1166,
      name: "Panama",
    },
    {
      id: 1167,
      name: "Papua New Guinea",
    },
    {
      id: 1168,
      name: "Paraguay",
    },
    {
      id: 1169,
      name: "Peru",
    },
    {
      id: 1170,
      name: "Philippines",
    },
    {
      id: 1171,
      name: "Pitcairn",
    },
    {
      id: 1172,
      name: "Poland",
    },
    {
      id: 1173,
      name: "Portugal",
    },
    {
      id: 1174,
      name: "Puerto Rico",
    },
    {
      id: 1175,
      name: "Qatar",
    },
    {
      id: 1176,
      name: "Romania",
    },
    {
      id: 1177,
      name: "Russian Federation",
    },
    {
      id: 1178,
      name: "Rwanda",
    },
    {
      id: 1179,
      name: "Reunion",
    },
    {
      id: 1180,
      name: "Saint Helena",
    },
    {
      id: 1181,
      name: "Saint Kitts and Nevis",
    },
    {
      id: 1182,
      name: "Saint Lucia",
    },
    {
      id: 1183,
      name: "Saint Pierre and Miquelon",
    },
    {
      id: 1184,
      name: "Saint Vincent and the Grenadines",
    },
    {
      id: 1185,
      name: "Samoa",
    },
    {
      id: 1186,
      name: "San Marino",
    },
    {
      id: 1187,
      name: "Saudi Arabia",
    },
    {
      id: 1188,
      name: "Senegal",
    },
    {
      id: 1189,
      name: "Seychelles",
    },
    {
      id: 1190,
      name: "Sierra Leone",
    },
    {
      id: 1191,
      name: "Singapore",
    },
    {
      id: 1192,
      name: "Slovakia",
    },
    {
      id: 1193,
      name: "Slovenia",
    },
    {
      id: 1194,
      name: "Solomon Islands",
    },
    {
      id: 1195,
      name: "Somalia",
    },
    {
      id: 1196,
      name: "South Africa",
    },
    {
      id: 1197,
      name: "South Georgia and the South Sandwich Islands",
    },
    {
      id: 1198,
      name: "Spain",
    },
    {
      id: 1199,
      name: "Sri Lanka",
    },
    {
      id: 1200,
      name: "Sudan",
    },
    {
      id: 1201,
      name: "Suriname",
    },
    {
      id: 1202,
      name: "Svalbard and Jan Mayen",
    },
    {
      id: 1203,
      name: "Swaziland",
    },
    {
      id: 1204,
      name: "Sweden",
    },
    {
      id: 1205,
      name: "Switzerland",
    },
    {
      id: 1206,
      name: "Syrian Arab Republic",
    },
    {
      id: 1207,
      name: "Sao Tome and Principe",
    },
    {
      id: 1208,
      name: "Taiwan",
    },
    {
      id: 1209,
      name: "Tajikistan",
    },
    {
      id: 1210,
      name: "Tanzania, United Republic of",
    },
    {
      id: 1211,
      name: "Thailand",
    },
    {
      id: 1212,
      name: "Bahamas",
    },
    {
      id: 1213,
      name: "Gambia",
    },
    {
      id: 1214,
      name: "Togo",
    },
    {
      id: 1215,
      name: "Tokelau",
    },
    {
      id: 1216,
      name: "Tonga",
    },
    {
      id: 1217,
      name: "Trinidad and Tobago",
    },
    {
      id: 1218,
      name: "Tunisia",
    },
    {
      id: 1219,
      name: "Turkey",
    },
    {
      id: 1220,
      name: "Turkmenistan",
    },
    {
      id: 1221,
      name: "Turks and Caicos Islands",
    },
    {
      id: 1222,
      name: "Tuvalu",
    },
    {
      id: 1223,
      name: "Uganda",
    },
    {
      id: 1224,
      name: "Ukraine",
    },
    {
      id: 1225,
      name: "United Arab Emirates",
    },
    {
      id: 1226,
      name: "United Kingdom",
    },
    {
      id: 1227,
      name: "United States Minor Outlying Islands",
    },
    {
      id: 1228,
      name: "United States",
    },
    {
      id: 1229,
      name: "Uruguay",
    },
    {
      id: 1230,
      name: "Uzbekistan",
    },
    {
      id: 1231,
      name: "Vanuatu",
    },
    {
      id: 1232,
      name: "Venezuela",
    },
    {
      id: 1233,
      name: "Viet Nam",
    },
    {
      id: 1234,
      name: "Virgin Islands, U.S.",
    },
    {
      id: 1235,
      name: "Wallis and Futuna",
    },
    {
      id: 1236,
      name: "Western Sahara",
    },
    {
      id: 1237,
      name: "Yemen",
    },
    {
      id: 1238,
      name: "Serbia and Montenegro",
    },
    {
      id: 1239,
      name: "Zambia",
    },
    {
      id: 1240,
      name: "Zimbabwe",
    },
    {
      id: 1241,
      name: "Åland Islands",
    },
    {
      id: 1242,
      name: "Serbia",
    },
    {
      id: 1243,
      name: "Montenegro",
    },
    {
      id: 1244,
      name: "Jersey",
    },
    {
      id: 1245,
      name: "Guernsey",
    },
    {
      id: 1246,
      name: "Isle of Man",
    },
  ]);
 
  useEffect(() => {
  const selectedCountryObj = countryMain.find((x) => x.id === selectedCountry);
  if (selectedCountryObj) {
    setInputValues((prev) => ({
      ...prev,
      countryCallCode: selectedCountryObj.countryCallCode,
    }));
  }
}, [selectedCountry]);

  const countrychange = (e) => {
    const countryId = parseInt(e.target.value);
    // console.log(countryId);
    handleInputChange(e);
    handleCountryChange(countryId);
  };

  const handleCountryChange = (countryId) => {
    // console.log(countryId);
    // debugger;
    setSelectedCountry(countryId);
    fetchData(countryId);
  };

  const fetchData = async (Cid) => {
    const filterData = [
      {
        fieldName: "CountryId",
        operatorName: "equal",
        compareValue: Cid || selectedCountry,
      },
    ];

    try {
      const state = await StateInfoApi.GetAllStateWithFilter(filterData);
      setStateInfo(state);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchData(props.data.countryCode);
  }, [props.data]);


  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Escape" || event.keyCode === 27) {
        handleEscapeKey();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      // Cleanup: Remove the event listener when the component unmounts
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  useEffect(() => {
    var loginDataString = localStorage.getItem("accountLogin");
    var loginData = JSON.parse(loginDataString);
    // console.log(loginData.d1Id);
    setMyAccountID(loginData.d1Id);
  }, [myAccountID]);

  const [inputValues, setInputValues] = useState({
    accountId: "",
    name: "",
    mobile: "",
    pincode: "",
    state: "",
    countryCallCode: "+254",
    countryCode: 1112,
    //stateCode: "",
    address: "",
    locality: "",
    city: "",
    typeOfAddress: 0,
    isDefault: "",
    isActive: true,
  });

  console.log(props.data);

  useEffect(() => {
    console.log(props.data);
    const fetchData = async () => {
      if (props.data) {
        try {
          const data = await props.data; // Assuming props.editData is a Promise
          setSelectedState(data.state);
          setSelectedCountry(data.countryCode);
          setInputValues({
            id: data.id,
            accountId: 707,
            name: data.name,
            mobile: data.mobile,
            pincode: data.pincode,
            countryCallCode: data.countryCallCode,
            countryCode: data.countryCode,
            state: data.state,
            address: data.address,
            locality: data.locality,
            city: data.city,
            typeOfAddress: data.typeOfAddress,
            isDefault: data.isDefault,
            isActive: true,
          });
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else if (props.data === "" || props.data === undefined) { // Use logical OR here
        setInputValues({
          accountId: "",
          name: "",
          mobile: "",
          pincode: "",
          state: "",
          countryCallCode: "+254",
          countryCode: 1112,
          //stateCode: "",
          address: "",
          locality: "",
          city: "",
          typeOfAddress: 0,
          isDefault: "",
          isActive: true,
        });
      }
    };
    

    fetchData();
  }, [selectedState]);

  const [errorMessages, setErrorMessages] = useState({
    name: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(`Changing ${name} to ${value}`);

    setInputValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleInputChange1 = (e) => {
    const value = parseInt(e.target.value, 10);
    setInputValues({
      ...inputValues,
      isDefault: value,
      typeOfAddress: Number(e.target.value),
    });
  };

  const handleInputChange2 = (e) => {
    const checked = e.target.checked;
    setInputValues({
      ...inputValues,
      isDefault: checked,
    });
  };

  const validateInput = (fieldName) => {
    // console.log(fieldName);
    const fieldValue = inputValues[fieldName];

    if (
      !fieldValue ||
      (typeof fieldValue === "string" && fieldValue.trim() === "")
    ) {
      setErrorMessages((prevErrors) => ({
        ...prevErrors,
        [fieldName]: `${toFriendlyName(fieldName)} field is required`,
      }));
      return false;
    }
  };
  const fieldNames = [];

  const toFriendlyName = (fieldName) => {
    return fieldName
      .replace(/([A-Z])/g, " $1")
      .replace(/^./, (str) => str.toUpperCase());
  };

  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const inputRef5 = useRef(null);

  const focusInput = (inputRef) => {
    inputRef.current.focus();
};

  const SaveBtn = async () => {
    console.log(props.data);
    let allValid = true;

    fieldNames.forEach((fieldName) => {
      const isValid = validateInput(fieldName);

      if (!isValid) {
        allValid = false;
      }
    });

    if (allValid) {
      if (props.data) {
        if(inputValues.name === ""){
          alert("Name field is Required")
          focusInput(inputRef1)
        }
        else if(inputValues.address ===""){
          alert("Address field is Required ")
          focusInput(inputRef2)
        }
        else if (inputValues.stateCode === "" && inputValues.stateCode === 0) {
          alert("Please select the state ")
          focusInput(inputRef3)
        }
        else if(inputValues.city === "" ){
          alert("City/District feild is required ")
          focusInput(inputRef4)
        }
        else if (inputValues.countryId === "") {
          alert("Country feild is required ")
          focusInput(inputRef5)
        }
        else{
          const address = await AddAddress.updateAddress(
            inputValues,
            myAccountID
          );
          if (address) {
            alert("Address Updated successfully");
            props.modalclose();
            props.fetchAddress();
          } else {
            alert("error while updating");
          }
        }
      } else {
        try {
          if(inputValues.name === ""){
            alert("Name field is Required")
            focusInput(inputRef1)
          }
          else if(inputValues.address ===""){
            alert("Address field is Required ")
            focusInput(inputRef2)
          }
          else if (inputValues.stateCode === "" || inputValues.stateCode === 0) {
            alert("Please select the state ")
            focusInput(inputRef3)
          }
          else if(inputValues.city === "" ){
            alert("City/District feild is required ")
            focusInput(inputRef4)
          }
          else if (inputValues.countryId === "") {
            alert("Country feild is required ")
            focusInput(inputRef5)
          }
          else{
          const address = await AddAddress.insertAddress(
            inputValues,
            myAccountID
          );

          if (address) {
            alert("Address added successfully");
            props.modalclose();
            props.fetchAddress();
          } else {
            // Handle case when address is not added successfully
          }
        }
        } catch (error) {
          console.error("Error inserting data:", error);
          alert("Error inserting data");
        }
      }
    } else {
      alert("Validation failed");
    }
  };

  return (
    <div className="modal-back">
      <div className="main-modal-mothers-hut ">
        <h5 style={{ color: "white", marginTop: "0", marginBottom: "5px" }}>
          ADD ADDRESS
        </h5>
        <div
          style={{ zIndex: "2" }}
          className="xmark-ms-2"
          onClick={props.modalclose}
        >
          <i className="fa-solid fa-xmark"></i>
        </div>
        <i
          onClick={props.modalclose}
          style={{ color: "black", cursor: "pointer" }}
          className="fa-solid fa-xmark card-menu-favorite dis-non"
        ></i>
        <div className=" address-flex-box">
          <div>
            <label className="addaddress_lable" htmlFor="">
              Name
            </label>
            <input
              className="addaddress_input"
              type="text"
              id=""
              onChange={handleInputChange}
              value={inputValues.name}
              name="name"
              ref={inputRef1}
            />
          </div>
          <div>
            <label className="addaddress_lable" htmlFor="">
              Mobile No
            </label>
            <div className="flex-country-address">
              <div className="country-code-top">
                <span>{inputValues.countryCallCode}</span>
              </div>
              <div className="input-country-mobile">
                <input
                  className="addaddress_input"
                  type="number"
                  onChange={handleInputChange}
                  value={inputValues.mobile}
                  name="mobile"
                  id=""
                />
              </div>
            </div>
          </div>

          <div>
            <label className="addaddress_lable" htmlFor="">
              Country
            </label>


            <select
              className="select-address"
              onChange={countrychange}
              value={inputValues.countryCode}
              name="countryCode"
              id=""
              ref={inputRef3}
            >
              {/* <option value="1001" CallCode="93">Afghanistan</option>
              <option value="1002" CallCode="355">Albania</option>
              <option value="1003" CallCode="213">Algeria</option>
              <option value="1004" CallCode="1-684">American Samoa</option>
              <option value="1005" CallCode="376">Andorra</option>
              <option value="1006" CallCode="244">Angola</option>
              <option value="1007" CallCode="1-264">Anguilla</option>
              <option value="1008" CallCode="672">Antarctica</option>
              <option value="1009" CallCode="1-268">Antigua and Barbuda</option>
              <option value="1010" CallCode="54">Argentina</option>
              <option value="1011" CallCode="374">Armenia</option>
              <option value="1012" CallCode="297">Aruba</option>
              <option value="1013" CallCode="61">Australia</option>
              <option value="1014" CallCode="43">Austria</option>
              <option value="1015" CallCode="994">Azerbaijan</option>
              <option value="1016" CallCode="973">Bahrain</option>
              <option value="1017" CallCode="880">Bangladesh</option>
              <option value="1018" CallCode="1-246">Barbados</option>
              <option value="1019" CallCode="375">Belarus</option>
              <option value="1020" CallCode="32">Belgium</option>
              <option value="1021" CallCode="501">Belize</option>
              <option value="1022" CallCode="229">Benin</option>
              <option value="1023" CallCode="1-441">Bermuda</option>
              <option value="1024" CallCode="975">Bhutan</option>
              <option value="1025" CallCode="591">Bolivia</option>
              <option value="1026" CallCode="387">Bosnia and Herzegovina</option>
              <option value="1027" CallCode="267">Botswana</option>
              <option value="1028" CallCode="">Bouvet Island</option>
              <option value="1029" CallCode="55">Brazil</option>
              <option value="1030" CallCode="246">British Indian Ocean Territory</option>
              <option value="1031" CallCode="1-284">British Virgin Islands</option>
              <option value="1032" CallCode="673">Brunei</option>
              <option value="1033" CallCode="359">Bulgaria</option>
              <option value="1034" CallCode="226">Burkina Faso</option>
              <option value="1035" CallCode="95">Myanmar</option>
              <option value="1036" CallCode="257">Burundi</option>
              <option value="1037" CallCode="855">Cambodia</option>
              <option value="1038" CallCode="237">Cameroon</option>
              <option value="1039" CallCode="1">Canada</option>
              <option value="1040" CallCode="238">Cape Verde</option>
              <option value="1041" CallCode="1-345">Cayman Islands</option>
              <option value="1042" CallCode="236">Central African Republic</option>
              <option value="1043" CallCode="235">Chad</option>
              <option value="1044" CallCode="56">Chile</option>
              <option value="1045" CallCode="86">China</option>
              <option value="1046" CallCode="61">Christmas Island</option>
              <option value="1047" CallCode="61">Cocos Islands</option>
              <option value="1048" CallCode="57">Colombia</option>
              <option value="1049" CallCode="269">Comoros</option>
              <option value="1050" CallCode="243">Congo, The Democratic Republic of the</option>
              <option value="1051" CallCode="242">Congo, Republic of the </option>
              <option value="1052" CallCode="682">Cook Islands</option>
              <option value="1053" CallCode="506">Costa Rica</option>
              <option value="1054" CallCode="599">Curacao</option>
              <option value="1055" CallCode="385">Croatia</option>
              <option value="1056" CallCode="53">Cuba</option>
              <option value="1057" CallCode="357">Cyprus</option>
              <option value="1058" CallCode="420">Czech Republic</option>
              <option value="1059" CallCode="45">Denmark</option>
              <option value="1060" CallCode="253">Djibouti</option>
              <option value="1061" CallCode="1-767">Dominica</option>
              <option value="1062" CallCode="1-809, 1-829, 1-849">Dominican Republic</option>
              <option value="1063" CallCode="670">East Timor</option>
              <option value="1064" CallCode="593">Ecuador</option>
              <option value="1065" CallCode="20">Egypt</option>
              <option value="1066" CallCode="503">El Salvador</option>
              <option value="1067" CallCode="240">Equatorial Guinea</option>
              <option value="1068" CallCode="291">Eritrea</option>
              <option value="1069" CallCode="372">Estonia</option>
              <option value="1070" CallCode="251">Ethiopia</option>
              <option value="1071" CallCode="225">Ivory Coast</option>
              <option value="1072" CallCode="500">Falkland Islands</option>
              <option value="1073" CallCode="298">Faroe Islands</option>
              <option value="1074" CallCode="679">Fiji</option>
              <option value="1075" CallCode="358">Finland</option>
              <option value="1076" CallCode="33">France</option>
              <option value="1077" CallCode="">French Guiana</option>
              <option value="1078" CallCode="689">French Polynesia</option>
              <option value="1079" CallCode="">French Southern Territories</option>
              <option value="1080" CallCode="241">Gabon</option>
              <option value="1081" CallCode="995">Georgia</option>
              <option value="1082" CallCode="49">Germany</option>
              <option value="1083" CallCode="233">Ghana</option>
              <option value="1084" CallCode="350">Gibraltar</option>
              <option value="1085" CallCode="30">Greece</option>
              <option value="1086" CallCode="299">Greenland</option>
              <option value="1087" CallCode="1-473">Grenada</option>
              <option value="1088" CallCode="1-671">Guam</option>
              <option value="1089" CallCode="502">Guatemala</option>
              <option value="1090" CallCode="44-1481">Guernsey</option>
              <option value="1091" CallCode="224">Guinea</option>
              <option value="1092" CallCode="245">Guinea-Bissau</option>
              <option value="1093" CallCode="592">Guyana</option>
              <option value="1094" CallCode="509">Haiti</option>
              <option value="1095" CallCode="">Heard Island and McDonald Islands</option>
              <option value="1096" CallCode="379">Holy See Values(Vatican City State)</option>
              <option value="1097" CallCode="504">Honduras</option>
              <option value="1098" CallCode="852">Hong Kong</option>
              <option value="1099" CallCode="36">Hungary</option>
              <option value="1100" CallCode="354">Iceland</option>
              <option value="1101" CallCode="91">India</option>
              <option value="1102" CallCode="62">Indonesia</option>
              <option value="1103" CallCode="98">Iran</option>
              <option value="1104" CallCode="964">Iraq</option>
              <option value="1105" CallCode="353">Ireland</option>
              <option value="1106" CallCode="972">Israel</option>
              <option value="1107" CallCode="39">Italy</option>
              <option value="1108" CallCode="1-876">Jamaica</option>
              <option value="1109" CallCode="81">Japan</option>
              <option value="1110" CallCode="962">Jordan</option>
              <option value="1111" CallCode="7">Kazakhstan</option>
              <option value="1112" CallCode="254">Kenya</option>
              <option value="1113" CallCode="686">Kiribati</option>
              <option value="1114" CallCode="">Korea, Democratic People Republic of</option>
              <option value="1115" CallCode="383">Kosovo</option>
              <option value="1116" CallCode="965">Kuwait</option>
              <option value="1117" CallCode="996">Kyrgyzstan</option>
              <option value="1118" CallCode="856">Laos</option>
              <option value="1119" CallCode="371">Latvia</option>
              <option value="1120" CallCode="961">Lebanon</option>
              <option value="1121" CallCode="266">Lesotho</option>
              <option value="1122" CallCode="231">Liberia</option>
              <option value="1123" CallCode="218">Libya</option>
              <option value="1124" CallCode="423">Liechtenstein</option>
              <option value="1125" CallCode="370">Lithuania</option>
              <option value="1126" CallCode="352">Luxembourg</option>
              <option value="1127" CallCode="853">Macau</option>
              <option value="1128" CallCode="389">Macedonia</option>
              <option value="1129" CallCode="261">Madagascar</option>
              <option value="1130" CallCode="265">Malawi</option>
              <option value="1131" CallCode="60">Malaysia</option>
              <option value="1132" CallCode="960">Maldives</option>
              <option value="1133" CallCode="223">Mali</option>
              <option value="1134" CallCode="356">Malta</option>
              <option value="1135" CallCode="692">Marshall Islands</option>
              <option value="1136" CallCode="">Martinique</option>
              <option value="1137" CallCode="222">Mauritania</option>
              <option value="1138" CallCode="230">Mauritius</option>
              <option value="1139" CallCode="262">Mayotte</option>
              <option value="1140" CallCode="52">Mexico</option>
              <option value="1141" CallCode="691">Micronesia</option>
              <option value="1142" CallCode="373">Moldova</option>
              <option value="1143" CallCode="377">Monaco</option>
              <option value="1144" CallCode="976">Mongolia</option>
              <option value="1145" CallCode="1-664">Montserrat</option>
              <option value="1146" CallCode="212">Morocco</option>
              <option value="1147" CallCode="258">Mozambique</option>
              <option value="1148" CallCode="264">Namibia</option>
              <option value="1149" CallCode="674">Nauru</option>
              <option value="1150" CallCode="977">Nepal</option>
              <option value="1151" CallCode="599">Netherlands Antilles</option>
              <option value="1152" CallCode="31">Netherlands</option>
              <option value="1153" CallCode="687">New Caledonia</option>
              <option value="1154" CallCode="64">New Zealand</option>
              <option value="1155" CallCode="505">Nicaragua</option>
              <option value="1156" CallCode="227">Niger</option>
              <option value="1157" CallCode="234">Nigeria</option>
              <option value="1158" CallCode="683">Niue</option>
              <option value="1159" CallCode="850">North Korea</option>
              <option value="1160" CallCode="1-670">Northern Mariana Islands</option>
              <option value="1161" CallCode="47">Norway</option>
              <option value="1162" CallCode="968">Oman</option>
              <option value="1163" CallCode="92">Pakistan</option>
              <option value="1164" CallCode="680">Palau</option>
              <option value="1165" CallCode="970">Palestine</option>
              <option value="1166" CallCode="507">Panama</option>
              <option value="1167" CallCode="675">Papua New Guinea</option>
              <option value="1168" CallCode="595">Paraguay</option>
              <option value="1169" CallCode="51">Peru</option>
              <option value="1170" CallCode="63">Philippines</option>
              <option value="1171" CallCode="64">Pitcairn</option>
              <option value="1172" CallCode="48">Poland</option>
              <option value="1173" CallCode="351">Portugal</option>
              <option value="1174" CallCode="1-787, 1-939">Puerto Rico</option>
              <option value="1175" CallCode="974">Qatar</option>
              <option value="1176" CallCode="40">Romania</option>
              <option value="1177" CallCode="7">Russia</option>
              <option value="1178" CallCode="250">Rwanda</option>
              <option value="1179" CallCode="262">Reunion</option>
              <option value="1180" CallCode="290">Saint Helena</option>
              <option value="1181" CallCode="1-869">Saint Kitts and Nevis</option>
              <option value="1182" CallCode="1-758">Saint Lucia</option>
              <option value="1183" CallCode="508">Saint Pierre and Miquelon</option>
              <option value="1184" CallCode="1-784">Saint Vincent and the Grenadines</option>
              <option value="1185" CallCode="685">Samoa</option>
              <option value="1186" CallCode="378">San Marino</option>
              <option value="1187" CallCode="966">Saudi Arabia</option>
              <option value="1188" CallCode="221">Senegal</option>
              <option value="1189" CallCode="248">Seychelles</option>
              <option value="1190" CallCode="232">Sierra Leone</option>
              <option value="1191" CallCode="65">Singapore</option>
              <option value="1192" CallCode="421">Slovakia</option>
              <option value="1193" CallCode="386">Slovenia</option>
              <option value="1194" CallCode="677">Solomon Islands</option>
              <option value="1195" CallCode="252">Somalia</option>
              <option value="1196" CallCode="27">South Africa</option>
              <option value="1197" CallCode="">South Georgia and the South Sandwich Islands</option>
              <option value="1198" CallCode="34">Spain</option>
              <option value="1199" CallCode="94">Sri Lanka</option>
              <option value="1200" CallCode="249">Sudan</option>
              <option value="1201" CallCode="597">Suriname</option>
              <option value="1202" CallCode="47">Svalbard and Jan Mayen</option>
              <option value="1203" CallCode="268">Swaziland</option>
              <option value="1204" CallCode="46">Sweden</option>
              <option value="1205" CallCode="41">Switzerland</option>
              <option value="1206" CallCode="963">Syria</option>
              <option value="1207" CallCode="239">Sao Tome and Principe</option>
              <option value="1208" CallCode="886">Taiwan</option>
              <option value="1209" CallCode="992">Tajikistan</option>
              <option value="1210" CallCode="255">Tanzania</option>
              <option value="1211" CallCode="66">Thailand</option>
              <option value="1212" CallCode="1-242">Bahamas</option>
              <option value="1213" CallCode="220">Gambia</option>
              <option value="1214" CallCode="228">Togo</option>
              <option value="1215" CallCode="690">Tokelau</option>
              <option value="1216" CallCode="676">Tonga</option>
              <option value="1217" CallCode="1-868">Trinidad and Tobago</option>
              <option value="1218" CallCode="216">Tunisia</option>
              <option value="1219" CallCode="90">Turkey</option>
              <option value="1220" CallCode="993">Turkmenistan</option>
              <option value="1221" CallCode="1-649">Turks and Caicos Islands</option>
              <option value="1222" CallCode="688">Tuvalu</option>
              <option value="1223" CallCode="256">Uganda</option>
              <option value="1224" CallCode="380">Ukraine</option>
              <option value="1225" CallCode="971">United Arab Emirates</option>
              <option value="1226" CallCode="44">United Kingdom</option>
              <option value="1227" CallCode="">United States Minor Outlying Islands</option>
              <option value="1228" CallCode="1">United States</option>
              <option value="1229" CallCode="598">Uruguay</option>
              <option value="1230" CallCode="998">Uzbekistan</option>
              <option value="1231" CallCode="678">Vanuatu</option>
              <option value="1232" CallCode="58">Venezuela</option>
              <option value="1233" CallCode="84">Vietnam</option>
              <option value="1234" CallCode="1-340">U.S. Virgin Islands</option>
              <option value="1235" CallCode="681">Wallis and Futuna</option>
              <option value="1236" CallCode="212">Western Sahara</option>
              <option value="1237" CallCode="967">Yemen</option>
              <option value="1238" CallCode="82">South Korea</option>
              <option value="1239" CallCode="260">Zambia</option>
              <option value="1240" CallCode="263">Zimbabwe</option>
              <option value="1241" CallCode="">Aland Islands</option>
              <option value="1242" CallCode="381">Serbia</option>
              <option value="1243" CallCode="382">Montenegro</option>
              <option value="1244" CallCode="44-1534">Jersey</option>
              <option value="1245" CallCode="">Guernsey</option>
              <option value="1246" CallCode="44-1624">Isle of Man</option> */}

              {countryMain !== undefined &&
                countryMain.length > 0 && (
                  <>
                    {countryMain.map((data) => (
                      <option key={data.id} value={data.id}>
                        {data.name}
                      </option>
                    ))}
                  </>
                )}
            </select>
          </div>
          <div>
            <label className="addaddress_lable" htmlFor="">
              State
            </label>
            {/* <input
              className="addaddress_input"
              type="text"
              onChange={handleInputChange}
              value={inputValues.state}
              name="state"
              id=""
            /> */}

            <select
              className="select-address"
              onChange={handleInputChange}
              value={inputValues.state || selectedState}
              name="state"
              id=""
              ref={inputRef3}
            >
              <option >
                Select State
              </option>
              {/* {(() => {
                if (stateInfo !== null && Array.isArray(stateInfo)) {
                  return stateInfo.map((data) => (
                    <option key={data.id} value={data.id}>
                      {data.stateName}
                    </option>
                  ));
                }
              })()} */}
              {(() => {
                if (
                  stateInfo &&
                  Array.isArray(stateInfo) &&
                  stateInfo.length > 0
                ) {
                  return (
                    stateInfo
                      // .filter(
                      //   (state) =>
                      //     state.countryId === selectedCountry
                      // )
                      .map((state) => (
                        <option key={state.id} value={state.id}>
                          {state.stateName}
                        </option>
                      ))
                  );
                } else {
                  return (
                    <option value="">No states available</option>
                  );
                }
              })()}
            </select>
          </div>
          <div>
            <label className="addaddress_lable" htmlFor="">
              Pincode
            </label>
            <input
              className="addaddress_input"
              type="number"
              onChange={handleInputChange}
              value={inputValues.pincode}
              name="pincode"
              id=""
            />
          </div>
          <div>
            <label className="addaddress_lable" htmlFor="">
              Address
            </label>
            <input
              className="addaddress_input"
              type="text"
              onChange={handleInputChange}
              value={inputValues.address}
              name="address"
              id=""
              ref={inputRef2}
            />
          </div>
          <div>
            <label className="addaddress_lable" htmlFor="">
              Locality/Town
            </label>
            <input
              className="addaddress_input"
              type="text"
              onChange={handleInputChange}
              value={inputValues.locality}
              name="locality"
              id=""
            />
          </div>
          <div style={{ height: "45px" }}>
            <label className="addaddress_lable" htmlFor="">
              City/District
            </label>
            <input
              className="addaddress_input"
              type="text"
              onChange={handleInputChange}
              value={inputValues.city}
              name="city"
              id=""
              ref={inputRef4}
            />
          </div>

          <div>
            <label className="addaddress_lable" htmlFor="">
              Type of address
            </label>
            {/* <div1 style={{ display: "flex" }}>
              <div1 className="addaddress_radio_box">
                <input
                  className="addaddress_radio"
                  type="radio"
                  onChange={handleInputChange}
                  value={inputValues.typeOfAddress}
                  name="typeOfAddress"
                  id=""
                />
                <label
                  style={{ marginBottom: "0" }}
                  className="addaddress_lable"
                  htmlFor=""
                >
                  Home
                </label>
              </div1>

              <div1 className="addaddress_radio_box">
                <input
                  className="addaddress_radio"
                  type="radio"
                  onChange={handleInputChange}
                  value={inputValues.typeOfAddress}
                  name="typeOfAddress"
                  id=""
                />
                <label
                  style={{ marginBottom: "0" }}
                  className="addaddress_lable"
                  htmlFor=""
                >
                  Office
                </label>
              </div1>
            </div1> */}

            <div1 style={{ display: "flex" }}>
              <div1 className="addaddress_radio_box">
                <input
                  className="addaddress_radio"
                  type="radio"
                  onChange={handleInputChange1}
                  value={0}
                  checked={inputValues.typeOfAddress === 0}
                  name="typeOfAddress"
                  id="homeRadio"
                />
                <label
                  style={{ marginBottom: "0" }}
                  className="addaddress_lable"
                  htmlFor="homeRadio"
                >
                  Home
                </label>
              </div1>

              <div1 className="addaddress_radio_box">
                <input
                  className="addaddress_radio"
                  type="radio"
                  onChange={handleInputChange1}
                  value={1}
                  checked={inputValues.typeOfAddress === 1}
                  name="typeOfAddress"
                  id="officeRadio"
                />
                <label
                  style={{ marginBottom: "0" }}
                  className="addaddress_lable"
                  htmlFor="officeRadio"
                >
                  Office
                </label>
              </div1>
            </div1>
          </div>
          <div2 className="addaddres-bottom_box">
            <input
              style={{ marginRight: "8px" }}
              type="checkbox"
              onChange={handleInputChange2}
              checked={inputValues.isDefault === true}
              name="typeOfAddress"
              id=""
            />
            <label
              className="addaddress_lable"
              style={{ marginBottom: "0", fontSize: "13px" }}
              htmlFor=""
            >
              Make this as a Defult Address
            </label>
          </div2>

          <button className="main-modal-button-1" style={{color: "white"}} onClick={SaveBtn}>
            ADD ADDRESS
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddAddressModalProfle;
