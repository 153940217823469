import React, { useState, useEffect, useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import MyContext from '../../MyContext';
import ShopBanner from '../../../API/API_SERVISES/Shopbanner';
import useIntersectionObserver from '../useIntersectionObserver';

const ContactLenseMore_Lc = (props) => {

    const { imageBaseUrl, currentUser } = useContext(MyContext);
    const { filteredData } = props;
    const navigate = useNavigate();


    const [imageSrc, setImageSrc] = useState('');

    // console.log(imageSrc);

    const loadImage = (element) => {
        if (filteredData && filteredData.length > 0) {
            setImageSrc(`${imageBaseUrl}`);
        }
    };

    const imageRef = useIntersectionObserver(loadImage, { threshold: 0.1 });

    // console.log(filteredData)n
    // const { item, index } = props;
    // console.log("itemm", item.bannerCaption)

    // const [block2Banner, setBlock2Banner] = useState([]);

    // const fetchData = async () => {
    //     // debugger
    //     if (currentUser === true) {
    //         try {
    //             const data = await ShopBanner.GetShopBanners();
    //             // const data = shopBanner;
    //             console.log(data)
    //             if (data) {
    //                 const filteredData = data.filter(item => item.imageBannerName.includes("Block2")).slice().sort((a, b) => a.bannerPriority - b.bannerPriority);
    //                 console.log("filteredData", filteredData);
    //                 setBlock2Banner(filteredData);
    //             }
    //         } catch (error) {
    //             console.error('Error fetching shop banners:', error);
    //         }
    //     }
    // }
    // useEffect(() => {
    //     fetchData();
    // }, [])

    const navigateBtn = (item) => {
        if (item.redirectTo) {
            navigate(`/productsearch?productId=${item.redirectTo}`);
        } else if (item.redirectToURL) {
            window.location.href = item.redirectToURL;
        }
    };

    const chunkedData = [];
    for (let i = 0; i < filteredData.length; i += 2) {
        chunkedData.push(filteredData.slice(i, i + 2));
    }

    return (
        <div className=''>
            {/* {block2Banner && block2Banner.map((item, index) => ( */}

            {/* <div>
                <div style={{ textAlign: "-webkit-center" }}>
                    <h4 className="Title-Name_Lc">{filteredData != null && filteredData != undefined && filteredData.length > 0 && filteredData[0].bannerCaption}</h4>
                </div>
                <div className="Four-Banner-Lc">
                    <div className="Find-Under-width-Lc" >
                        <div className="" onClick={() => navigateBtn(filteredData[0])}>
                            <img className='banner-1-style Contact-Img-Lc' ref={imageRef}
                                src={`${imageSrc}${filteredData != null && filteredData != undefined && filteredData.length > 0 && filteredData[0].bannerImageId}`} alt="" />
                        </div>
                        <div className="" onClick={() => navigateBtn(filteredData[1])}>
                            <img className='banner-1-style Contact-Img-Lc' ref={imageRef}
                                src={`${imageSrc}${filteredData != null && filteredData != undefined && filteredData.length > 0 && filteredData[1].bannerImageId}`} alt="" />
                        </div>
                    </div>
                </div>
            </div> */}

            {/* <div className="image-grid-container">
                <div className="title-container" style={{ textAlign: "center" }}>
                    <h4 className="Title-Name_Lc">{filteredData && filteredData[0]?.bannerCaption}</h4>
                </div>
                <div className="image-grid">
                    {filteredData && filteredData.length > 0 && filteredData.map((item, index) => (
                        <div className="image-block" key={index} onClick={() => navigateBtn(item)}>
                            <img
                                className="banner-style"
                                ref={imageRef}
                                src={`${imageSrc}${item.bannerImageId}`}
                                alt={item.bannerCaption}
                            />
                        </div>
                    ))}
                </div>
            </div> */}


            <div className="image-grid-container">
                {chunkedData.map((chunk, index) => (
                    <div key={index}>
                        <div className="title-container" style={{ textAlign: "-webkit-center" }}>
                            <h4 className="Title-Name_Lc">
                                {chunk[0]?.bannerCaption}
                            </h4>
                        </div>
                        <div className="image-grid">
                            {chunk.map((item, index) => (
                                <div className="image-block" key={index} onClick={() => navigateBtn(item)}>
                                    <img
                                        className="banner-style"
                                        ref={imageRef}
                                        src={`${imageSrc}${item.bannerImageId}`}
                                        alt={item.bannerCaption}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
            {/* ))} */}
        </div >
    )
}

export default ContactLenseMore_Lc;