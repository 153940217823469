import React, { useContext, useEffect, useState, useRef } from "react";
import { useSpring, animated } from "react-spring";
import grid from "../../Assets/Icons/list.png";
import grid1 from "../../Assets/Icons/visualization.png";
import MobileFilterPage from "../../Component/Modal/MobileFilterPage";
import ProductCart1 from "../../Component/Card/ProductCart1";
// import ItemMasterApi from "../../../API/API_SERVISES/ItemMaster";
import Loader1 from "../../Component/Loaders/Loader1";
//import img from "../../Assets/Images/pic7.png";
import ProductCart2 from "../../Component/Card/ProductCart2";
import ProductCart3 from "../../Component/Card/ProductCart3";
import ProductCart4 from "../../Component/Card/ProductCart4";
import ItemMaster from "../../../API/API_SERVISES/ItemMaster";
import ItemMasterApi from "../../../API/API_SERVISES/ItemMaster";
import { useLocation } from "react-router-dom";
import MultiRangeSlider from "./MultiRangeSlider";
import MyContext from "../../MyContext";
import ViewModalBox from "../../Component/Modal/ViewModal";
import ProductCart11 from "../../Component/Card/ProductCart11";
import ProductCart5_lc from "../../Component/Card/ProductCart5_lc";
import ShopBanner from "../../../API/API_SERVISES/Shopbanner";
import WhatsAppButton from "../../Component/Modal/WhatsappButton";

const ProductSearch = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const componentName = "ProductCart5_lc"; // This can be set dynamically based on your logic
  const components = {
    ProductCart11,
    ProductCart1,
    ProductCart2,
    ProductCart5_lc,
  };

  // Dynamic component name

  const [price, setPrice] = useState(false);
  const [shopFor, setShopFor] = useState(false);
  const [color, setColor] = useState(false);
  const [style, setStyle] = useState(false);
  const [shape, setShape] = useState(true);
  const [size, setSize] = useState(false);
  const [material, setMaterial] = useState(false);

  const [loading, setLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState("");
  const [mobileFilter, setMobileFilter] = useState(false);
  const [ViewModal, setViewModal] = useState(false);
  const [ProductCartView, setProductCartView] = useState(false);
  const [filterProducts, setFilterProducts] = useState(false);
  const [filteredata2, setFilteredData2] = useState([]);
  const [openAttributes, setOpenAttributes] = useState({});
  const [apiBannerData, setApiBannerData] = useState([]);
  // console.log(filteredata2)
  const [activeAttrId, setActiveAttrId] = useState([]);

  const {
    productSearch,
    setProductSearch,
    searchName,
    setSearchName,
    changeProductGrid,
    changegrid,
    setChangegrid,
    setClose,
    productlist,
    setProductList,
    attributeList,
    setAttributeList,
    imageBaseUrl,
    currentUser,
    shopBanner,
    companyData,
  } = useContext(MyContext);
  const [maxSalePrice, setMaxSalePrice] = useState(0);
  const [FilteredData, setFilteredData] = useState(productlist);
  // console.log("maxsale price", maxSalePrice);
  console.log("FilteredData", FilteredData);

  const [scrollingDiv, setScrollingDiv] = useState(false); // State to track if the div should scroll
  const divRef = useRef(null); // Ref to access the div directly
  const scrollThreshold = 200; // The height after which the div starts to scroll

  // Handle page scroll event
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > scrollThreshold) {
        setScrollingDiv(true); // Enable scrolling in the div
      } else {
        setScrollingDiv(false); // Disable scrolling in the div
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // Scroll the div programmatically if needed
  const scrollToDivBottom = () => {
    if (divRef.current) {
      divRef.current.scrollTop += 50; // Scroll the div content down by 50px
    }
  };

  useEffect(() => {
    changeProductGrid();
  }, []);

  useEffect(() => {
    console.log("product list", productlist);
    setFilteredData(productlist);
  }, [productlist]);

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const location = useLocation();
  const query = useQuery();
  const productId = query.get("productId");
  const searchValueData = location?.state?.searchValue || "";

  useEffect(() => {
    if (productId) {
      window.scrollTo(0, 0);
      fetchData2(productId);
    }
  }, [productId]);

  useEffect(() => {
    if (searchValueData && searchValueData !== "" && productlist.length > 0) {
      setLoading(true);
      // Convert both to lowercase to make it case-insensitive
      var data = productlist.filter(
        (product) =>
          product.name.toLowerCase().includes(searchValueData.toLowerCase()) ||
          product.name.includes(searchValueData) || // Check for numeric values in name
          product.name.toString().includes(searchValueData) // Check the product number
      );
      if (data.length > 0) {
        setClose(false);
        setFilteredData(data);
        setLoading(false);
      } else {
        alert("No product Found");
        setLoading(false);
      }
    }
  }, [searchValueData, productlist]);

  const fetchData2 = async () => {
    setLoading(true);
    try {
      const val = await ItemMasterApi.GetEcomItemFilterFromCategory(productId);
      console.log("This is Ecom", val);
      setAttributeList(val);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  
    const [minVal, setMinVal] = useState(0);
    const [maxVal, setMaxVal] = useState(0);
  
    useEffect(() => {
    const maxfunc = async () => {
      if (productlist && productlist?.length > 0) {
        const maxPrice = await productlist.reduce(
          (max, product) => Math.max(max, product.salePrice),
          -Infinity
        );
        setMaxSalePrice(maxPrice + 50);
      }
    };
    maxfunc();
  }, [productId]);

  // const [minVal, setMinVal] = useState(0);
  // const [maxVal, setMaxVal] = useState(0);

  useEffect(() => {
    setMaxVal(maxSalePrice);
  }, [maxSalePrice]);

  //******************* Filter ************************* */
  const fetchDataFilter = async () => {
    setLoading(true);
    if (maxVal !== 0) {
      const filteredata = [
        {
          fieldName: "salePrice",
          operatorName: "lessthan",
          compareValue: maxVal,
        },
        {
          fieldName: "salePrice",
          operatorName: "greaterthan",
          compareValue: minVal,
        },
      ];
      // if (productlist.length == 0) {
        try {
          const categoryName = await ItemMasterApi.GetItemMasterFilter(
            filteredata
          );
          console.log(categoryName);
          setFilteredData(categoryName);
          // setMaxSalePrice(0)
          setLoading(false);
          console.log("Search", searchName);
        } catch (error) {
          setLoading(false);
          console.error("Error Fetching Data:", error);
        }
      // }
      setLoading(false);
    }
  };

  // const FilteredProducts = productlist.filter(item=>(
  //   item.salePrice > minVal && item.salePrice < maxVal
  //  )) 
  //  setFilteredData(FilteredProducts);
  //   setLoading(false);
  // }
  // useEffect(() => {
  //   debugger
  //   if (productId != null) {
  //     debugger
  //     fetchDataFilter();
  //   }
  // }, [minVal, maxVal, searchName]);

  useEffect(() => {
    if (sortOrder !== "") {
      if (sortOrder === "newlyAdded") {
        // If sorting by newly added, fetch products from API in descending order
        fetchSortedProducts();
      } else {
        sortProducts(sortOrder);
      }
    }
  }, [sortOrder]);

  const sortProducts = (sortMethod) => {
    const sortedProducts = [...productlist].sort((a, b) => {
      switch (sortMethod) {
        case "priceLowToHigh":
          return (a.salePrice || 0) - (b.salePrice || 0);
        case "priceHighToLow":
          return (b.salePrice || 0) - (a.salePrice || 0);
        case "newlyAdded":
          const dateA = new Date(a.addedAt);
          const dateB = new Date(b.addedAt);
          return dateB - dateA;
        default:
          return 0;
      }
    });
    console.log(sortedProducts);
    setProductList(sortedProducts);
  };

  const filteredata = [
    {
      fieldName: "parentGrp",
      operatorName: "Equal",
      compareValue: productId,
    },
  ];
  const fetchSortedProducts = async () => {
    setLoading(true);
    try {
      // const response = await ItemMasterApi.GetItemMasterDescendingOrder(filteredata);
      const response = await ItemMasterApi.GetItemMasterFilter(filteredata);
      const sortedProducts =
        response?.data &&
        response?.data.sort((a, b) => {
          return new Date(b.addedAt) - new Date(a.addedAt);
        });
      console.log(sortedProducts);
      setProductList(sortedProducts);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching sorted products:", error);
      setLoading(false);
    }
    setLoading(false);
  };

  const handleSortChange = (event) => {
    setSortOrder(event.target.value);
  };

  //**************************Search Name*************************** */

  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     const filteredata = [
  //       {
  //         fieldName: "isActive",
  //         operatorName: "Equal",
  //         compareValue: true
  //       },
  //       {
  //         fieldName: "name",
  //         operatorName: "equal",
  //         compareValue: searchName
  //       }
  //     ]
  //     try {
  //       const categoryName = await ItemMasterApi.GetItemMasterFilter(filteredata);
  //       setProductList(categoryName)
  //       // setProductSearch(categoryName)
  //       setLoading(false);
  //       console.log("Search", searchName)
  //     } catch (error) {
  //       console.error("Error Fetching Data:", error);
  //     }
  //   };
  //   fetchData();
  // }, [searchName]);

  //***************************************************** */

  // const fetchData1 = async () => {
  //   try {
  //     const val = await ItemMasterApi.getGetItemAttributeSettings();
  //     console.log(val);
  //     setAttributeList(val);
  //     console.log("This is the attribute", attributeList)
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };
  const fadeAnimation = useSpring({
    opacity: price ? 1 : 0,
    height: price ? 80 : 0,
    config: { duration: 200 },
  });

  const fadeAnimation1 = useSpring({
    opacity: shopFor ? 1 : 0,
    height: shopFor ? 80 : 0,
    config: { duration: 200 },
  });

  const fadeAnimation2 = useSpring({
    opacity: color ? 1 : 0,
    height: color ? 80 : 0,
    config: { duration: 200 },
  });

  const fadeAnimation3 = useSpring({
    opacity: style ? 1 : 0,
    height: style ? 80 : 0,
    config: { duration: 200 },
  });

  const fadeAnimation5 = useSpring({
    opacity: size ? 1 : 0,
    height: size ? 80 : 0,
    config: { duration: 200 },
  });

  const fadeAnimation6 = useSpring({
    opacity: material ? 1 : 0,
    height: material ? 80 : 0,
    config: { duration: 200 },
  });


  const handleRangeChange =()=>{
    fetchDataFilter()
  }
  // const toggleOption = (attributeName) => {
  //   setOpenAttributes(prevState => ({
  //     ...prevState,
  //     [attributeName]: !prevState[attributeName]
  //   }));
  // };

  const showoption = () => {
    setPrice(!price);
  };

  const openmobilefilter = () => {
    setMobileFilter(!mobileFilter);
  };

  const openViewModal = () => {
    setViewModal(!ViewModal);
  };

  //************************************************************ */
  // useEffect(() => {
  //   const fetchData = async () => {
  //     setLoading(true);
  //     const filteredata1 = [
  //       {
  //         fieldName: "string",
  //         operatorName: "string",
  //         compareValue: "string"
  //       }
  //     ]
  //     const filteredata3 = filteredata2.map(item => ({
  //       parentId: item.attributeId, // Assuming parentId should be attributeId
  //       attrId: item.attrId
  //     }));
  //     try {
  //       const filteredata = {
  //         filter: filteredata1,
  //         attribute: filteredata3,
  //         pageNumber: 0,
  //         pageSize: 50000
  //       };

  //       const categoryName = await ItemMasterApi.GetItemMasterFilter(filteredata);
  //       console.log(categoryName)
  //       setFilterProducts(categoryName, productSearch)
  //       setProductList(categoryName, productSearch)
  //       setLoading(false);
  //       console.log("Search", searchName)
  //     } catch (error) {
  //       console.error("Error Fetching Data:", error);
  //     }
  //   };

  // }, []);

  // ******************Filter product on the click of attribute***************************
  // const handleCheckboxChange12 = async (attrId, attributeId, checked) => {
  //   setLoading(true);

  //   let updatedValues;

  //   if (checked) {
  //     // If checked, add the filter to filteredData2
  //     setFilteredData2(prevFilteredData2 => [
  //       ...prevFilteredData2,
  //       {
  //         parentId: attributeId,
  //         attrId: attrId,
  //       }
  //     ]);
  //     updatedValues = [
  //       ...filteredata2,
  //       {
  //         "parentId": attributeId,
  //         "attrId": attrId,
  //       }
  //     ];
  //   } else {
  //     // If unchecked, remove the filter from filteredData2
  //     setFilteredData2(prevFilteredData2 => prevFilteredData2.filter(filter => !(filter.parentId === attributeId && filter.attrId === attrId)));
  //     // Remove the filter from updatedValues
  //     updatedValues = filteredata2.filter(filter => !(filter.parentId === attributeId && filter.attrId === attrId));
  //   }
  //   const filteredata1 = [
  //     {
  //       fieldName: "string",
  //       operatorName: "string",
  //       compareValue: "string"
  //     }
  //   ];
  //   try {
  //     const filteredata = {
  //       filter: filteredata1,
  //       order: [
  //         {
  //           propertyName: "string",
  //           ascending: true
  //         }
  //       ],
  //       attribute: updatedValues,
  //       pageNumber: 0,
  //       pageSize: 50000
  //     };

  //     console.log(filteredata);

  //     const categoryName = await ItemMasterApi.GetItemMasterFilterAttribute(filteredata);
  //     setFilterProducts(categoryName);
  //     console.log(categoryName);
  //     setProductList(categoryName);
  //   } catch (error) {
  //     console.error("Error Fetching Data:", error);
  //     setLoading(false);
  //   } finally {
  //     setLoading(false);
  //   }

  // };

  useEffect(() => {
    const clearStorageOnRefresh = () => {
      localStorage.removeItem("attriButeName");
      localStorage.removeItem("attriButeId");
    };

    window.addEventListener("beforeunload", clearStorageOnRefresh);

    return () => {
      window.removeEventListener("beforeunload", clearStorageOnRefresh);
    };
  }, []);

  useEffect(() => {
    const fetchData = () => {
      const attrName = localStorage.getItem("attriButeName");
      if (attrName) {
        try {
          const parsedData = JSON.parse(attrName);
          if (Array.isArray(parsedData)) {
            parsedData.forEach((item) => {
              handleCheckboxChange12(item.attrId, item.parentId, true, false);
            });
          }
        } catch (error) {
          console.error("Error parsing localStorage data:", error);
        }
      }
    };
    fetchData();
  }, []);

  const handleCheckboxChange12 = async (
    attrId,
    attributeId,
    checked,
    isFromUseEffect
  ) => {
    setLoading(true);
    let updatedValues;

    await setFilteredData2((prevFilteredData2) => {
      if (checked) {
        updatedValues = [
          ...prevFilteredData2,
          { parentId: attributeId, attrId: attrId },
        ];
      } else {
        updatedValues = prevFilteredData2.filter(
          (filter) =>
            !(filter.parentId === attributeId && filter.attrId === attrId)
        );
      }
      if (isFromUseEffect) {
        localStorage.setItem("attriButeName", JSON.stringify(updatedValues));
      }
      return updatedValues;
    });

    const filterDataPayload = {
      filter: [
        {
          fieldName: "string",
          operatorName: "string",
          compareValue: "string",
        },
      ],
      order: [
        {
          propertyName: "string",
          ascending: true,
        },
      ],
      attribute: updatedValues,
      pageNumber: 0,
      pageSize: 50000,
    };
    try {
      console.log(filterDataPayload);
      const categoryName = await ItemMasterApi.GetItemMasterFilterAttribute(
        filterDataPayload
      );
      setFilterProducts(categoryName);
      console.log(categoryName);
      setProductList(categoryName);
    } catch (error) {
      console.error("Error Fetching Data:", error);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const [visibleItems, setVisibleItems] = useState(4);
  const defaultVisibleCount = 6;

  const toggleOption = (attributeName) => {
    setVisibleItems((prevState) => ({
      ...prevState,
      [attributeName]:
        prevState[attributeName] === defaultVisibleCount
          ? null
          : defaultVisibleCount,
    }));
  };

  const handleMoreClick = (attributeName) => {
    setVisibleItems((prevState) => ({
      ...prevState,
      [attributeName]: prevState[attributeName] + defaultVisibleCount,
    }));
  };

  const handleHideClick = (attributeName) => {
    setVisibleItems((prevState) => ({
      ...prevState,
      [attributeName]: defaultVisibleCount,
    }));
  };

  const fetchAllBannerdata = async () => {
    if (currentUser === true) {
      try {
        const filterData = [
          {
            fieldName: "showInCategoryGroup",
            operatorName: "Equal",
            compareValue: productId,
          },
          {
            fieldName: "imageBannerName",
            operatorName: "Equal",
            compareValue: "Category",
          },
        ];
        const response = await ShopBanner.GetShopBannersFilter(filterData);
        // const response = shopBanner;
        // const response = await ShopBanner.GetAllImageBannerName()
        if (response) {
          console.log("Response", response);
          setApiBannerData(response);
        }
      } catch (error) {
        alert(error);
        throw new error("Unable To fetch Data");
      }
    }
  };
  useEffect(() => {
    fetchAllBannerdata();
  }, [currentUser, shopBanner]);

  const ImageId =
    apiBannerData &&
    apiBannerData
      .filter((item) => item.showInCategoryGroup == productId)
      .map((image) => image.bannerImageId);
  console.log(ImageId);
  // console.log(attributeList.map((attribute) => (attribute.attributeChilds.map((child) => { return (child) }))))

  const [parentId, setParentId] = useState(null);
  const [childAttrId, setChildAttrId] = useState(null);
  const [attributes, setAttributes] = useState([]);

  console.log(attributeList);

  const handleParentClick = (parentId) => {
    setParentId(parentId);
    console.log("Parent ID:", parentId);
  };

  useEffect(() => {
    const fetchData = () => {
      const attrName = localStorage.getItem("attriButeId");
      if (attrName) {
        try {
          const parsedData = JSON.parse(attrName);
          if (Array.isArray(parsedData) && parsedData.length > 0) {
            setActiveAttrId(parsedData); // Set active attributes first

            // Call handleChildClick for each attribute after state is updated
            setTimeout(() => {
              parsedData.forEach((item) => {
                handleChildClick(item, false);
              });
            }, 0);
          }
        } catch (error) {
          console.error("Error parsing localStorage data:", error);
        }
      }
    };
    fetchData();
  }, []);

  const handleChildClick = async (attrId, isFromUseEffect) => {
    setLoading(true);

    console.log("activeAttrId", activeAttrId);
    // Ensure we're working with the latest state
    if (isFromUseEffect) {
      await setActiveAttrId((prevActiveAttrIds) => {
        let updatedData;
        if (prevActiveAttrIds.includes(attrId)) {
          updatedData = prevActiveAttrIds.filter((id) => id !== attrId);
        } else {
          updatedData = [...prevActiveAttrIds, attrId];
        }
        // Always update localStorage when state changes
        localStorage.setItem("attriButeId", JSON.stringify(updatedData));
        return updatedData;
      });
    }

    // setChildAttrId(attrId);
    console.log("Child Attr ID:", attrId);

    const updatedValues = [...activeAttrId, attrId]
      .filter((id, index, arr) => arr.indexOf(id) === index) // Ensure uniqueness
      .map((id) => ({
        parentId,
        attrId: id,
      }));

    const filteredata1 = [
      {
        fieldName: "string",
        operatorName: "string",
        compareValue: "string",
      },
    ];

    try {
      const filteredata = {
        filter: filteredata1,
        order: [
          {
            propertyName: "string",
            ascending: true,
          },
        ],
        attribute: updatedValues,
        pageNumber: 0,
        pageSize: 50000,
      };

      console.log(filteredata);

      const response = await ItemMasterApi.GetItemMasterFilterAttribute(
        filteredata
      );
      if (response) {
        console.log(response);
        setProductList(response);
      }
    } catch (error) {
      console.error("Error fetching filtered data:", error);
    } finally {
      setLoading(false);
    }
  };

  const seenAttributeParentIds = new Set(); // Initialize the Set outside the conditional rendering

  return (
    <>
      {/* {apiBannerData && apiBannerData.length > 0 && (
        console.log("apiBannerData", apiBannerData),
        <img
          style={{
            height: apiBannerData.length === 0 ? "0" : "max-content"
          }}
          className="Category-banner-img"
          src={apiBannerData.length > 0 ? `${imageBaseUrl}${apiBannerData.filter(item => item.showInCategoryGroup == productId).map(image => image.bannerImageId)[0]}` : ""}
          alt=""
        />
      )} */}
      <div className="category-img-top">
        {ImageId.length > 0 && (
          <img
            style={{
              height: ImageId.length === 0 ? "0" : "max-content",
            }}
            className="Category-banner-img"
            src={ImageId.length > 0 ? `${imageBaseUrl}${ImageId[0]}` : ""}
            alt=""
          />
        )}
      </div>
      <div
        className="ProductSearch"
        style={{
          marginTop:
            apiBannerData && apiBannerData?.length === 0 ? "0px" : "-6px",
        }}
      >
        <div className="ProductSearch-left">
          <div className="ProductSearch-top">
            <p>Filters</p>
          </div>
          <div className="productsearch-bottom productsearch-scroll">
            <div className="filter-heading" onClick={showoption}>
              Price-Range
              {price ? (
                <i className="fa-solid fa-caret-up option-icon1"></i>
              ) : (
                <i className="fa-solid fa-sort-down option-icon"></i>
              )}
            </div>
            {price && (
              <animated.div style={fadeAnimation} className="filter-options">
                {" "}
                <MultiRangeSlider
                  min={0}
                  handleRangeChange={handleRangeChange}
                  max={maxSalePrice}
                  handleCheckboxChange
                  minVal={minVal}
                  setMinVal={setMinVal}
                  maxVal={maxVal}
                  setMaxVal={setMaxVal}
                  onChange={({ min, max }) =>
                    console.log(`min = ${min}, max = ${max}`)
                  }
                />
              </animated.div>
            )}

            {Array.isArray(attributeList) &&
              attributeList
                .filter(
                  (attribute) =>
                    attribute.attributeName.toLowerCase() !== "colour code" &&
                    attribute.attributeName.toLowerCase() !== "color code"
                )
                .map((attribute) => (
                  <React.Fragment key={attribute.attributeName}>
                    <div
                      className="filter-heading"
                      onClick={() => {
                        toggleOption(attribute.attributeName);
                        handleParentClick(attribute.attributeId);
                      }}
                    >
                      {attribute.attributeName}
                      {visibleItems[attribute.attributeName] ? (
                        <i className="fa-solid fa-caret-up option-icon1"></i>
                      ) : (
                        <i className="fa-solid fa-sort-down option-icon"></i>
                      )}
                    </div>
                    {/* <div className="filter-options"> */}
                    <div className="filter-scroll-theme2">
                      {visibleItems[attribute.attributeName] && (
                        // <React.Fragment>
                        //   {attribute.attributeChilds.slice(0, visibleItems[attribute.attributeName]).map(child => (
                        //     <div key={child.attrId} className="filter-options">
                        //       <div className="filter-line">

                      //         {!child.imageId && (
                      //           <input
                      //             type="checkbox"
                      //             className="ui-checkbox"
                      //             value={`${child.attrId},${attribute.attributeId}`}
                      //             checked={filteredata2[`${child.attrId},${attribute.attributeId}`]}
                      //             onChange={() => handleCheckboxChange12(
                      //               child.attrId,
                      //               attribute.attributeId,
                      //               !filteredata2.some(filter => filter.parentId === attribute.attributeId && filter.attrId === child.attrId)
                      //             )}
                      //           />)}
                      //         {attribute.attributeName.toLowerCase() === 'color' && (
                      //           <div style={{ background: child.attrName }} className="filterclrset"></div>
                      //         )}
                      //         {child.imageId ? (

                      //           <animated.div className="filter-options">
                      //             <div className="style-filter-box-wrap">
                      //               <div className="style-filter-1">
                      //                 <img
                      //                   className="filter-type-image"
                      //                   src={`${imageBaseUrl}${child.imageId}`}
                      //                   alt=""
                      //                 />
                      //                 <p>{child.attrName}</p>
                      //               </div>
                      //             </div>
                      //           </animated.div>
                      //         ) : (
                      //           <p style={{ margin: "0" }}>{child.attrName}</p>
                      //         )}
                      //       </div>
                      //     </div>
                      //   ))}
                      //   {attribute.attributeChilds.length > visibleItems[attribute.attributeName] && (
                      //     <p
                      //       style={{ textAlign: "center", color: "blueviolet", cursor: "pointer" }}
                      //       onClick={() => handleMoreClick(attribute.attributeName)}
                      //     >
                      //       {attribute.attributeChilds.length - visibleItems[attribute.attributeName]} More
                      //     </p>
                      //   )}
                      //   {visibleItems[attribute.attributeName] > defaultVisibleCount && (
                      //     <p
                      //       style={{ textAlign: "center", color: "blueviolet", cursor: "pointer" }}
                      //       onClick={() => handleHideClick(attribute.attributeName)}
                      //     >
                      //       Hide
                      //     </p>
                      //   )}
                      // </React.Fragment>

                        <React.Fragment>
                          {/* Items with images */}
                          <div className="filter-options">
                            <div className="filter-line">
                              <animated.div className="filter-options">
                                <div className="style-filter-box-wrap">
                                  {attribute.attributeChildren // productId
                                    .filter(
                                      (child) => child.imageDetails.length > 0
                                    ) // Ensure there are imageDetails available
                                    .slice(
                                      0,
                                      visibleItems[attribute.attributeName]
                                    ) // Slice based on visibleItems
                                    .map((child) => {
                                      // Find the image with matching groupId or fallback to a default image
                                      const image =
                                        child.imageDetails.find(
                                          (detail) =>
                                            detail.groupId === productId
                                        ) ||
                                        child.imageDetails.find(
                                          (detail) =>
                                            !detail.groupId ||
                                            detail.groupId === "null" ||
                                            detail.groupId === "NaN"
                                        );
                                      console.log(child.attrId);
                                      return (
                                        <div
                                          key={child.attrId}
                                          className={`style-filter-1 ${
                                            activeAttrId.includes(child.attrId)
                                              ? "style-filter-1-active"
                                              : "style-filter-1"
                                          }`}
                                          onClick={() =>
                                            handleChildClick(child.attrId, true)
                                          }
                                        >
                                          <div
                                            className="filter-type-image"
                                            style={{
                                              backgroundImage: image
                                                ? `url(${imageBaseUrl}${image.id})`
                                                : "none",
                                              height: "40px",
                                              backgroundSize: "85%", // Adjust as necessary
                                              backgroundRepeat: "no-repeat",
                                              backgroundPosition: "center",
                                            }}
                                          />
                                          <p style={{ marginTop: "1px" }}>
                                            {child.attrName}
                                          </p>
                                        </div>
                                      );
                                    })}
                                </div>
                              </animated.div>
                            </div>
                          </div>

                          {/* Items without images */}
                          {/* {attribute.attributeChildren
                          .filter(child => child.imageDetails.length <= 0)
                          .slice(0, visibleItems[attribute.attributeName])
                          .map(child => (
                            <div key={child.attrId} className="filter-options">
                              <div className="filter-line">
                                <input
                                  type="checkbox"
                                  className="ui-checkbox"
                                  value={`${child.attrId},${attribute.attributeId}`}
                                  checked={filteredata2[`${child.attrId},${attribute.attributeId}`]}
                                  onChange={() => handleCheckboxChange12(
                                    child.attrId,
                                    attribute.attributeId,
                                    !filteredata2.some(filter => filter.parentId === attribute.attributeId && filter.attrId === child.attrId),
                                    true
                                  )}
                                />
                                {attribute.attributeName.toLowerCase() === 'color' && (
                                  <div style={{ background: child.attrName }} className="filterclrset"></div>
                                )}
                                <p className="filter-name-theme2" style={{ margin: "0" }}>{child.attrName}</p>
                              </div>
                            </div>
                          ))} */}
                          {attribute.attributeChildren
                            .filter((child) => !child.imageId)
                            .slice(0, visibleItems[attribute.attributeName])
                            .map((child) => {
                              const isChecked = filteredata2.some(
                                (filter) =>
                                  filter.parentId === attribute.attributeId &&
                                  filter.attrId === child.attrId
                              );

                              return (
                                <div
                                  key={`${child.attrId}-${attribute.attributeId}`}
                                  className="filter-options"
                                >
                                  <div className="filter-line">
                                    <input
                                      type="checkbox"
                                      className="ui-checkbox"
                                      value={`${child.attrId},${attribute.attributeId}`}
                                      checked={isChecked}
                                      onChange={() =>
                                        handleCheckboxChange12(
                                          child.attrId,
                                          attribute.attributeId,
                                          !isChecked,
                                          true
                                        )
                                      }
                                    />
                                    {attribute.attributeName.toLowerCase() ===
                                      "color" && (
                                      <div
                                        style={{ background: child.attrName }}
                                        className="filterclrset"
                                      ></div>
                                    )}
                                    <p style={{ margin: "0" }}>
                                      {child.attrName}
                                    </p>
                                  </div>
                                </div>
                              );
                            })}

                        {/* "More" and "Hide" buttons */}
                        {attribute.attributeChildren.length >
                          visibleItems[attribute.attributeName] && (
                          <p
                            style={{
                              textAlign: "center",
                              color: "$Theme-color",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleMoreClick(attribute.attributeName)
                            }
                          >
                            {attribute.attributeChildren.length -
                              visibleItems[attribute.attributeName]}{" "}
                            More
                          </p>
                        )}
                        {visibleItems[attribute.attributeName] >
                          defaultVisibleCount && (
                          <p
                            style={{
                              textAlign: "center",
                              color: "$Theme-color",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleHideClick(attribute.attributeName)
                            }
                          >
                            Hide
                          </p>
                        )}
                      </React.Fragment>
                    )}
                  </div>
                  {/* </div> */}
                </React.Fragment>
              ))}

            {/* <div className="filter-heading" onClick={showoption2}>
              {" "}
              Color
              {color ? (
                <i className="fa-solid fa-caret-up option-icon1"></i>
              ) : (
                <i className="fa-solid fa-sort-down option-icon"></i>
              )}
            </div>
            {color && (
              <animated.div style={fadeAnimation2} className="filter-options">
                {attributeList.map((attributeChilds, index) => (
                  <div className="filter-line" key={index}>
                    <input type="checkbox" className="ui-checkbox" id={attributeChilds.attrId} name={attributeChilds.attrName} />
                    <label style={{ color: "red" }}>{attributeChilds.attrName}</label>
                  </div>
                ))}
              </animated.div>
            )}

            <div className="filter-heading" onClick={showoption3}>
              Styles
              {style ? (
                <i className="fa-solid fa-caret-up option-icon1"></i>
              ) : (
                <i className="fa-solid fa-sort-down option-icon"></i>
              )}
            </div>
            {style && (
              <animated.div style={fadeAnimation3} className="filter-options">
                {" "}
                <div className="filter-line">
                  <input type="checkbox" className="ui-checkbox" />
                  <p style={{ margin: "0" }}>filter-name</p>
                </div>
                <div className="filter-line">
                  <input type="checkbox" className="ui-checkbox" name="" id="" />
                  <p style={{ margin: "0" }}>filter-name</p>
                </div>
                <div className="filter-line">
                  <input type="checkbox" className="ui-checkbox" name="" id="" />
                  <p style={{ margin: "0" }}>filter-name</p>
                </div>
              </animated.div>
            )}

            <div className="filter-heading" onClick={showoption5}>
              {" "}
              Size
              {size ? (
                <i className="fa-solid fa-caret-up option-icon1"></i>
              ) : (
                <i className="fa-solid fa-sort-down option-icon"></i>
              )}
            </div>
            {size && (
              <animated.div style={fadeAnimation5} className="filter-options">
                {" "}
                <div className="filter-line">
                  <input type="checkbox" className="ui-checkbox" />
                  <p style={{ margin: "0" }}>filter-name</p>
                </div>
                <div className="filter-line">
                  <input type="checkbox" className="ui-checkbox" name="" id="" />
                  <p style={{ margin: "0" }}>filter-name</p>
                </div>
                <div className="filter-line">
                  <input type="checkbox" className="ui-checkbox" name="" id="" />
                  <p style={{ margin: "0" }}>filter-name</p>
                </div>
              </animated.div>
            )}

            <div className="filter-heading" onClick={showoption6}>
              {" "}
              Material
              {material ? (
                <i className="fa-solid fa-caret-up option-icon1"></i>
              ) : (
                <i className="fa-solid fa-sort-down option-icon"></i>
              )}
            </div>
            {material && (
              <animated.div style={fadeAnimation6} className="filter-options">
                {" "}
                <div className="filter-line">
                  <input type="checkbox" className="ui-checkbox" />
                  <p style={{ margin: "0" }}>filter-name</p>
                </div>
                <div className="filter-line">
                  <input type="checkbox" className="ui-checkbox" name="" id="" />
                  <p style={{ margin: "0" }}>filter-name</p>
                </div>
                <div className="filter-line">
                  <input type="checkbox" className="ui-checkbox" name="" id="" />
                  <p style={{ margin: "0" }}>filter-name</p>
                </div>
              </animated.div>
            )} */}
          </div>
        </div>
        <div className="ProductSearch-right">
          <div className="ProductSearch-top">
            <p>Products</p>
            {/* <div>SORT BY </div> */}
            <select
              onChange={handleSortChange}
              name=""
              id=""
              className="filter-select"
            >
              <option value="newlyAdded">Sort by</option>
              {/* <option value="">Most populer</option> */}
              <option value="priceLowToHigh">Price low to high</option>
              <option value="priceHighToLow">Price high to low </option>
              {/* <option value="">Customer Reviews</option> */}
              {/* <option value="newlyAdded">Newly Added</option> */}
            </select>
          </div>
          <div
            style={{
              display: "grid",
              overflowY: scrollingDiv ? "scroll" : "hidden",
              paddingBottom: "111px",
            }}
            className="productSearch-section-wrap3"
            ref={divRef}
          >
            {companyData.isVariationItmShowInCatPage
              ? FilteredData.filter(
                  (data) =>
                    (productId === null ||
                      productId === undefined ||
                      data.ecomCategoryDetail.includes(Number(productId))) &&
                    data.published !== false
                ).map((data1) => {
                  // Get the component to render dynamically based on your logic
                  const ComponentToRender = components[componentName];

                  return (
                    <ComponentToRender
                      key={data1.d1Id}
                      items={data1}
                      isVariationItmShowInCatPage={
                        companyData.isVariationItmShowInCatPage
                      }
                      setLoading={setLoading}
                    />
                  );
                })
              : // Group items by attributeParentId
                (() => {
                  const groupedItems = {};
                  const seenAttributeParentIds = new Set();

                  FilteredData &&
                    FilteredData.forEach((data) => {
                      if (
                        (productId === null ||
                          productId === undefined ||
                          data.ecomCategoryDetail.includes(
                            Number(productId)
                          )) &&
                        data.published !== false
                      ) {
                        // Initialize the array for each attributeParentId if it doesn't exist
                        if (!groupedItems[data.attributeParentId]) {
                          groupedItems[data.attributeParentId] = [];
                        }
                        // Push the item into the corresponding array
                        groupedItems[data.attributeParentId].push(data);
                      }
                    });

                  console.log("Grouped Items:", groupedItems); // Debugging line

                  if (sortOrder == "newlyAdded") {
                    return Object.keys(groupedItems)
                      .reverse()
                      .map((attributeParentId) => {
                        if (
                          !seenAttributeParentIds.has(Number(attributeParentId))
                        ) {
                          seenAttributeParentIds.add(Number(attributeParentId));
                          const itemsList = groupedItems[attributeParentId];
                          const ComponentToRender = components[componentName];

                          // Special handling for `attributeParentId === 0`
                          if (Number(attributeParentId) === 0) {
                            return itemsList
                              .filter((item1) => item1.isAttributeItem == false)
                              .map((item) => (
                                <ComponentToRender
                                  key={item.d1Id || item.d2Id}
                                  items={item}
                                  isVariationItmShowInCatPage={
                                    companyData.isVariationItmShowInCatPage
                                  }
                                  setLoading={setLoading}
                                />
                              ));
                          }
                          // Default rendering for grouped items
                          return (
                            <ComponentToRender
                              key={attributeParentId}
                              items={itemsList[0]} // Pass the first item in the group
                              itemList={itemsList} // Pass the entire group
                              isVariationItmShowInCatPage={
                                companyData.isVariationItmShowInCatPage
                              }
                              setLoading={setLoading}
                            />
                          );
                        }
                        return null; // Skip already processed items
                      });
                  } else {
                    // Generate components paired with their sorting criteria
                    let componentPairs = Object.keys(groupedItems)
                    .reverse().map((attributeParentId) => {
                        if (
                          !seenAttributeParentIds.has(Number(attributeParentId))
                        ) {
                          seenAttributeParentIds.add(Number(attributeParentId));
                          const itemsList =
                            groupedItems[Number(attributeParentId)];
                          const ComponentToRender = components[componentName];

                          if (Number(attributeParentId) === 0) {
                            // Create components for attributeParentId === 0
                            const componentsForAttribute = itemsList
                              .filter((item1) => item1.isAttributeItem == false)
                              .map((item1) => ({
                                component: (
                                  <ComponentToRender
                                    key={item1.d1Id || item1.d2Id} // Use a unique key from the item
                                    items={item1} // Pass the current item
                                    isVariationItmShowInCatPage={
                                      companyData.isVariationItmShowInCatPage
                                    }
                                    setLoading={setLoading}
                                    isAttribute={true}
                                  />
                                ),
                                sortValue: getSortValue(item1), // Extract the sorting value
                              }));
                            return componentsForAttribute; // Return as an array
                          }

                          // Default rendering for other attributeParentIds
                          return [
                            {
                              component: (
                                <ComponentToRender
                                  key={attributeParentId}
                                  items={itemsList[0]} // Pass the first item as the main item
                                  itemList={itemsList} // Pass the entire list of items with the same attributeParentId
                                  isVariationItmShowInCatPage={
                                    companyData.isVariationItmShowInCatPage
                                  }
                                  setLoading={setLoading}
                                  isAttribute={false}
                                />
                              ),
                              sortValue: getSortValue(itemsList[0]), // Extract the sorting value
                            },
                          ];
                        }
                        return [];
                      })
                      .flat(); // Flatten the nested arrays

                    // Define a helper function to extract sorting value based on the sortOrder
                    function getSortValue(item) {
                      if (
                        sortOrder === "priceLowToHigh" ||
                        sortOrder === "priceHighToLow"
                      ) {
                        return item.salePrice || 0;
                      } else if (sortOrder === "newlyAdded") {
                        return new Date(item.addedAt || 0);
                      }
                      return 0; // Default value for sorting
                    }

                    // Sort the components based on their sorting criteria
                    const sortedComponents = componentPairs
                      .sort((a, b) => {
                        if (sortOrder === "priceLowToHigh") {
                          return a.sortValue - b.sortValue;
                        } else if (sortOrder === "priceHighToLow") {
                          return b.sortValue - a.sortValue;
                        } else if (sortOrder === "newlyAdded") {
                          return; //a.sortValue - b.sortValue; // Newest first
                        }
                        return 0;
                      })
                      .map((pair) => pair.component); // Extract the sorted components

                    return sortedComponents;
                  }
                })()}
          </div>

          {/* <div className="productSearch-section-wrap3"
            ref={divRef}
            style={{ overflowY: scrollingDiv ? 'scroll' : 'hidden' }}>
            {companyData.isVariationItmShowInCatPage ? (
              FilteredData
                .filter(item =>
                  (productId === null || productId === undefined || item.ecomCategoryDetail.includes(Number(productId))) &&
                  item.published !== false
                )
                .map(item => (
                  <ProductCart11
                    key={item.d1Id}
                    items={item}
                    isVariationItmShowInCatPage={companyData.isVariationItmShowInCatPage}
                  />
                ))
            ) : (
              // Group items by attributeParentId
              (() => {
                const groupedItems = {};
                const seenAttributeParentIds = new Set();

                FilteredData.forEach(item => {
                  if (
                    (productId === null || productId === undefined || item.ecomCategoryDetail.includes(Number(productId))) &&
                    item.published !== false) {
                    if (!groupedItems[item.attributeParentId]) {
                      groupedItems[item.attributeParentId] = [];
                    }
                    groupedItems[item.attributeParentId].push(item);
                  }
                });

                console.log("Grouped Items:", groupedItems); // Debugging line

                // Generate ProductCart11 components for each unique attributeParentId
                return Object.keys(groupedItems).map(attributeParentId => {
                  if (!seenAttributeParentIds.has(Number(attributeParentId))) {
                    seenAttributeParentIds.add(Number(attributeParentId));
                    const itemsList = groupedItems[attributeParentId];

                    return (
                      <ProductCart11
                        key={attributeParentId}
                        items={itemsList[0]} // Pass the first item as the main item
                        itemList={itemsList} // Pass the entire list of items with the same attributeParentId
                        isVariationItmShowInCatPage={companyData.isVariationItmShowInCatPage}
                      />
                    );
                  }
                  return null; // Skip if already processed
                });
              })()
            )}


          </div> */}
        </div>

        {mobileFilter && <MobileFilterPage close={openmobilefilter} maxSalePrice={maxSalePrice} setMaxSalePrice={setMaxSalePrice}    maxVal={maxVal} setMaxVal={setMaxVal} minVal={minVal} setMinVal={setMinVal}  />}
        {ViewModal && <ViewModalBox close={openViewModal} />}

        <div className="filter-mobile-btn">
          <button onClick={openmobilefilter}>
            {" "}
            <i
              style={{ marginRight: "10px" }}
              className="fa-solid fa-filter"
            ></i>{" "}
            Filter{" "}
          </button>
          <button onClick={openViewModal}>
            {" "}
            <i
              style={{ marginRight: "10px" }}
              class="fa-solid fa-grip-lines"
            ></i>{" "}
            View
          </button>
        </div>
        <WhatsAppButton
         />
        {loading && <Loader1 />}
      </div>
    </>
  );
};

export default ProductSearch;
