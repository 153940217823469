import React from 'react';
// import './WhatsAppButton.css'; 
import "../../Css/style.scss";

const WhatsAppButton = () => {
  const phoneNumber = "+918871099000"; // Replace with your actual WhatsApp phone number in international format
  const message = "Hello, I need help!";  // Optional: pre-filled message

  //const whatsappUrl = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`;
  //const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(message)}`;
  const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;

  return (
    <a href={whatsappUrl} target="_blank" className="whatsapp-chat-button">
      <i className="fab fa-whatsapp"></i>
      {/* <span>Chat with us!</span> */}
    </a>
  );
};

export default WhatsAppButton;
