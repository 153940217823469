import React, { useContext, useState, useEffect } from "react";
import "./Form.css";
import Input from "./Input/Input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCross, faL, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import AppointmentNav from "../AppointmentNav";
import MyContext from "../../../../MyContext";
import Company from "../../../../../API/API_SERVISES/Company";
import EyeCheckUpAppointment from "../../../../../API/API_SERVISES/EyeCheckUpAppointment";
import swal from "sweetalert";
import User from '../../../../../API/API_SERVISES/User';
import StateInfoApi from "../../../../../API/API_SERVISES/StateInfo";


const Form = () => {
  const { homeEyeCheckUpData } = useContext(MyContext);
  const navigate = useNavigate();

  const [timeSlots, setTimeSlots] = useState([]);
  const [cities, setCities] = useState([])
  const [dateOptions, setDateOptions] = useState([]);
  const [stores, setStores] = useState([])

  // const [lensEyeCheckUpFee, setLensEyeCheckUpFee] = useState('Free');
  // console.log(lensEyeCheckUpFee)


  useEffect(() => {
    if (homeEyeCheckUpData) {
      const { checkUpTimeSlot, eyeCheckUpUsePincode } = homeEyeCheckUpData;

      if (checkUpTimeSlot) {
        const slots = checkUpTimeSlot.split(',');
        setTimeSlots(slots);
      }

      if (eyeCheckUpUsePincode) {
        const city = eyeCheckUpUsePincode.split(',');
        setCities(city);
      }
    }
  }, [homeEyeCheckUpData]);


  useEffect(() => {

    const formatDate = (date) => {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    };


    const generateDateOptions = (limit) => {
      const dates = [];
      const currentDate = new Date();
      for (let i = 0; i < limit; i++) {
        const futureDate = new Date(currentDate);
        futureDate.setDate(currentDate.getDate() + i);
        dates.push(formatDate(futureDate));
      }
      return dates;
    };

    setDateOptions(generateDateOptions(homeEyeCheckUpData.checkUpDateSlotLimit));
  }, [homeEyeCheckUpData]);

  const fetchData = async () => {
    try {
      const response = await Company.GetCompanyForLogin()
      // console.log(response);
      setStores(response)
    } catch (error) {
      throw new Error("Unable To Fetch Data", error)
    }
  }
  useEffect(() => {
    fetchData()
  }, [])
  const lensEyeCheckUpFee = `₹ ${homeEyeCheckUpData.eyeCheckUpFeeOnline ? homeEyeCheckUpData.lensEyeCheckUpFee : "Free"}`;
  const [inputValues, setInputValues] = useState({
    // lensEyeCheckUpFee: `₹ ${homeEyeCheckUpData.lensEyeCheckUpFee}`,
    selectStore: "",
    inHomeCheckUp: "",
    inStoreCheckUp: "",
    date: '',
    time: '',
    firstName: "",
    lastName: "",
    emailId: "",
    address: "",
    areaName: "",
    availableLocation: "",
    city: "",
    stateName: "",
  })


  const handleChange = (event) => {
    const { name, value } = event.target;
    setInputValues({
      ...inputValues,
      // [name]: value === 'in-Home' ? 1 : 0,
      inHomeCheckUp: name === 'checkup' && value === 'in-Home' ? 1 : 0,
      inStoreCheckUp: name === 'checkup' && value === 'in-store' ? 1 : 0
    });
  };
  

  console.log(inputValues)

  const [stateInfo, setStateInfo] = useState();
  useEffect(() => {
      const fetchData = async () => {
          try {
              const data = await StateInfoApi.GetAllState();
              setStateInfo(data);
          } catch (error) {
              console.error("Error fetching data:", error);
          }
      };
      fetchData();
  }, []);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(`Changing ${name} to ${value}`);

    setInputValues((prevValues) => ({
        ...prevValues,
        [name]: value,
    }));
    //setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: "" }));
};

  const CreateAppointment = async (data) => {
    debugger
    if (inputValues.firstName === "" || inputValues.emailId === "" || inputValues.address === "") {
      swal({
        title: "Warning",
        text: "Please fill in all the required fields Name,Email,Address",
        icon: "warning",
        button: "OK",
        timer: 5000, // Close after 3 seconds
      });
      return;
    }

    try {
      const response = await EyeCheckUpAppointment.CreateEyeCheckUpAppointment(data)
      if (response.isSuccess) {
        await sendEmail(data);
        swal("Success", {
          icon: "success",
          timer: 3000,
          buttons: false
        })
        setTimeout(() => {
          navigate("/")
        }, 3000)
      } else {
        swal("Error", {
          icon: "error",
          text: response.errorMessages[0] + "\n Email already exist",
          timer: 3000,
          buttons: false
        });
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }


  const sendEmail = async (data) => {
    debugger
    const emailData = {
      text: `
        Appointment Details:
        --------------------
        Store: ${data.selectStore}
        Check-Up Type: ${data.inHomeCheckUp ? "In-Home CheckUp" : "In-Store CheckUp"}
        Date: ${data.date}
        Time: ${data.time}
        Name: ${data.firstName} ${data.lastName}
        Email: ${data.emailId}
        Address: ${data.address}, ${data.areaName}, ${data.city}, ${data.stateName}
        `,
      subject: `New Eye Check-Up Appointment`,
      to: "Pintuvirwani12345@gmail.com"
    };

    try {
      await User.SendEmail(emailData)
        .then(() => {
          swal({
            title: "Email Sent",
            text: "Appointment details have been emailed successfully!",
            icon: "success",
            timer: 3000,
            buttons: false,
          });
        })
        .catch((err) => {
          console.error("Failed to send email:", err.message);
          swal({
            title: "Email Failed",
            text: "Could not send appointment details via email.",
            icon: "error",
            timer: 3000,
            buttons: false,
          });
        });
    } catch (error) {
      console.error("Error in sendEmail:", error);
    }
  };



  return (
    <>
      {/* <AppointmentNav /> */}
      <div className={`main-form-appointment`}>
        <form className={`form-appointment`}>

          <div className={`nav-for-form-appointment`}>
            <div className={`div-for-heading-appointment`}>
              <h2 className={`h2-for-heading-appointment`}>
                APPOINTMENT DETAILS FOR EYE CHECK-UP
              </h2>
              <p className={`subheading-appointment`}>WE PROVIDE GOOD CUSTOMER SERVICE</p>
            </div>
            <button
              className={`btn-cross-appointment`}
              type="button"
              onClick={() => navigate("/")}
            >
              <FontAwesomeIcon icon={faXmark} />
            </button>
          </div>

          <section className={`section-for-inputs-appointment`}>
            <select className={`select-appointment`}
              value={inputValues.selectStore}
              onChange={(event) => setInputValues({ ...inputValues, selectStore: event.target.value })}
            >
              <option className={`option-appointment`}>---------------- <t /><t />SELECT STORE<t /><t />----------------</option>
              {stores.map((store) => (
                <option key={store.id} value={store.printName}>
                  {store.printName.toUpperCase()}
                </option>
              ))
              }
            </select>
            <div className={`subdiv-for-lr-appointment`}>
              <div className="appointment-grid-container">
                <div className="appointment-grid-item">
                  <Input width={"90%"}
                    placeholder={"Eye check Rs"}
                    value={lensEyeCheckUpFee}

                    readOnly
                  />
                </div>
                <div className="appointment-grid-item">
                  <div className={`checkbox-appointment`}>
                    <label className={`label-appointment`}>
                      <input
                        type="radio"
                        name="checkup"
                        value="in-Home"
                        checked={inputValues.inHomeCheckUp === 1}
                        // onChange={(event) => { setInputValues({ ...inputValues, inHomeCheckUp: event.target.value }); }}
                        onChange={handleChange}
                      /> In Home CheckUp
                    </label>
                    <label className={`label-appointment`}>
                      <input
                        type="radio"
                        name="checkup"
                        value="in-store"
                        checked={inputValues.inStoreCheckUp === 1}
                        // onChange={(event) => { setInputValues({ ...inputValues, inStoreCheckUp: event.target.value }); }}
                        onChange={handleChange}
                      /> In Store Checkup
                    </label>
                  </div>
                </div>
                <div className="appointment-grid-item">
                  <select className={`select-from-appointment`}
                    value={inputValues.date}
                    onChange={(event) => setInputValues({ ...inputValues, date: Number(event.target.value) })}
                  >
                    <option value="">---Select Date---</option>
                    {dateOptions.map((date, index) => (
                      <option key={index} value={index + 1}>
                        {date}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="appointment-grid-item">
                  <select className={`select-from-appointment`}
                    value={inputValues.time}
                    onChange={(event) => setInputValues({ ...inputValues, time: Number(event.target.value) })}
                  >
                    <option value="">---Select Time Slot---</option>
                    {timeSlots.map((slot, index) => (
                      <option key={index} value={index + 1}>
                        {slot}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="appointment-grid-item">

                  <Input placeholder={"Enter First Name"} width={"90%"}
                    value={inputValues.firstName}
                    onChange={(newvalue) => setInputValues({ ...inputValues, firstName: newvalue })}
                  />
                </div>
                <div className="appointment-grid-item">
                  <Input placeholder={"Enter Last Name"} width={"90%"}
                    value={inputValues.lastName}
                    onChange={(newvalue) => setInputValues({ ...inputValues, lastName: newvalue })}
                  />
                </div>
                <div className="appointment-grid-item">
                  <Input placeholder={"Enter Email Id"} width={"90%"}
                    value={inputValues.emailId}
                    onChange={(newvalue) => setInputValues({ ...inputValues, emailId: newvalue })}
                  />
                </div>
                <div className="appointment-grid-item">
                  <select className={`select-from-appointment`}
                    value={inputValues.availableLocation}
                    onChange={(event) => setInputValues({ ...inputValues, availableLocation: Number(event.target.value) })}
                  >
                    <option className={`option-appointment`}>---Avaliable Locations---</option>
                    {cities.map((city, index) => (
                      <option key={index} value={index + 1}>
                        {city}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="appointment-grid-item">
                  <Input placeholder={"Address"} width={"90%"}
                    value={inputValues.address}
                    onChange={(newvalue) => setInputValues({ ...inputValues, address: newvalue })}
                  />
                </div>

                <div className="appointment-grid-item">
                  <Input placeholder={"Enter Area Name"} width={"90%"}
                    value={inputValues.areaName}
                    onChange={(newvalue) => setInputValues({ ...inputValues, areaName: newvalue })}
                  />
                </div>
                <div className="appointment-grid-item">
                  <Input placeholder={"Enter City"} width={"90%"}
                    value={inputValues.city}
                    onChange={(newvalue) => setInputValues({ ...inputValues, city: newvalue })}
                  />
                </div>
                <div className="appointment-grid-item">
                  {/* <Input placeholder={"Enter State Name"} width={"90%"}
                    value={inputValues.stateName}
                    onChange={(newvalue) => setInputValues({ ...inputValues, stateName: newvalue })}
                  /> */}
                  <select className="Input-shipping-name eye-form-radius"
                    type="text"
                    onChange={handleInputChange}
                    //onChange={(newvalue) => setInputValues({ ...inputValues, stateName: newvalue })}
                    value={inputValues.stateName}
                    name="stateName"
                    
                  >
                    <option>--Select State--</option>
                    {(() => {
                      if (stateInfo !== null && Array.isArray(stateInfo)) {
                        return stateInfo.map((data) => (
                          <option key={data.id} value={data.stateName}>
                            {data.stateName}
                          </option>
                        ));
                      }
                    })()}
                  </select>
                </div>
              </div>
            </div>
            <p className={`p-for-form-appointment`}>Product Try At Home</p>
            <div className={`div-for-proceed-appointment`}>
              <button className={`btn-proceed-appointment`}
                onClick={(event) => {
                  event.preventDefault();
                  CreateAppointment(inputValues);

                }}
              >{homeEyeCheckUpData.eyeCheckUpFeeOnline ? "Proceed to Pay" : "Book Appointment"}</button>
            </div>
          </section>
        </form>
      </div >
    </>
  );
};

export default Form;
