import React, { useState, useEffect, useContext } from "react";

import MyContext from "../../../MyApp/MyContext";
import Loader1 from "../../Component/Loaders/Loader1";
import "../../Css/Theme3_LC.scss"
import img from "../../Assets/Icons/icon-arrow-left.png"
import img1 from "../../Assets/Icons/icon-arrow-right.png"
import { Link } from 'react-router-dom';
import OurBrandsJohnJacobs from "./OurBrandsJohnJacobs";
// import ShopBanner from "../../../API/API_SERVISES/Shopbanner";
import Slider from "react-slick";
import ShopBanner from "../../../API/API_SERVISES/Shopbanner";
import SliderImageCard from "../Card/SliderImageCard";

const ProductSliderImages_Lc = (props) => {

    const { imageBaseUrl, productlist, currentUser, shopBanner, ProducrDetailPageURL } = useContext(MyContext);
    const { categoryData, item } = props;

    // console.log("test1", categoryData)
    // console.log("test1", shopBanner)


    // useEffect(() => {
    //     console.log('Product List', productlist);
    // }, [productlist])



    const [startIndex, setStartIndex] = useState(0);
    const [prevStartIndex, setPrevStartIndex] = useState(0);
    const [showPrevArrow, setShowPrevArrow] = useState(false);
    const [showNextArrow, setShowNextArrow] = useState(true);

    const [startIndexUnder, setStartIndexUnder] = useState(0);
    const [showPrevArrowUnder, setShowPrevArrowUnder] = useState(false);
    const [showNextArrowUnder, setShowNextArrowUnder] = useState(true);

    // const [backgroundSize, setBackgroundSize] = useState('90%');

    const visibleItems = productlist && productlist.filter(item => item.showOnHomePage && item.images && item.images.length > 0 && item.parentGrpId2 === props.idNumber);
    const visibleItems1 = productlist && productlist.filter(item => item.showOnHomePage && item.images && item.images.length > 0 && item.parentGrpId2 === props.idsunglass);

    // console.log(visibleItems)


    const handlePrevClick = () => {
        if (startIndex > 0) {
            const newStartIndex = Math.max(0, startIndex - 3);  // Ensure the index doesn't go negative
            setStartIndex(newStartIndex);
            setShowNextArrow(true);  // Always enable the next arrow if we can go back
            setShowPrevArrow(newStartIndex > 0);  // Only enable the previous arrow if there's more to see
        }
    };

    const handleNextClick = () => {

        if (startIndex < visibleItems.length - 3) {
            const newStartIndex = startIndex + 3;
            setStartIndex(newStartIndex);
            setShowPrevArrow(true);  // Always enable the prev arrow if we can go forward
            setShowNextArrow(newStartIndex + 3 < visibleItems.length);  // Check if there's more to see
        }
    };

    //********************************************************** */

    const handlePrevClickUnder = () => {
        if (startIndexUnder > 0) {
            const newStartIndex = Math.max(0, startIndexUnder - 3);  // Ensure the index doesn't go negative
            setStartIndexUnder(newStartIndex);
            setShowNextArrowUnder(true);  // Always enable the next arrow if we can go back
            setShowPrevArrowUnder(newStartIndex > 0);  // Only enable the previous arrow if there's more to see
        }
    };

    const handleNextClickUnder = () => {
        if (startIndexUnder < visibleItems1.length - 3) {
            const newStartIndex = startIndexUnder + 3;
            setStartIndexUnder(newStartIndex);
            setShowPrevArrowUnder(true);  // Always enable the prev arrow if we can go forward
            setShowNextArrowUnder(newStartIndex + 3 < visibleItems1.length);  // Check if there's more to see
        }
    };

    // var settings = {
    //     // dots: true,
    //     infinite: true,
    //     speed: 500,
    //     slidesToShow: 4,
    //     slidesToScroll: 1,
    //     autoplay: true,
    //     autoplaySpeed: 2000,
    // };
    // console.log(productlist);






    // useEffect(() => {
    //     const handleResize = () => {
    //         if (window.innerWidth <= 700) {
    //             setBackgroundSize('90%');
    //         } else {
    //             setBackgroundSize('90%');
    //         }
    //     };

    //     // Set initial size
    //     handleResize();

    //     // Add event listener
    //     window.addEventListener('resize', handleResize);

    //     // Cleanup event listener on component unmount
    //     return () => window.removeEventListener('resize', handleResize);
    // }, []);





    const [banner, setBanner] = useState([]);

    const fetchData = async () => {
        if (currentUser === true) {
            try {
                const data = await ShopBanner.GetShopBanners();
                // const data = shopBanner;
                if (data) {
                    const filteredData = data.filter(item => item.imageBannerName.includes(categoryData.name)).slice().sort((a, b) => a.bannerPriority - b.bannerPriority);
                    setBanner(filteredData);
                }
            } catch (error) {
                console.error('Error fetching shop banners:', error);
            }
        }
    }
    useEffect(() => {
        fetchData();
    }, [currentUser])


    // console.log(productlist.map((item)=> item.images[]))
    const initialSlideIndex = 0;
    const [currentSlide, setCurrentSlide] = useState(initialSlideIndex);
    const [totalSlides, setTotalSlides] = useState(0);
    const [slidesLoaded, setSlidesLoaded] = useState(false);


    useEffect(() => {
        const fetchSlides = () => {
            // debugger
            if (currentUser === true) {
                const slickList = document.querySelector('.slick-list');
                if (slickList) {
                    const slickTrack = slickList.querySelector('.slick-track');
                    if (slickTrack) {
                        const slides = slickTrack.children;
                        // console.log("slides length", slides.length)
                        setTotalSlides(slides.length);
                        setSlidesLoaded(true);
                        // console.log(`Total slides: ${slides.length}`);
                    }
                }
            }
        };

        // Fetch slides after a short delay to ensure DOM readiness
        setTimeout(fetchSlides, 2500); // Adjust delay as needed
    }, [currentUser]);


    useEffect(() => {
        if (slidesLoaded) {
            // Force re-render by updating a state
            setCurrentSlide(currentSlide);
        }
    }, [slidesLoaded, currentSlide]);


    // const ArrowButtonPrevious = ({ onClick }) => {
    //     return (
    //         <button
    //             onClick={onClick}
    //             style={{ backgroundColor: "transparent", border: "none" }}
    //         >
    //             <img
    //                 src={img}
    //                 alt={"Left Arrow"}
    //                 style={{
    //                     width: "100px",
    //                     height: "100px",

    //                 }}
    //             />
    //         </button>
    //     );
    // };

    // const ArrowButtonNext = ({ onClick }) => {

    //     return (
    //         <button
    //             onClick={onClick}
    //             style={{ backgroundColor: "transparent", border: "none" }}
    //         >
    //             <img
    //                 src={img1}
    //                 alt={"Left Arrow"}
    //                 style={{
    //                     width: "100px",
    //                     height: "100px",

    //                 }}
    //             />
    //         </button>
    //     );
    // };



    //**************************************************************/


    const ArrowButtonPrevious = ({ onClick, disabled }) => {
        return (
            <button className="arrow-btn-position"
                onClick={onClick}
                style={{ backgroundColor: "transparent", border: "none", display: disabled ? 'none' : 'block' }}
            >
                <img className="Arrow-btn-prev"
                    src={img}
                    alt={"Left Arrow"}
                    style={{
                        width: "75px",
                        height: "75px",
                    }}
                />
            </button>
        );
    };

    const ArrowButtonNext = ({ onClick, disabled }) => {
        return (
            <button className="arrow-btn-position"
                onClick={onClick}
                style={{ backgroundColor: "transparent", border: "none", display: disabled ? 'none' : 'block' }}
            >
                <img className="Arrow-btn-next"
                    src={img1}
                    alt={"Right Arrow"}
                    style={{
                        width: "75px",
                        height: "75px",
                    }}
                />
            </button>
        );
    };



    const settings = {
        // dots: true,
        // fade: true,
        infinite: false,
        initialSlide: initialSlideIndex,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        // prevArrow: <ArrowButtonPrevious />,
        // nextArrow: <ArrowButtonNext />,
        beforeChange: (current, next) => setCurrentSlide(next),
        afterChange: (current) => setCurrentSlide(current),
        prevArrow: <ArrowButtonPrevious />,
        nextArrow: <ArrowButtonNext />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                }
            }
        ],

    };



    return (
        <div>
            <div className="" style={{ marginTop: "10px" }}>
                {/* <span style={{ display: item.nameInHomePage == "" ? "none" : "block" }}>
                    <div style={{ textAlign: "-webkit-center" }}>
                        <h4 className="Title-Name_Lc">{item.nameInHomePage}</h4>
                    </div>
                </span> */}

                {banner && banner.map((item, index) => (
                    <OurBrandsJohnJacobs item={item} />
                ))}
                <div className="Flex-ProductSlider_Lc">
                    <div className="ProductEyeglass-Font_Lc">{categoryData && categoryData != null && categoryData != undefined && categoryData.name}</div>
                    {/* <Link to="/productsearch" className="ViewRange-Font_Lc">View Range</Link> */}
                    <Link to={`/productsearch?productId=${categoryData.d2_Id}`} className="ViewRange-Font_Lc">View Range</Link>
                </div>

                <Slider {...settings} className="Flex_Slide1_Lc ItemSlider-margin slick-slider-custom ">
                    {productlist && productlist.length > 0 && productlist.filter(item => item.showOnHomePage === true && categoryData != null && categoryData != undefined && item.parentGrpId2 === categoryData.d2_Id).map((item, index) => (
                        item.images[0] && (
                            <Link to={`${ProducrDetailPageURL}/${item.d2Id}`} state={{ item }} key={index} className="Flex-Explore_Lc ProductImg-Margin_Lc Home-Image-Border">
                                <SliderImageCard item={item} imageBaseUrl={imageBaseUrl} />
                                <div className="Card-product-type-1-bottom home-item-name">
                                    <div className="Card-product-type-1-desc-box home-card-percentage">
                                        <div className="card-product-name Product-Name-Home">
                                            {item.sku}
                                        </div>
                                        <div>
                                            {/* <p className="card-product-desc">{item.shortDescription ? item.shortDescription : "No short description found!"}</p> */}
                                        </div>
                                        {/* <div style={{ display: "flex", alignItems: "center" }}>
                                                <p className="card-product-size11">
                                                    <span>Size </span>
                                                    Medium.Ocean Blue
                                                </p>

                                            </div> */}
                                        <div className="Price-Name-slider-content">
                                            <div className="card-product-price11 Price-color-Home">
                                                <i className="fa-solid fa-indian-rupee-sign"></i> {item.salePrice}
                                            </div>
                                            <div className="card-product-price-2 Mrp-price-home">
                                                MRP&nbsp;<i className="fa-solid fa-indian-rupee-sign"></i>
                                                <span className="mrp-price">{item.mrpPrice}</span>
                                            </div>
                                            <div className="percentage-off-Amount">
                                                ({parseInt((((item.mrpPrice - item.salePrice) / item.mrpPrice) * 100).toFixed(2))}% OFF)
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Link>
                        )

                    ))}
                </Slider>
            </div>
            {/* {banner && banner.map((item, index) => (
                <OurBrandsJohnJacobs item={item} />
            ))} */}

            {/* <div className="">
                <div className="Flex-ProductSlider_Lc">
                    <div className="ProductEyeglass-Font_Lc">SUNGLASSES</div>
                    <Link to="/productsearch" className="ViewRange-Font_Lc">View Range</Link>
                </div>
                <div className="Flex_Slide1_Lc ItemSlider-margin">
                    {showPrevArrowUnder && <img className="PrevSlide_Lc" src={img} onClick={handlePrevClickUnder} />}
                    {productlist.filter(item => item.showOnHomePage === true && item.parentGrpId2 === props.idsunglass).slice(startIndexUnder, startIndexUnder + 3).map((item, index) => (
                        <Link to={`/ProductDetailTheme/${item.d2Id}`} state={{ item }} key={index} className="Flex-Explore_Lc ProductImg-Margin_Lc">
                            <img className="Img-Trend_Lc Img-Width_Lc" src={`${imageBaseUrl}${item.images[0]}`} alt="" />
                        </Link>
                    ))}
                    {showNextArrowUnder && <img className="NextSlide_Lc" src={img1} onClick={handleNextClickUnder} />}
                </div>
            </div> */}
        </div>
    );
};

export default ProductSliderImages_Lc;
