import React, { useContext, useEffect, useState, useMemo, useRef } from "react";
import coupon from "../../Assets/Icons/discount.png";
import Modal from "../../Component/Modal/Modal";
import AlertBox from "../../Component/AlertBox/AlertBox";
import AddCouponModal from "../../Component/Modal/AddCouponModal";
import coupon2 from "../../Assets/Icons/discount.png";
import Order from "../../../API/API_SERVISES/Order";
import dlt from "../../Assets/Icons/trash.png";
import MyContext from "../../MyContext";
import axios from "axios";
import swal from "sweetalert";
import AlertSuccess from "../../Component/AlertBox/AlertSuccess";
import { Link, useNavigate } from "react-router-dom";
import StateInfoApi from "../../../API/API_SERVISES/StateInfo";
import CountryApi from "../../../API/API_SERVISES/Country";
import AddAddress from "../../../API/API_SERVISES/AddAddress";
import Googlepay from "../../Assets/Images/GooglePay.webp";
import PhonePe from "../../Assets/Images/PhonePe.webp";
import PayStack from ".././../../API/API_SERVISES/PayStack";
import img11 from "./../../Assets/Image_lc/LensImg/single_vision.webp";
import img22 from "./../../Assets/Image_lc/LensImg/bifocal.webp";
import img33 from "./../../Assets/Image_lc/LensImg/zero_power.webp";
import img44 from "./../../Assets/Image_lc/LensImg/tinted_sv.webp";
import ThemeModal from "../ProductDetailPage/ThemeModal";
import Transaction from "../../../API/API_SERVISES/Transaction";
import BillSeriesMaster from "../../../API/API_SERVISES/BillSeriesMaster";
import BillType from "../../../API/API_SERVISES/BillType";
import CouponMasterApi from "../../../API/API_SERVISES/CouponMaster";
import Loader1 from "../../Component/Loaders/Loader1";

const PaymentMethods = (props) => {
    // const [modal, setModal] = useState(false);
    // const [alert, setAlert] = useState(false);
    const { companyData, AppliedCouponData, CouponApplied, GetCouponData, setCouponApplied, setAppliedCouponData ,totalDiscountAmt  } = useContext(MyContext);
    const [addcoupon, setAddCoupon] = useState(false);
    const [coupon, setCoupon] = useState([]);


    useEffect(() => {
        const getCouponMaster = async () => {
            // setLoading(true);
            var data = await CouponMasterApi.getCouponMaster();
            if (data.length > 0) {
                setCoupon(data);
            } else {
                console.error("error while finding coupon data");
            }
        };
        getCouponMaster();
    }, []);
    // const [sweetAlert, setSweetAlert] = useState(false);
    // const alertbox = () => {
    //     setAlert(!alert)
    // }
    const navigationBar = [
        {
            name: 'Login/Signup',
            disable: true,
            navigationroute: "/",
            // status:
        },
        {
            name: 'Shipping Address',
            disable: false,
            navigationroute: "/ShippingAddress"
        },
        {
            name: 'Payment',
            disable: false,
            navigationroute: "/PaymentMethods"
        },
        {
            name: 'Summary',
            disable: true,
            navigationroute: "/"
        }
    ]
    const navigate = useNavigate()
    const [EditData, setEditData] = useState();
    const [addresses, setAddAddresses] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedAddress, setSelectedAddress] = useState(null);
    const [isSliderOpen, setSliderOpen] = useState(false);
    const [alertSuccess, setAlertSuccess] = useState(false);
    const [visibleTables, setVisibleTables] = useState([]);


    const ApplyCoupon = async (CouponData) => {
        setLoading(true)

        debugger
        var loginDataString = localStorage.getItem("accountLogin");
        var loginData = JSON.parse(loginDataString);
        // console.log(loginData.d1Id);

        var data = [
            {
                rowIndex: 0,
                id: 961,
                adjustmentInItem: true,
                Percent:
                    CouponData.discountType == "Percentage"
                        ? CouponData.discountValue
                        : 0,
                Amount:
                    CouponData.discountType != "Percentage"
                        ? CouponData.discountValue
                        : 0,
            },
        ];

        var stringifydata = JSON.stringify(data)
        var encodedData = encodeURIComponent(stringifydata)

        var stringifydataCoupon = JSON.stringify(CouponData)
        var encodedDataCoupon = encodeURIComponent(stringifydataCoupon)
        console.log( "COUPONDATA", data);
        console.log( "COUPONDATA2", CouponData);
        const CartData = [
            {
                id: 0,
                useType: 0,
                accId: loginData.d2Id || 0,
                ex1: encodedData || "",
                ex2: encodedDataCoupon || "",
                ex3: "",
                ex4: "",
                ex5: "",
                iEx1: 0,
                iEx2: 0,
                iEx3: 0,
                nEx1: 0,
                nEx2: 0,
                nEx3: 0,
                nEx4: 0,
                nEx5: 0

            }]
        var coupon = await Order.SaveEcomCartExtrafields(CartData)
        debugger
        if (coupon.isSuccess) {
            setLoading(false)
            // setAppliedCouponData(CouponData);
            // setCouponApplied(data);
            toggleSliderClose()
            setTimeout(() => {
                GetCouponData(loginData.d2Id , InputValues)
             }, 100);
        }
        else {
            setLoading(false)
        }
    };


    useEffect(() => {
        var loginDataString = localStorage.getItem("accountLogin");
        var loginData = JSON.parse(loginDataString);
        // console.log(loginData.d1Id);
        if (loginData.d1Id) {
            GetCouponData(loginData.d2Id , InputValues)
        }
    }, [])



    const handleCheckboxChange = (addressIndex) => {
        setSelectedAddress(addressIndex);
    };

    const OpenAddCoupon = () => {
        setSliderOpen(true)
    }
    const { myaccountmodal, setTotalQuantity, modal, setLoginPanel, totalQuantity, InputValues, imageBaseUrl,
        fetchDataWish, currentUser, wishList, setWishList, totalPrice, setTotalPrice, setInputValues, fetchDataCart } = useContext(MyContext)

  //****************************************************************** */
  const [isModalOpenAddAddress, setIsModalOpenAddAddress] = useState(false);
  const [showSelectAddress, setShowSelectAddress] = useState(true);

  const handleOpenModalAddAddress = () => {
    setIsModalOpenAddAddress(true);
    setShowSelectAddress(false);
    setEditData("");
  };

  const handleGoBack = () => {
    setIsModalOpenAddAddress(false);
    setShowSelectAddress(true); // Show Select Address section when going back
  };

  //******************************Apply Coupon********************************* */

  const [selectedRadio, setSelectedRadio] = useState("table1");
  const handleRadioChange = (event) => {
    setSelectedRadio(event.target.value);
    // const value = event.target.value;
    // setSelectedRadio(value);
    // navigateToPage(value);
    // setSingleLensModalBox(true)
  };

  const toggleSlider = () => {
    // console.log(editData);
    setSliderOpen(true);
  };
  const toggleSliderClose = () => {
    // console.log(editData);
    setSliderOpen(false);
  };

  const [SingleLensModalBox, setSingleLensModalBox] = useState(false);
  const showModal1 = () => {
    setSingleLensModalBox(true);
  };
  const showModal1Hide = () => {
    setSingleLensModalBox(false);
    setShowFirstComponent(true);
  };

  const [showFirstComponent, setShowFirstComponent] = useState(true);
  const [transition, setTransition] = useState(false);

  const handleClick = () => {
    setTransition(true);
    setTimeout(() => {
      setShowFirstComponent(false);
      setTransition(false);
    }, 500);
  };

  //************************************************************ */

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const signin = () => {
    setLoginPanel(!modal);
  };
  const { item } = props;

  const [activeLink, setActiveLink] = useState("/");
  const handleLinkClick = (link) => {
    setActiveLink(link);
  };

  // const [totalPrice, setTotalPrice] = useState(0);
  // const [taxtotalPrice, setTaxTotalPrice] = useState(0);
  // const [finalPrice, setFinalPrice] = useState(0);
  // const [productPrice, setProductPrice] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const itemsPerPage = 3; // Number of items to show per page
  const itemsPerPage2 = 1; // Number of items to show per page

  //*****************************ADD Address************************************* */
  const [myAccountID, setMyAccountID] = useState();

  useEffect(() => {
    var loginDataString = localStorage.getItem("accountLogin");
    var loginData = JSON.parse(loginDataString);
    // console.log(loginData.d1Id);
    setMyAccountID(loginData.d1Id);
  }, [myAccountID]);

  const [inputValues1, setInputValues1] = useState({
    accountId: "",
    name: "",
    mobile: "",
    email: "",
    pincode: "",
    state: "",
    country: "",
    address: "",
    locality: "",
    city: "",
    typeOfAddress: 0,
    isDefault: "",
    isActive: true,
  });

  useEffect(() => {
    console.log(EditData);
    const fetchData = () => {
      if (EditData) {
        setInputValues1({
          id: EditData.id,
          accountId: 707,
          name: EditData.name,
          email: EditData.email,
          mobile: EditData.mobile,
          pincode: EditData.pincode,
          state: EditData.state,
          address: EditData.address,
          locality: EditData.locality,
          city: EditData.city,
          typeOfAddress: EditData.typeOfAddress,
          isDefault: EditData.isDefault,
          isActive: true,
        });
      } else {
        setInputValues1({
          accountId: "",
          name: "",
          mobile: "",
          pincode: "",
          state: "",
          address: "",
          locality: "",
          city: "",
          typeOfAddress: 0,
          isDefault: "",
          isActive: true,
        });
      }
    };

    fetchData();
  }, [EditData]);

  const [errorMessages, setErrorMessages] = useState({
    name: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    console.log(`Changing ${name} to ${value}`);

    setInputValues1((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    setErrorMessages((prevErrors) => ({ ...prevErrors, [name]: "" }));
  };

  const handleInputChange1 = (value) => {
    //// debugger
    setInputValues1({
      ...inputValues1,
      typeOfAddress: Number(value),
    });
  };

  const insertPayment = async (totalAmt) => {
    var dataRes = {
      status: true,
      message: "Authorization URL created",
      data: {
        authorization_url: "https://checkout.paystack.com/3ni8kdavz62431k",
        access_code: "3ni8kdavz62431k",
        reference: "re4lyvq3s3",
      },
    };

    var loginDataString = localStorage.getItem("accountLogin");
    var loginData = loginDataString && JSON.parse(loginDataString);

    var data = {
      email: loginData?.emailId || "customer@gmail.com",
      amount: totalPrice || totalAmt, //"20000",
      currency: "KES",
      callback_url: "https://lens2cart.com/PaymentResponse",
    };
    var response = await PayStack.CreatePayment(data);
    if (response.status === 200) {
      const url = response.data.data.authorization_url;
      localStorage.setItem("PayStackPayment", response.data.data.reference);
      window.location.href = url;
    }
  };

  // const [stateInfo, setStateInfo] = useState();
  // useEffect(() => {
  //     const fetchData = async () => {
  //         try {
  //             const data = await StateInfoApi.GetAllState();
  //             setStateInfo(data);
  //         } catch (error) {
  //             console.error("Error fetching data:", error);
  //         }
  //     };
  //     fetchData();
  // }, []);

  // const [country, setCountry] = useState();
  // useEffect(() => {
  //     const fetchData = async () => {
  //         try {
  //             const data = await CountryApi.GetAllCountry();
  //             setCountry(data);
  //         } catch (error) {
  //             console.error("Error fetching data:", error);
  //         }
  //     };
  //     fetchData();
  // }, []);

  // const validateInput = (fieldName) => {
  //     // console.log(fieldName);
  //     const fieldValue = inputValues1[fieldName];

  //     if (
  //         !fieldValue ||
  //         (typeof fieldValue === "string" && fieldValue.trim() === "")
  //     ) {
  //         setErrorMessages((prevErrors) => ({
  //             ...prevErrors,
  //             [fieldName]: `${toFriendlyName(fieldName)} field is required`,
  //         }));
  //         return false;
  //     }
  // };
  // const fieldNames = [];

  // const toFriendlyName = (fieldName) => {
  //     return fieldName
  //         .replace(/([A-Z])/g, " $1")
  //         .replace(/^./, (str) => str.toUpperCase());
  // };

  // const inputRef1 = useRef(null);
  // const inputRef2 = useRef(null);
  // const inputRef3 = useRef(null);
  // const inputRef4 = useRef(null);

  // const focusInput = (inputRef) => {
  //     inputRef.current.focus();
  // };

  // const SaveBtn = async () => {
  //     // console.log(props.data);
  //     let allValid = true;

  //     fieldNames.forEach((fieldName) => {
  //         const isValid = validateInput(fieldName);

  //         if (!isValid) {
  //             allValid = false;
  //         }
  //     });

  //     if (allValid) {
  //         if (EditData) {
  //             if (inputValues1.name === "") {
  //                 alert("Name field is Required")
  //                 focusInput(inputRef1)
  //             }
  //             else if (inputValues1.address === "") {
  //                 alert("Address field is Required ")
  //                 focusInput(inputRef2)
  //             }
  //             else if (inputValues1.state === "" && inputValues1.state === 0) {
  //                 alert("Please select the state ")
  //                 focusInput(inputRef3)
  //             }
  //             else if (inputValues1.city === "") {
  //                 alert("City/District feild is required ")
  //                 focusInput(inputRef4)
  //             }
  //             else {
  //                 const address = await AddAddress.updateAddress(
  //                     inputValues1,
  //                     myAccountID
  //                 );
  //                 if (address) {
  //                     alert("Address Updated successfully");
  //                     // setEditData();

  //                 } else {
  //                     alert("error while updating");
  //                 }
  //             }
  //         } else {
  //             try {
  //                 if (inputValues1.name === "") {
  //                     alert("Name field is Required")
  //                     focusInput(inputRef1)
  //                 }
  //                 else if (inputValues1.address === "") {
  //                     alert("Address field is Required ")
  //                     focusInput(inputRef2)
  //                 }
  //                 else if (inputValues1.state === "" || inputValues1.state === 0) {
  //                     alert("Please select the state ")
  //                     focusInput(inputRef3)
  //                 }
  //                 else if (inputValues1.city === "") {
  //                     alert("City/District feild is required ")
  //                     focusInput(inputRef4)
  //                 }
  //                 else {
  //                     const address = await AddAddress.insertAddress(
  //                         inputValues1,
  //                         myAccountID
  //                     );

  //                     if (address) {
  //                         alert("Address added successfully");
  //                         // props.modalclose();
  //                         // props.fetchAddress();
  //                     } else {
  //                         // Handle case when address is not added successfully
  //                     }
  //                 }
  //             } catch (error) {
  //                 console.error("Error inserting data:", error);
  //                 alert("Error inserting data");
  //             }
  //         }
  //     } else {
  //         alert("Validation failed");
  //     }
  // };

  //******************************************************* */

  const fetchAddress = async () => {
    var loginDataString = localStorage.getItem("accountLogin");
    var loginData = JSON.parse(loginDataString);
    console.log(loginData.d1Id);
    setMyAccountID(loginData.d1Id);
    var addresses = await AddAddress.getAddress(loginData.d1Id);
    if (addresses) {
      setAddAddresses(addresses);
      //   setAddressModal(false);
      // setIsModalOpenAddAddress(true);
      setLoading(false);
    } else {
      alert("Error Occoured");
    }
  };

  useEffect(() => {
    const fetchAddress = async () => {
      var loginDataString = localStorage.getItem("accountLogin");
      var loginData = JSON.parse(loginDataString);
      console.log(loginData.d1Id);
      setMyAccountID(loginData.d1Id);
      var addresses = await AddAddress.getAddress(loginData.d1Id);
      if (addresses) {
        setAddAddresses(addresses);
        // setAddressModal(false);
        // setIsModalOpenAddAddress(true);
        setLoading(false);
      } else {
        alert("Error Occoured");
      }
    };

    fetchAddress();
  }, []);

  console.log(addresses);

  //************************Edit Address********************* */

  const editaddress = async (id) => {
    var getaddress = await AddAddress.getAddressById(id, myAccountID);
    // console.log(data);
    console.log(getaddress);
    setEditData(getaddress);
    setIsModalOpenAddAddress(true);
    setShowSelectAddress(false);
  };

  //***************************Delete Address******************************* */

  const deleteaddress = async (id) => {
    if (window.confirm("Are you sure you want to delete this address?")) {
      setLoading(true);
      try {
        var deletedata = await AddAddress.deleteAddress(id, myAccountID);
        if (deletedata) {
          alert("Address Deleted");
          fetchAddress();
        } else {
          alert("Error Occurred");
        }
      } catch (error) {
        alert("Error Occurred: " + error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const [paymentType, setPaymentType] = useState("");

  const handlePaymentClick = (payType) => {
    setPaymentType(payType);
  };

  const PlaceOrder = async () => {
    const userLogin = localStorage.getItem("accountLogin");
    const userArray = userLogin ? JSON.parse(userLogin) : null;

    const cartData = await Order.getCartList(userArray.d2Id);

    if (paymentType == "") {
      alert("Please select Payment Mode");
    } else if (paymentType == "CASH") {
      const accAdd = await AddAddress.getAddress(userArray.d1Id); // userArray.d2Id
      const address = accAdd.find((item) => item.isDefault == true);

            const billSeries = await BillSeriesMaster.getBillSeriesMaster();
            const defaultSeries = billSeries.find(item => item.setDefaultSeriesForEcom == true);
            const filteredData = [
                {
                    fieldName: "IsDefault",
                    operatorName: "Equal",
                    compareValue: true
                }
            ]
            const billTypeData = await BillType.GetFilteredBillType(filteredData);
            const billData = { billTypeId: billTypeData ? (billTypeData[0].id || 0) : 0, billSeriesId: defaultSeries ? (defaultSeries?.id || 0) : 0 }

            const data = await Transaction.GetTransRefNoForOrder(userArray, cartData, 0, address, billData , CouponApplied); //Cash
            await Order.DeleteEcomCartByAccountId(userArray.d2Id);

            setTotalQuantity(0);
            setInputValues([]);

            const tabName = "My Order"
            navigate("/admin", {
                state: {
                    tabName
                }
            });
        }
        if (paymentType == "MPESA" || paymentType == "CREDITCARD" || paymentType == "DEBITCARD") {
            const totalAmt = cartData?.reduce((total, item) => {
                return total + ((item.quantity || 0) * (item.itemPrice || 0))
                    + ((item.quantity || 0) * (item.lensPrice || 0))
                    + ((item.quantity || 0) * (item.coatingPrice || 0));  // Add the calculated amount for each item
            }, 0);
            // const data = await Transaction.GetTransRefNoForOrder(userArray, cartData, 241); //Online
            // await Order.DeleteEcomCartByAccountId(userArray.d2Id)
            insertPayment(totalAmt); //Transaction will save after payment success
        }
        else {

        }

    }


    const toggleTableVisibility = (productId) => {
        setVisibleTables((prevState) => ({
            ...prevState,
            [productId]: !prevState[productId],
        }));
    };

    return (
        <>  {InputValues.length === 0 ? (
            <div className="black-codesdf">
                <div className="p-graph-head-title">Your shopping cart is empty! </div>
                <Link to="/" className="Blank-cart-page-btn"><button className="pricebox-type-btn-theme3">Continue Shopping</button></Link>
            </div>
        ) : (
            <div>

                <div className="cartpage-high-theme3 cart-summary-pay">
                    <div className="cartpage-left-theme3 cartpage-bottom pay-left-summary">
                        <div className="flex-Shipping-address">
                            {
                                navigationBar.map((item) => {
                                    return (
                                        <>
                                            <div onClick={() => !item.disable && item.name != "Summary" ? navigate(item.navigationroute) : null} className={` ${window.location.pathname == item.navigationroute ? "add-color-Shipping-add" : "Top_font-Shipping-Add"} ${item.disable && "disable-Shipping-add"}`}>{item.name}</div>
                                            <div><i class="fa-solid fa-chevron-right icon-margin-Rarrow"></i></div>
                                        </>
                                    )
                                })
                            }

                        </div>
                        <div className="Save-Address-theme3 Select-Address-shipp">Payments Methods</div>
                        <div className="shipping-Address-flex">
                            <div className="Flex-Checkout-powers Power-margin-bottom">
                                <p className="Paragraph-checkout-Font">Power will be taken after checkout. No additional charges for high powers.</p>
                            </div>
                        </div>
                        <div className="shipping-Address-flex">
                            {/* <div className="Flex-Apply-Coupon"> */}
                                {/* <div className="flex-Radio-Coupon">
                                    <div className="Radio-margin-auto-Lc">
                                        <input className="Input-Radio-Coupon" type="radio" name="coupon" onClick={() => toggleSlider()} />
                                    </div>
                                    <div>
                                        <h3 className="Apply-Coupon-Head">Apply Coupon</h3>
                                        <p className="cashbacks-offers">See available offers and cashbacks</p>
                                    </div>
                                </div>
                                <div className="Flex-Angle-right">
                                    <i class="fa-solid fa-angle-right"></i>
                                </div> */}
                                {CouponApplied[0] ? (
                                    <div
                                        style={{ cursor: "pointer", width: "80%", padding: "0" }}
                                        onClick={OpenAddCoupon}
                                        className="cartpage-pricebox frutiger-button"
                                    >
                                        {/* <div class=""> */}
                                        <div class="inner">
                                            <div class="top-white"></div>
                                            <div className="pricebox-1" style={{ padding: "0" }}>
                                                <div
                                                    style={{ display: "flex", flexDirection: "column" }}
                                                >
                                                    {/* <img src={coupon2} alt="" /> */}
                                                    <div className="pricebox-coupon">
                                                        {AppliedCouponData.couponMethod}
                                                    </div>
                                                    {AppliedCouponData.discountType == "Percentage" ? (
                                                        <div
                                                            className="coupon-txt-box-1"
                                                            style={{ width: "100%" }}
                                                        >
                                                            <p
                                                                style={{
                                                                    margin: "0",
                                                                    paddingLeft: "10px",
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                Flat {AppliedCouponData.discountValue} % off
                                                            </p>
                                                            <p
                                                                style={{
                                                                    margin: "0",
                                                                    paddingLeft: "10px",
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                You are saving  {totalDiscountAmt || 0}
                                                                {companyData.currencySymbol}
                                                            </p>
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className="coupon-txt-box-1"
                                                            style={{ width: "100%" }}
                                                        >
                                                            <p
                                                                style={{
                                                                    margin: "0",
                                                                    paddingLeft: "10px",
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                Flat {AppliedCouponData.discountValue} off
                                                            </p>
                                                            <p
                                                                style={{
                                                                    margin: "0",
                                                                    paddingLeft: "10px",
                                                                    width: "100%",
                                                                }}
                                                            >
                                                                You are saving  {totalDiscountAmt || 0}
                                                                {companyData.currencySymbol}

                                                            </p>
                                                        </div>
                                                    )}
                                                </div>
                                                <i className="fa-solid fa-caret-right"></i>
                                            </div>
                                        </div>
                                        {/* </div> */}
                                    </div>
                                ) : (
                                    <div
                                        style={{ cursor: "pointer" }}
                                        onClick={OpenAddCoupon}
                                        className="cartpage-pricebox"
                                    >
                                        <div className="pricebox-1">
                                            <div style={{ display: "flex" }}>
                                                <img src={coupon2} alt="" />
                                                <div className="pricebox-coupon">Add Coupon Code</div>
                                            </div>
                                            <i className="fa-solid fa-caret-right"></i>
                                        </div>
                                    </div>
                                )}
                            {/* </div> */}

                        </div>
                       <div className="Save-Address-theme3 Saved-Add-shipp">Cash on Delivery</div>
                        <div className="shipping-Address-flex">
                            <div className="Flex-Apply-Coupon Upi-Paddin-QR" onClick={() => handlePaymentClick("CASH")}>
                                <div className="flex-UPI-Pay">
                                    <div className="Radio-margin-auto-Lc">
                                        <input className="Input-Radio-Coupon" type="radio" name="coupon" checked={paymentType == "CASH"} />
                                    </div>
                                    <div>
                                        <h3 className="Apply-Coupon-Head">Cash on Delivery</h3>
                                    </div>
                                </div>
                                <div className="Flex-Angle-right">
                                    <i class="fa-solid fa-angle-right"></i>
                                </div>
                            </div>
                        </div> 
                        <div className="Save-Address-theme3 Saved-Add-shipp">UPI</div>
                        <div className="shipping-Address-flex">
                            <div className="Flex-Apply-Coupon Upi-Paddin-QR" 
			   // onClick={() => handlePaymentClick("MPESA")}
			    >
                                <div className="flex-UPI-Pay">
                                    <div className="Radio-margin-auto-Lc">
                                        <input className="Input-Radio-Coupon" type="radio" name="coupon" 
					//checked={paymentType == "MPESA"} 
					/>
                                    </div>
                                    <div>
                                        <h3 className="Apply-Coupon-Head">UPI</h3>
                                    </div>
                                </div>
                                <div className="Flex-Angle-right">
                                    <i class="fa-solid fa-angle-right"></i>
                                </div>
                            </div>
                        </div>

              {/* <div className="Save-Address-theme3 Saved-Add-shipp">Google Pay</div>
                        <div className="shipping-Address-flex" onClick={insertPayment}>
                            <div className="Flex-Apply-Coupon Upi-Paddin-QR">
                                <div className="flex-UPI-Pay">
                                    <div className="Radio-margin-auto-Lc">
                                        <input className="Input-Radio-Coupon" type="radio" name="coupon" />
                                    </div>
                                    <div className="GooglePay-Img-lc">
                                        <img className="google-Pay-img" src={Googlepay} alt="" />
                                    </div>
                                    <div>
                                        <h3 className="Apply-Coupon-Head">Google Pay</h3>
                                    </div>
                                </div>
                                <div className="Flex-Angle-right">
                                    <i class="fa-solid fa-angle-right"></i>
                                </div>
                            </div>
                        </div> */}

              {/* <div className="Save-Address-theme3 Saved-Add-shipp">Store Credit</div>
                        <div className="shipping-Address-flex" onClick={insertPayment}>
                            <div className="Flex-Apply-Coupon Upi-Paddin-QR">
                                <div className="flex-UPI-Pay">
                                    <div className="Radio-margin-auto-Lc">
                                        <input className="Input-Radio-Coupon" type="checkbox" name="coupon" />
                                    </div>
                                    <div>
                                        <h3 className="Apply-Coupon-Head">Store Credit</h3>
                                    </div>
                                </div>
                                <div className="Flex-Angle-right">
                                    <i class="fa-solid fa-angle-right"></i>
                                </div>
                            </div>
                        </div> */}

              {/* <div className="Save-Address-theme3 Saved-Add-shipp">Net Banking</div>
                        <div className="shipping-Address-flex" onClick={insertPayment}>
                            <div className="Flex-Apply-Coupon Upi-Paddin-QR">
                                <div className="flex-UPI-Pay">
                                    <div className="Radio-margin-auto-Lc">
                                        <input className="Input-Radio-Coupon" type="radio" name="coupon" />
                                    </div>
                                    <div>
                                        <h3 className="Apply-Coupon-Head">Net Banking</h3>
                                    </div>
                                </div>
                                <div className="Flex-Angle-right">
                                    <i class="fa-solid fa-angle-right"></i>
                                </div>
                            </div>
                        </div> */}

              <div className="Save-Address-theme3 Saved-Add-shipp">
                Credit Card
              </div>
              <div
                className="shipping-Address-flex"
                //onClick={() => handlePaymentClick("CREDITCARD")}
              >
                <div className="Flex-Apply-Coupon Upi-Paddin-QR">
                  <div className="flex-UPI-Pay">
                    <div className="Radio-margin-auto-Lc">
                      <input
                        className="Input-Radio-Coupon"
                        type="radio"
                        name="coupon"
                        //checked={paymentType == "CREDITCARD"}
                      />
                    </div>
                    {/* <div className="GooglePay-Img-lc">
                                        <img className="google-Pay-img" src={PhonePe} alt="" />
                                    </div> */}
                    <div>
                      <h3 className="Apply-Coupon-Head">Credit Card</h3>
                    </div>
                  </div>
                  <div className="Flex-Angle-right">
                    <i class="fa-solid fa-angle-right"></i>
                  </div>
                </div>
              </div>

              <div className="Save-Address-theme3 Saved-Add-shipp">
                Debit Card
              </div>
              <div
                className="shipping-Address-flex"
                onClick={() => handlePaymentClick("DEBITCARD")}
              >
                <div className="Flex-Apply-Coupon Upi-Paddin-QR">
                  <div className="flex-UPI-Pay">
                    <div className="Radio-margin-auto-Lc">
                      <input
                        className="Input-Radio-Coupon"
                        type="radio"
                        name="coupon"
                        //checked={paymentType == "DEBITCARD"}
                      />
                    </div>
                    <div>
                      <h3 className="Apply-Coupon-Head">Debit Card</h3>
                    </div>
                  </div>
                  <div className="Flex-Angle-right">
                    <i class="fa-solid fa-angle-right"></i>
                  </div>
                </div>
              </div>
            </div>
            <div className="cart-summary-right">
              <div
                style={{ marginLeft: "5px" }}
                className="cartpage-heading-theme3"
              >
                Order Summary
              </div>
              <div className="ord-summary-right-height">
                {InputValues &&
                  InputValues.filter((item, index, self) =>
                    item.linkedItm == ""
                      ? item
                      : index ===
                        self.findIndex((t) => t.linkedItm === item.linkedItm)
                  ).map((product) => (
                    <div
                      className="review-box review-box-2-theme3 cart-summary-review"
                      key={product.id}
                    >
                      <div className="review-box-image">
                        <img
                          src={`${imageBaseUrl}${product.itemImgId}`}
                          alt=""
                        />
                      </div>
                      <div className="review-box-detail">
                        <div
                          className="review-box-options--theme3"
                          style={{ paddingTop: "5px" }}
                        >
                          <div className="heading-panel-1-theme3">
                            {product.itemName}
                          </div>
                          <div className="prize-55">
                            {companyData.currencySymbol} {product.itemPrice}
                          </div>
                        </div>
                        {/* {product.productCategory == "2" ? (
                                                <div> */}
                        {/* <div className="review-box-options--theme3">
                                                <span className="Usage-Topic-Lc">Usage :</span>
                                                <span className="Monthly-Disposable-Lc">Monthly Disposable</span>
                                            </div> */}
                        {product.sph_L || product.sph_L != 0 ? (
                          <div>
                            <div
                              className="Buying-For--User"
                              onClick={() => toggleTableVisibility(product.id)}
                            >
                              <span className="">
                                Buying for
                                <u className="Lens-user-Mleft">
                                  Lens2cart User
                                </u>
                                <i
                                  className={`fa-solid fa-angle-${
                                    visibleTables ? "up" : "down"
                                  }`}
                                ></i>
                              </span>
                              <span className="">
                                No extra charge for high power
                              </span>
                            </div>
                            {visibleTables[product.id] && (
                              <div className="Table-Border-Prescription ">
                                <table className="table-Main-contact-Lc scroll-table-ctclens">
                                  <thead className="Table-Header-Contactlens">
                                    <tr>
                                      <th>EYE</th>
                                      {product.box_L && <th>Boxes</th>}
                                      {product.sph_L && <th>SPH</th>}
                                      {product.cyl_L && <th>CYL</th>}
                                      {product.axis_L && <th>AXIS</th>}
                                      {/* {product.bC_L && (
                                                                            <th>BC</th>
                                                                        )}
                                                                        {product.dia_L && (
                                                                            <th>DIA</th>
                                                                        )} */}
                                      {product.add_L && <th>READING</th>}
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td>L</td>
                                      {product.box_L && (
                                        <td>{product.box_L}</td>
                                      )}
                                      {product.sph_L && (
                                        <td>{product.sph_L}</td>
                                      )}
                                      {product.cyl_L && (
                                        <td>{product.cyl_L}</td>
                                      )}
                                      {product.axis_L && (
                                        <td>{product.axis_L}</td>
                                      )}
                                      {/* {product.bC_L && (
                                                                            <td>{product.bC_L}</td>
                                                                        )}
                                                                        {product.dia_L && (
                                                                            <td>{product.dia_L}</td>
                                                                        )} */}
                                      {product.add_L && (
                                        <td>{product.add_L}</td>
                                      )}
                                    </tr>
                                    <tr>
                                      <td>R</td>
                                      {product.box_R && (
                                        <td>{product.box_R}</td>
                                      )}
                                      {product.sph_R && (
                                        <td>{product.sph_R}</td>
                                      )}
                                      {product.cyl_R && (
                                        <td>{product.cyl_R}</td>
                                      )}
                                      {product.axis_R && (
                                        <td>{product.axis_R}</td>
                                      )}

                                      {product.add_R && (
                                        <td>{product.add_R}</td>
                                      )}
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            )}
                          </div>
                        ) : (
                          ""
                        )}
                        {/* </div>
                                            ) : null} */}
                        {product.lensName && (
                          <div className="review-box-options--theme3">
                            <div className="Lens-color-cart3page">
                              LENS - {product.lensName}
                            </div>
                            <div className="prize-55">
                              {companyData.currencySymbol} {product.lensPrice}
                            </div>
                          </div>
                        )}
                        {product.coatingName && (
                          <div className="review-box-options--theme3">
                            <div className="Lens-color-cart3page">
                              COATING - {product.coatingName}
                            </div>
                            <div className="prize-55">
                              {companyData.currencySymbol}{" "}
                              {product.coatingPrice}
                            </div>
                          </div>
                        )}
                        <div className="review-box-options--theme3">
                          <div className="Lens-color-cart3page">
                            Final prize
                          </div>
                          <div className="prize-55">
                            {companyData.currencySymbol}{" "}
                            {(product.itemPrice +
                              product.lensPrice +
                              product.coatingPrice) *
                              product.quantity}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>

              <div className="bill-details-left">
                <div
                  style={{ marginLeft: "5px" }}
                  className="cartpage-heading-theme3 bill-details-topcart"
                >
                  Bill Details
                </div>

                <div className="">
                  <div className="cartpage-pricebox bill-box-cartsum">
                    <div className="pricebox-type-1">
                      <div className="pricebox-heading">Item Total</div>
                      <div className="pricebox-heading-2">
                        {companyData.currencySymbol} {totalPrice}
                      </div>
                    </div>
                    <div
                      style={{ color: "rgb(17, 186, 216)" }}
                      className="pricebox-type-1"
                    >
                      <div className="pricebox-heading">Item Coupon</div>
                      <div className="pricebox-heading-2">
{AppliedCouponData && (
                        <>
                          {AppliedCouponData.discountType == "Percentage" ? (
                            <p
                              style={{
                                margin: "0",
                                paddingLeft: "10px",
                                width: "100%",
                              }}
                            >
                              Flat {AppliedCouponData.discountValue} % off (
                              {totalDiscountAmt || 0}
                              {companyData.currencySymbol})
                            </p>
                          ) : (
                            <p
                              style={{
                                margin: "0",
                                paddingLeft: "10px",
                                width: "100%",
                              }}
                            >
                              Flat {AppliedCouponData.discountValue}{" "}
                              {companyData.currencySymbol} off
                            </p>
                          )}
                        </>
                      )}
                      </div>
                    </div>
                    <div className="pricebox-type-1">
                      <div className="pricebox-heading">Net Amount</div>
                      <div className="pricebox-heading-2">
                        {companyData.currencySymbol} {totalPrice}
                      </div>
                    </div>
                    <div style={{ border: "0" }} className="pricebox-type-1">
                      <div className="pricebox-heading">
                        Total payable (incl. taxes)
                      </div>
                      <div className="pricebox-heading-2">
                        {companyData.currencySymbol} {totalPrice}
                      </div>
                    </div>
                  </div>
                  {/* <div style={{ cursor: "pointer" }} onClick={OpenAddCoupon} className="cartpage-pricebox">
                                    <div className="pricebox-1">
                                        <div style={{ display: "flex" }}>
                                            <img src={coupon} alt="" />
                                            <div className="pricebox-coupon">Add Coupon Code</div>
                                        </div>
                                        <i className="fa-solid fa-caret-right"></i>
                                    </div>
                                </div> */}
                  <div>
                    <button
                      className="pricebox-type-btn-theme3"
                      onClick={PlaceOrder}
                    >
                      Place Order
                    </button>
                  </div>
                  {/* <div
                            // onClick={myaccountmodal}
                            // className={`navbar-option ${activeLink === "/MyAccount" ? "active" : ""
                            //     }`}
                            >
                                <button className="pricebox-type-btn-theme3" onClick={SaveBtn}>Proceed To Payment</button>
                            </div> */}
                </div>
              </div>
            </div>
            {/* {modal && (
                        <Modal handleClick={signin} />
                    )} */}
            {/* {alert && (<AlertBox alertname="Remove Item From Cart" close={() => DeleteDeleteEcomCartDetail()} />)} */}
            {addcoupon && <AddCouponModal handleClick={OpenAddCoupon} />}
            {/* <AlertSuccess /> */}
          </div>

                {/* <div className={`slider-container100 Slider-coupon-width ${isSliderOpen ? "open" : ""}`}>
                    <div style={{ padding: "15px" }}>
                        <div>
                            <div className="slider-display-allow">
                                <div className="slider-head-gm"><i onClick={showModal1Hide} class="fa-solid fa-arrow-left-long slide1-left"></i> <h3 className="slider-top-head-nm">
                                    Apply Coupon </h3></div>
                                <div onClick={toggleSliderClose} className="slider-head-gm"><i class="fa-solid fa-xmark slider-cross-btn"></i></div>
                            </div>
                        </div>


                        <div className="">
                            {showFirstComponent && (
                                <div className={`component first ${transition ? 'exit' : ''}`} onClick={handleClick}>
                                    <div className="buywithlens-content">
                                        <div className="buywithlens-left apply-coupon-width">
                                            <div className="Gaping-Coupon-code">
                                                <div className="Flex-Coupon-code-lc">
                                                    <div className="Coupon-code-width-Lc">
                                                        <input className="Input-Box-Coupon" type="text" placeholder="ENTER COUPON CODE" />
                                                    </div>
                                                    <div className="Apply-CouponBtn-Lc">
                                                        Apply
                                                    </div>
                                                </div>
                                                <div className="Coupons-Moment">
                                                    <div className="Moment-Coupon-alert">
                                                        <span>Sorry! No Coupons available at this moment.</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div> */}
            </div>
        )}
            {isSliderOpen && (<AddCouponModal 
            coupons={coupon} 
            ApplyCoupon={ApplyCoupon}
             handleClick={toggleSliderClose} />)}
    {loading && <Loader1 />}
        </>
    );
};

export default PaymentMethods;
