import React, { useContext, useEffect, useState } from "react";
import "./Lens2Cart.css";
import buy1get1 from "../../Assets/GoldMembership/Buy1Get1Desktop.webp";
import FreeEyetest from "../../Assets/GoldMembership/FreeEyetest.webp";
import Aqulence from "../../Assets/GoldMembership/Aqulence.webp";
import Extracashback from "../../Assets/GoldMembership/ExtraDesktop.webp";
import FAQ from "../../Assets/GoldMembership/FAQ.webp";
import { Icon } from "@iconify/react/dist/iconify.js";
import ItemMaster from "../../../API/API_SERVISES/ItemMaster";
import LoyaltyMaster from "../../../API/API_SERVISES/LoyaltyMaster";
import CouponMaster from "../../../API/API_SERVISES/CouponMaster";
import Order from "../../../API/API_SERVISES/Order";
import MyContext from "../../MyContext";
import { useNavigate } from "react-router-dom";

const MembershipGold = () => {
  const [selectedfaq, setselectedfaq] = useState(null);

  const CardImage = {
    // "Buy X get Y": buy1get1,
    // "Free Eye Test": FreeEyetest,
    // "Amount Off on Item/(Category/Group)": Aqulence,
    // "Amount off on total Order value": Extracashback,
    // "Extra Cashback 2": Extracashback,
  };

  const {
    fetchDataCart,
    // input5,
  } = useContext(MyContext);
  const [Card, setCard] = useState([]);
  const [couponDetails, setCouponDetails] = useState([]);

 
  useEffect(() => {

    console.log(Card);
  }, [Card]);
  const navigate = useNavigate();

  console.log(couponDetails);

  const AddToCart = async (item) => {
    debugger;
    // alert(item.d2Id);
    var loginDataString = localStorage.getItem("accountLogin");
    var loginData = JSON.parse(loginDataString);
    if (loginData === null) {
      loginData = {
        d1Id: 0,
        d2Id: 0,
      };
    }

    console.log();
    console.log(item);
    const data = {
      d1ItemId: item.d1Id || item.d1ItemId,
      d2ItemId: item.d2Id || item.d2ItemId,
      d1AccountId: loginData ? loginData.d1Id || 0 : 0,
      d2AccountId: loginData ? loginData.d2Id || 0 : 0,
      userId: 1,
      quantity: 1,
      unit: "Pcs",
      clientId: 0,
      itemPrice: item.salePrice,
      lensPrice: item.lensPrice,
      lensName: item.lensName,
      coatingPrice: item.coatingPrice,
      coatingName: item.coatingName,

      // box_R: inputValues.boxesRight,
      // box_L: inputValues.boxesLeft,
      // sph_R: inputValues.sphRight,
      // sph_L: inputValues.sphLeft,
      // cyl_R: inputValues.cylRight,
      // cyl_L: inputValues.cylLeft,
      // axis_R: inputValues.axisRight,
      // axis_L: inputValues.axisLeft,
      // add_R: inputValues.addRight,
      // add_L: inputValues.addLeft,
      // dia_R: inputValues.diaRight,
      // dia_L: inputValues.diaLeft,
      // bC_R: inputValues.bcRight,
      // bC_L: inputValues.bcLeft,
    };
    const isSuccess = await Order.insertCreateEcomCartDetail(data);
    if (isSuccess) {
      // setTotalQuantity(isSuccess.length)
      await fetchDataCart();
      navigate("/cart3");

      // setAlertSuccess(true);
      // setInputValues({
      //   d1ItemId: " ",
      //   d2ItemId: " ",
      //   d1AccountId: " ",
      //   d2AccountId: " ",
      //   userId: " ",
      //   quantity: " ",
      //   unit: " ",
      //   itemPrice: " ",
      //   lensPrice: "",
      //   lensName: "",
      //   coatingPrice: "",
      //   coatingName: "",
      //   boxesLeft: "",
      //   boxesRight: "",
      //   sph_R: "",
      //   sph_L: "",
      //   cyl_R: "",
      //   cyl_L: "",
      //   axis_R: "",
      //   axis_L: "",
      //   add_R: "",
      //   add_L: "",
      //   dia_R: "",
      //   dia_L: "",
      //   bcRight: "",
      //   bcLeft: "",
      // });
    }
  };

  const faqdata = [
    {
      label: "What is the Lens2Cart Gold Membership?",
      data: "The Lens2Cart Gold Membership is a premium subscription plan that costs 500 Ksh per year. Members enjoy a 25% discount on all single vision, bifocal, progressive, frames and sunglasses for the entire year.",
    },
    {
      label: "How much does the Gold Membership cost?",
      data: "The Gold Membership is priced at just 500 Ksh for one year.",
    },
    {
      label: "What benefits do I get with the Gold Membership?",
      data: "Gold members receive a 25% discount on every purchase of  single vision, bifocal, progressive ,frames and sunglasses on Lens2Cart store, website or app",
    },
    {
      label: "How do I sign up for the Gold Membership?",
      data: "You can sign up for the Gold Membership through our website, app or store. Simply go to the Gold Membership section, make the payment of 500 Ksh, and your membership will be activated.",
    },
    {
      label: "How do I use the Gold Membership discount?",
      data: "Once you’ve purchased the Gold Membership, the 25% discount will automatically be applied at checkout whenever you purchase single vision, bifocal, progressive, frames and sunglasses",
    },
    {
      label: "Is the discount applicable to all products?",
      data: "Yes, the 25% discount applies to all single vision, bifocal, progressive frames and sunglasses available on Lens2Cart. However, it does not include accessories or other non-lens/frame products.",
    },
    {
      label: "Can I cancel my Gold Membership?",
      data: "The Gold Membership is non-refundable. However, you can choose not to renew it after the one-year period.",
    },
    {
      label: "Does the Gold Membership renew automatically?",
      data: "No, the Gold Membership does not renew automatically. You’ll need to repurchase it after one year to continue enjoying the benefits.",
    },
    
  ];

  const [MembershipItems, setMembershipItems] = useState([]);

  //   alert(MembershipItems.length);

  useEffect(() => {
    const GetmembershipItem = async () => {
      const filteredata = [
        {
          fieldName: "productCategory",
          operatorName: "equal",
          compareValue: 3,
        },
      ];
      var Item = await ItemMaster.GetItemMasterFilter(filteredata);
      // debugger;

      if (Item && Item.length > 0) {
        setMembershipItems(Item);
      }
    };
    GetmembershipItem();
  }, []);

  useEffect(() => {
    const CardDetail = async () => {
      if (MembershipItems && MembershipItems.length > 0) {
        for (let i = 0; i < MembershipItems.length; i++) {
          const CardData =
            await LoyaltyMaster.GetLoyaltyMemberShipAccountCardDetail(
              MembershipItems[i].iUseMembershipCard
            );
          // Check if the CardData already exists based on a unique property (e.g., iUseMembershipCard)
          setCard((prevCard) => {
            if (!prevCard.some((card) => card.id === CardData.id)) {
              // Only add if it doesn't already exist
              return [...prevCard, CardData];
            }
            return prevCard; // No change if the card is already in the array
          });
        }
      }
    };
    CardDetail();
  }, [MembershipItems]);

  useEffect(() => {
    const CouponDetails = async () => {
      const data = await CouponMaster.getCouponMaster();
      if (data && data.length > 0) {
        setCouponDetails(data); // Update state with the full list of coupon details
      }
    };
    CouponDetails();
  }, [Card]); // Re-run when Card changes

  //   useEffect(() => {
  //     const fetchCardDetails = async () => {
  //       debugger;
  //       if (MembershipItems && MembershipItems.length > 0) {
  //         try {
  //           // Step 1: First API call with 5448
  //           var firstCardDetails =
  //             await LoyaltyMaster.GetLoyaltyMemberShipAccountCardDetail(5448);
  //           console.log("First API Call Result:", firstCardDetails);

  // if(firstCardDetails && firstCardDetails.length > 0){
  //   // for(firstCardDetails.)
  //   var couponData =  await CouponMaster.getCouponMasterId()
  // }

  //           // Step 2: Second API call with 5449
  //           var secondCardDetails =
  //             await LoyaltyMaster.GetLoyaltyMemberShipAccountCardDetail(5449);

  //           console.log("Second API Call Result:", secondCardDetails);
  //           // You can combine or process both results here if needed
  //           const firstArray = Array.isArray(firstCardDetails)
  //             ? firstCardDetails
  //             : [firstCardDetails];
  //           const secondArray = Array.isArray(secondCardDetails)
  //             ? secondCardDetails
  //             : [secondCardDetails];

  //           // Combine the two arrays
  //           const combinedResults = [...firstArray, ...secondArray];
  //           console.log("Combined Results:", combinedResults);

  //           // Set the combined data
  //           setCard(combinedResults);
  //         } catch (error) {
  //           console.error("Error fetching card details:", error);
  //         }
  //       }
  //     };

  //     fetchCardDetails();
  //   }, [MembershipItems]);

  useEffect(() => {
    console.log(faqdata);
  }, [faqdata]);
  return (
    <>
      <div className={`lens2kart-gold`}>
        {MembershipItems
          // .slice()
          //   .reverse()
          .map((item, index) => (
            <div key={`membership-${index}`}>
              {/* --------top----------------- */}
              <div className="lens2cart-gold-top">
                <h1 className="label-lens2kart-gold">{item.name}</h1>
                {/* -----gold---------- */}
                <div className="sublabel-lens2kart-gold">
                  <p>Online</p>
                  <p>At Store</p>
                  <p>At Home</p>
                </div>
              </div>

              {/* ---------------4card-------------------- */}
              <div className="fourcard">
                {/* {Card.length > 0 && (
                  <>
                    {Card[index]?.autoApplyCouponsList?.map((item, cardIndex) => (
                      <div className="card-div" key={`card-${cardIndex}`}>
                        <img
                          src={CardImage[item.couponName]}
                          alt={`Membership card ${item.couponName}`}
                        />
                        <div className="coupon-item">
         
                        </div>
                      </div>
                    ))}

                  </>
                )} */}
                {Card.length > 0 && (
                  <>
                    {Card[index]?.autoApplyCouponsList?.map((couponId, cardIndex) => {
                      debugger
                      const coupon = couponDetails.find((c) => c.id === couponId);

                      // Check if the coupon exists and if a corresponding image exists
                      const couponImage = coupon ? CardImage[coupon.couponName] : null;

                      return (
                        couponImage && (
                          <div className="card-div" key={`card-${cardIndex}`}>
                            <img
                              src={couponImage}
                              alt={`Membership card ${coupon.couponName}`}
                            />
                            <div className="coupon-item">
                              {/* <p>{coupon?.couponName}</p> Optional: You can display the coupon name here */}
                            </div>
                          </div>
                        )
                      );
                    })}
                  </>
                )}

              </div>

              {/* ----------Gold-max-button--------- */}
              <div className="gold-max-button" onClick={() => AddToCart(item)}>
                <div className="goldmax-button">
                  <div className="goldmax-button-top">
                    <h2 className="goldmax-button-top-name">
                      1 Year Gold Membership
                    </h2>
                    <h2 className="goldmax-button-top-price">
                      {item.salePrice} /- KSh
                    </h2>
                  </div>
                  <button
                    className="goldmax-button-added"
                  //   onClick={() => handleAddToCart(item.id)}
                  >
                    Gold Membership Added - View Cart
                  </button>
                </div>
              </div>
            </div>
          ))}

        {/* --------FAQ-------------- */}

        <div className={`faq-data`}>
          <h1 className={`faq-label`}>
            <img src={FAQ} className={``} />
          </h1>

          <ul className={`ul-for-faq-data`}>
            {faqdata.map((item, index) => {
              return (
                <li
                  onClick={() =>
                    selectedfaq != index
                      ? setselectedfaq(index)
                      : setselectedfaq(null)
                  }
                >
                  <p>
                    {item.label}

                    <Icon
                      icon="iconamoon:arrow-down-2-light"
                      style={{ color: "#e0c67c" }}
                    />
                  </p>
                  {selectedfaq == index && (
                    <div className={`faq-data-content`}>
                      <p>{item.data}</p>
                    </div>
                  )}
                </li>
              );
            })}
          </ul>

          <div className={`gold-max-button gold-max-bottom`}>
            <div className="goldmax-button">
              <div className={`goldmax-button-top`}>
                <h2 className={`goldmax-button-top-name`}>
                  1 Year Gold Membership
                </h2>
                {/* <h2 className={`goldmax-button-top-price`}>1200</h2> */}
              </div>
              <button className={`goldmax-button-added`}>
                Gold Membership Added - View Cart
              </button>
            </div>
          </div>
        </div>

        {/* ---------------footer------------------ */}
        {/* <div className={`footer-black`}>
          <div className={`footer-black-top`}>
            <div className={`footer-black-top-left`}>
              <ul>
                <h2>Service</h2>
                <li>Store Locator</li>
                <li>Buying Guide</li>
                <li>Frame Size</li>
              </ul>

              <ul>
                <h2>About Us</h2>
                <li>We Are Hiring</li>
                <li>Refer And Earn</li>
                <li>Refer And Earn</li>
                <li>About us</li>
                <li>Lenskart Coupons</li>
              </ul>

              <ul>
                <h2>Help</h2>
                <li>FAQ's</li>
              </ul>
            </div>

            <div className={`footer-black-top-right`}>
              <div className={`footer-black-top-right-top`}>
                <div>
                  <img src="src\components/Lens2cartGold/googleplay.svg" />
                </div>
                <div>
                  <img src="src\components/Lens2cartGold/appstore.svg" />
                </div>
              </div>

              <div className={`footer-black-top-right-bottom`}>
                <h2>
                  Download Lens2kart App to buy <br />
                  Eyeglasses, Sunglasses and Contact Lenses
                </h2>
              </div>
            </div>
          </div>
          <hr className={`hr-footer-bottom`} />

          <div className={`footer-bottom`}>
            <div className={`footer-bottom-top`}>
              <div className={`footer-bottom-left`}>
                <p>T&C</p>
                <p>Privacy</p>
                <p>Disclaimer</p>
              </div>

              <div className={`footer-bottom-right`}>
                <p>Version 1.00</p>
                <span class="footer-bottom-span-bar">||</span>
                <p>Follow Us</p>
                <div className={`footer-bottom-right-icon`}>
                  <span>
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 1024 1024"
                      xmlns="http://www.w3.org/2000/svg"
                      class="facebook_svg__icon"
                      fill="#fff"
                    >
                      <path d="M880 112H144c-17.7 0-32 14.3-32 32v736c0 17.7 14.3 32 32 32h736c17.7 0 32-14.3 32-32V144c0-17.7-14.3-32-32-32zm-92.4 233.5h-63.9c-50.1 0-59.8 23.8-59.8 58.8v77.1h119.6l-15.6 120.7h-104V912H539.2V602.2H434.9V481.4h104.3v-89c0-103.3 63.1-159.6 155.3-159.6 44.2 0 82.1 3.3 93.2 4.8v107.9z"></path>
                    </svg>
                  </span>

                  <span>
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 24 24"
                      fill="#fff"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path d="M12 2.16c3.206 0 3.586.015 4.847.071 1.172.052 1.805.249 2.226.413a3.71 3.71 0 0 1 1.379.895c.421.422.68.82.895 1.378.164.422.36 1.06.412 2.227.057 1.265.07 1.645.07 4.847 0 3.206-.013 3.586-.07 4.846-.051 1.172-.248 1.805-.412 2.227a3.71 3.71 0 0 1-.895 1.378 3.69 3.69 0 0 1-1.379.895c-.421.165-1.059.361-2.226.413-1.266.056-1.645.07-4.847.07-3.206 0-3.586-.014-4.847-.07-1.172-.052-1.805-.248-2.226-.413a3.71 3.71 0 0 1-1.379-.895 3.691 3.691 0 0 1-.895-1.378c-.164-.422-.36-1.06-.412-2.227-.057-1.265-.07-1.645-.07-4.846 0-3.207.013-3.586.07-4.847.051-1.172.248-1.805.412-2.227.216-.558.478-.96.895-1.378.422-.422.82-.68 1.379-.895.421-.164 1.059-.361 2.226-.413 1.261-.056 1.64-.07 4.847-.07ZM12 0C8.742 0 8.334.014 7.055.07 5.78.127 4.903.333 4.144.628a5.857 5.857 0 0 0-2.128 1.388A5.88 5.88 0 0 0 .628 4.139C.333 4.903.127 5.775.07 7.05.014 8.334 0 8.742 0 12s.014 3.666.07 4.945c.057 1.275.263 2.152.558 2.911.31.792.717 1.463 1.388 2.128a5.866 5.866 0 0 0 2.123 1.383c.764.296 1.636.502 2.911.558 1.28.056 1.688.07 4.945.07 3.258 0 3.666-.014 4.946-.07 1.275-.056 2.151-.262 2.91-.558a5.866 5.866 0 0 0 2.124-1.383 5.866 5.866 0 0 0 1.383-2.123c.295-.764.501-1.636.558-2.911.056-1.28.07-1.688.07-4.945 0-3.258-.014-3.666-.07-4.946-.057-1.275-.263-2.151-.558-2.91a5.62 5.62 0 0 0-1.374-2.133A5.866 5.866 0 0 0 19.861.633C19.097.338 18.225.13 16.95.075 15.666.015 15.258 0 12 0Z"></path>
                      <path d="M12 5.836A6.166 6.166 0 0 0 5.836 12 6.166 6.166 0 0 0 12 18.164 6.166 6.166 0 0 0 18.164 12 6.166 6.166 0 0 0 12 5.836Zm0 10.162A3.999 3.999 0 1 1 12.001 8 3.999 3.999 0 0 1 12 15.998ZM19.847 5.592a1.44 1.44 0 1 1-2.879 0 1.44 1.44 0 0 1 2.879 0Z"></path>
                    </svg>
                  </span>

                  <span>
                    <svg
                      width="1em"
                      height="1em"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill="#fff"
                        fill-rule="evenodd"
                        d="M24 4.309a9.83 9.83 0 0 1-2.828.775 4.94 4.94 0 0 0 2.165-2.724 9.865 9.865 0 0 1-3.127 1.196 4.925 4.925 0 0 0-8.39 4.49A13.974 13.974 0 0 1 1.671 2.9a4.902 4.902 0 0 0-.667 2.476c0 1.708.869 3.216 2.191 4.099A4.936 4.936 0 0 1 .964 8.86v.06a4.926 4.926 0 0 0 3.95 4.829 4.964 4.964 0 0 1-2.224.085 4.93 4.93 0 0 0 4.6 3.42 9.886 9.886 0 0 1-6.115 2.107c-.398 0-.79-.023-1.175-.068a13.945 13.945 0 0 0 7.548 2.212c9.057 0 14.009-7.503 14.009-14.01 0-.213-.005-.425-.014-.636A10.012 10.012 0 0 0 24 4.309"
                      ></path>
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        {/* <Footer /> */}
      </div>
    </>
  );
};

export default MembershipGold;
