import React, { useContext, useEffect, useState } from "react";
import ProductSwap2 from "../../Component/ProductSlider/ProductSwap2";
import ProductSwap3 from "../../Component/ProductSlider/ProductSwap3";
import { Link, useParams } from "react-router-dom";
import ProductSwap from "../../Component/ProductSlider/Productswap";
import ProductSwap4 from "../../Component/ProductSlider/Productswap4";
import Banner1 from "../../Component/Sections/Banner1";
import ReviewAndRating from "../../Component/Rating/ReviewAndRating";
// import ProductCart1 from "../../Component/Card/ProductCart1";
import SimilerProductSection1 from "../../Component/Sections/SimilerProductSection1";
import ProductCart2 from "../../Component/Card/ProductCart2";
import MyContext from "../../MyContext";
import ProductEnquiryModal from "../../Component/Modal/ProductEnquiryModal";
// import ProductSwap from "../Component/ProductSlider/Productswap";
import { useLocation } from 'react-router-dom';
import Order from "../../../API/API_SERVISES/Order.js";
import AlertSuccess from "../../Component/AlertBox/AlertSuccess.jsx";
import ItemMaster from "./../../../API/API_SERVISES/ItemMaster.js";
import Loader1 from "../../Component/Loaders/Loader1.jsx";
import ReletedProductsShow from "./ReletedProductsShow.jsx";
import Slider from "react-slick/lib/slider.js";
import ArrowLeft from "../../Assets/Icons/icon-arrow-left.png"
import ArrowRight from "../../Assets/Icons/icon-arrow-right.png"
import ProductSwap_LC from "../../Component/ProductSlider/ProductSwap_LC.jsx";




const ProductDetailPage = (props) => {
  const { productEnquiry, openproductenquiry, imageBaseUrl, Itemvariation, fetchVariations, currentUser, setItemDataLens, fetchDataCart } = useContext(MyContext);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const location = useLocation();
  const [item, setItem] = useState(location?.state?.item || {});
  const isSearch = location?.state?.isSearch || false;
  console.log(item);
  const [item2, setItem2] = useState(null);
  const [activeComponent, setActiveComponent] = useState(<ProductSwap items={item} />);
  const [description, setDescription] = useState(true);
  const [features, setFeatures] = useState(true);
  const [specification, setSpecification] = useState(true);
  const [review, setReview] = useState(true);
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [filteredAttributeData, setFilteredAttributeData] = useState([]);
  const [selectedParents, setSelectedParents] = useState([]);
  const [AvaliableVariation, setAvaliableVariation] = useState([])
  const [selectedAttributes, setSelectedAttributes] = useState([]);
  const [ClickdParentId, setClickdParentId] = useState(0);
  const [loading, setloading] = useState(false)
  const [attributeFetch, setAttributeFetch] = useState([]);
  const [AttributeData, setAttributeData] = useState([])
  const [apiData, setapiData] = useState([])
  const [itemsPerPageRelated, setItemsPerPageRelated] = useState(4);
  const [isSliderOpen, setSliderOpen] = useState(false);
  const [showModalTechnical, setShowModalTechnical] = useState(false);
  debugger
  const { id: paramId } = useParams(); // Get the 'id' parameter from the route
  const [id, setId] = useState(paramId); // Initialize state with paramId

  useEffect(() => {
    const updateFlexDirection = () => {
      const slickTracks = document.querySelectorAll('.slick-track');
      const isSmallScreen = window.innerWidth < 800;

      slickTracks.forEach((track) => {
        track.style.display = 'flex';
        track.style.flexDirection = isSmallScreen ? 'row' : 'column'; // Change to 'row' if screen is small
      });
    };

    // Initial call to set styles
    updateFlexDirection();

    // Add resize event listener
    window.addEventListener('resize', updateFlexDirection);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener('resize', updateFlexDirection);
    };
  }, []); // Runs once after the component mounts

  useEffect(() => {
    setId(paramId); // Update state with the current route param
  }, [paramId]); // Use paramId in the dependency array

  const isEmptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
  };

  useEffect(() => {
    if (isEmptyObject(item)) {
      fetchProductDetails(id);
    } else if (isSearch && (item.d2_Id || item.d2Id !== id)) {
      fetchProductDetails(id);
    }
  }, [id, item, isSearch]); // Include item and isSearch in dependency array

  const fetchProductDetails = async (d1_Id) => {
    setloading(true); // Start loading
    try {
      const response = await ItemMaster.GetItemMasterById(d1_Id);
      if (response) {
        setItem(response);
      }
    } catch (error) {
      console.error('Failed to fetch product details:', error);
    } finally {
      setloading(false); // Ensure loading is set to false in all cases
    }
  };


  useEffect(() => {
    if (item && currentUser) {
      fetchVariations(item.d1Id)
    }
  }, [currentUser])


  useEffect(() => {
    // Call findItemMaster whenever attributeFetch changes, but not on initial render
    if (attributeFetch.length > 0) {
      findItemMaster(attributeFetch);
    }
  }, [attributeFetch]);




  const opendesc1 = () => {
    setDescription(!description);
  };
  const opendesc2 = () => {
    setFeatures(!features);
  };
  const opendesc3 = () => {
    setSpecification(!specification);
  };

  const opendesc4 = () => {
    setReview(!review);
  };

  const showComponent = (component) => {
    setActiveComponent(component);
  };

  const [inputValues, setInputValues] = useState({
    d1ItemId: item.d1ItemId,
    d2ItemId: item.d2ItemId,
    d1AccountId: 214,
    d2AccountId: 214,
    userId: 1,
    quantity: 1,
    unit: "Pcs",
    itemPrice: item.itemPrice,
  });

  const [modelOpen, setModelOpen] = useState(false)


  const AddToCart = async () => {
    // debugger
    var loginDataString = localStorage.getItem("accountLogin");
    var loginData = JSON.parse(loginDataString);
    if (loginData === null) {
      loginData = {
        d1Id: 0,
        d2Id: 0,
      }
    }

    console.log()
    console.log(item)
    const data = {
      d1ItemId: item.d1_Id || item.d1ItemId || item.d1Id,
      d2ItemId: item.d2_Id || item.d2ItemId || item.d2Id,
      d1AccountId: loginData ? (loginData.d1Id || 0) : 0,
      d2AccountId: loginData ? (loginData.d2Id || 0) : 0,
      userId: 1,
      quantity: 1,
      unit: "Pcs",
      clientId: 0,
      itemPrice: item.salePrice || 0,
      lensPrice: item.lensPrice || 0,
      lensName: item.lensName || "",
      coatingPrice: item.coatingPrice || 0,
      coatingName: item.coatingName || "",

      // box_R: inputValues.boxesRight,
      // box_L: inputValues.boxesLeft,
      // sph_R: inputValues.sphRight,
      // sph_L: inputValues.sphLeft,
      // cyl_R: inputValues.cylRight,
      // cyl_L: inputValues.cylLeft,
      // axis_R: inputValues.axisRight,
      // axis_L: inputValues.axisLeft,
      // add_R: inputValues.addRight,
      // add_L: inputValues.addLeft,
      // dia_R: inputValues.diaRight,
      // dia_L: inputValues.diaLeft,
      // bC_R: inputValues.bcRight,
      // bC_L: inputValues.bcLeft,
    }
    const isSuccess = await Order.insertCreateEcomCartDetail(data);
    if (isSuccess) {
      // setTotalQuantity(isSuccess.length)
      fetchDataCart()
      setAlertSuccess(true)
      setInputValues({
        d1ItemId: " ",
        d2ItemId: " ",
        d1AccountId: " ",
        d2AccountId: " ",
        userId: " ",
        quantity: " ",
        unit: " ",
        itemPrice: " ",
        lensPrice: "",
        lensName: "",
        coatingPrice: "",
        coatingName: "",
        boxesLeft: "",
        boxesRight: "",
        sph_R: "",
        sph_L: "",
        cyl_R: "",
        cyl_L: "",
        axis_R: "",
        axis_L: "",
        add_R: "",
        add_L: "",
        dia_R: "",
        dia_L: "",
        bcRight: "",
        bcLeft: "",
      });

    }
  }

  const fetchData1 = async () => {
    try {
      const val = await ItemMaster.getItemRelatedProdSetting(item.d1Id || item.d1_Id)
      console.log(val);
      setapiData(val);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData1();
  }, [item]);


  useEffect(() => {
    // debugger
    const fetchAttribute = async () => {
      var data1 = await ItemMaster.GetItemAttributeSettings();
      console.log("update data", data1);
      if (data1) {
        setAttributeData(data1)
      } else {
        console.log("error");
      }
    }
    fetchAttribute()
  }, [])

  const SettingAttribute = async (ChildId, ParentId, isAvaliable, ClickdParentId, initialParents, initialAttributes) => {
    debugger
    setloading(true)
    if (ParentId && ClickdParentId) {
      if (ParentId != ClickdParentId) {
        if (!isAvaliable) {
          alert("not avaliable")
          setloading(false)
          return
        }
      }
    }
    var variationData = await fetchVariations(item.d1Id)
    const filterItemAccordingToChild = await variationData?.filter(item => item.variation.find(items => (items.attr === ChildId)))
    console.log("filterItemAccordingToChild", filterItemAccordingToChild);

    setAvaliableVariation(filterItemAccordingToChild)
    setClickdParentId(ParentId)
    // Determine the index based on whether selectedParents has any entries
    let index = -1; // Initialize index

    if (selectedParents.length > 0) {
      index = selectedParents.indexOf(ParentId);
    } else {
      index = initialParents.indexOf(ParentId);
    }

    // Update or add the ParentId and ChildId accordingly
    if (index !== -1) {
      // ParentId already exists; update the corresponding attribute
      const updatedAttributes = [...(selectedAttributes.length > 0 ? selectedAttributes : initialAttributes)];
      updatedAttributes[index] = ChildId; // Update attribute at the found index

      setSelectedAttributes(updatedAttributes); // Update state for selected attributes
    } else {
      // ParentId does not exist; add it
      setSelectedParents([...selectedParents, ParentId]); // Add ParentId to selected parents
      setSelectedAttributes([...selectedAttributes, ChildId]); // Add ChildId to selected attributes
    }


    // Update attributeFetch state

    setAttributeFetch(prevAttributes => {
      const attributeExists = prevAttributes.some(items => items.parentId === ParentId);

      if (attributeExists) {
        // Update the existing object with the same parentId
        setloading(false)
        return prevAttributes.map(items =>
          items.parentId === ParentId ? { ...items, attrId: ChildId } : items
        );
      } else {
        // Add a new object if parentId does not exist
        setloading(false)
        return [{ parentId: ParentId, attrId: ChildId }];
      }
    });


    // Set loading state
    setloading(true);
  };






  const updateItemsPerPage = () => {
    setItemsPerPageRelated(numItemsToShow());
  };


  const numItemsToShow = () => {
    if (window.innerWidth < 768) {
      return 2;
    } else if (window.innerWidth < 950) {
      return 4;
    } else {
      return 4;
    }
  };

  useEffect(() => {
    updateItemsPerPage();
    window.addEventListener('resize', updateItemsPerPage);

    // Cleanup the event listener on component unmount
    return () => window.removeEventListener('resize', updateItemsPerPage);
  }, []);



  const getProductIdFromUrl = async () => {
    // debugger
    const url = window.location.href;
    const urlParts = url.split('/');
    return urlParts[urlParts.length - 1]; // Assuming the ID is at the end of the URL
  };
  // const fetchItem = async () => {
  //   // debugger
  //   if (location.state === null && location.state === undefined) {
  //     var itemId = getProductIdFromUrl();
  //     const data = await ItemMaster.GetItemMasterById(itemId);
  //     if (data) {
  //       console.log(data);
  //       setItem(data);
  //     }
  //   }
  // }
  // useEffect(() => {
  //   fetchItem()
  // }, [])

  // useEffect(() => {
  //   // debugger
  //   getProductIdFromUrl()
  //   const fetchData = async () => {
  //     try {
  //       const id = await getProductIdFromUrl();
  //       const data = await ItemMaster.GetItemMasterById(id);
  //       if (data) {
  //         console.log(data);
  //         setItem2(data);
  //       }
  //     } catch (error) {
  //       console.error('Error fetching item data:', error);
  //     }
  //   };

  //   fetchData();

  // }, []);

  const fetchItemData = async () => {
    try {
      let itemId = location.state?.id || getProductIdFromUrl(); // Use location.state if available
      if (itemId) {
        const data = await ItemMaster.GetItemMasterById(itemId);
        if (data) {
          console.log(data);
          setItem(data);
          setItem2(data); // If both states hold the same data, consider using only one state
        }
      }
    } catch (error) {
      console.error("Error fetching item data:", error);
    }
  };

  useEffect(() => {
    fetchItemData();
  }, []);



  const findItemMaster = async (updatedAttributeFetch) => {
    console.log(selectedParents);
    console.log(selectedAttributes);

    // Construct filteredData using updatedAttributeFetch
    const filteredData = [{
      fieldName: "attributeParentId",
      operatorName: "equal",
      compareValue: item.iAttributeParent || item.attributeParentId,
    }];

    // Call API or function with updatedAttributeFetch
    // itmAttribute
    // attributeId
    var data = await ItemMaster.GetItemMasterFilterVariation(filteredData, updatedAttributeFetch);
    if (data.length > 0) {
      console.log(data); // Log the data fetched
      const filterData1 = data.filter(findItem => {
        const attributeIds = findItem.itmAttribute.map(itemAttributeChild => itemAttributeChild.attributeId);
        return selectedAttributes.every(attrId => attributeIds.includes(attrId));
      });

      if (filterData1.length > 0) {
        setItem(filterData1[0]); // Set the first matched item
      } else {
        setItem(data[0]); // Fallback to the first item if no matches
      }
      setloading(false);
    }
    setloading(false);
  };


  useEffect(() => {
    // Initialize selectedParents and selectedAttributes from filteredAttributeData
    if (item.itmAttribute && item.itmAttribute.length > 0) {
      const initialParents = item.itmAttribute.map(category => category.attributeParentId);
      const initialAttributes = item.itmAttribute.map(category => category.attributeId);
      setSelectedParents(initialParents);
      setSelectedAttributes(initialAttributes);
      const index = item.itmAttribute.findIndex(attr => attr.attributeParentName?.toLowerCase() == "color" || attr.attributeParentName?.toLowerCase() == "colour");
      if (index !== -1) {
        const attributeId = item.itmAttribute[index].attributeId;
        const attributeParentId = item.itmAttribute[index].attributeParentId;
        setClickdParentId(item.itmAttribute[index].attributeParentId)
        SettingAttribute(attributeId, attributeParentId, 0, 0, initialParents, initialAttributes);
      } else {
        console.log("Attribute with parent name 'colour' not found.");
      }
    }
  }, [currentUser]);


  useEffect(() => {
    console.log(AvaliableVariation, "AvaliableVariation");
  }, [AvaliableVariation])

  useEffect(() => {
    if (currentUser === true) {
      if (AttributeData?.length > 0 && Itemvariation?.length > 0 && currentUser) {


        const filteredAttributes = AttributeData.filter(attribute => {
          return attribute.attributeChilds.some(child => {
            const found = Itemvariation.some(item => {
              return item.variation.some(variation => {
                return variation.attr_prnt === attribute.attributeId && variation.isActive;
              });
            });
            return found;
          });
        });

        const filteredAttributeData = filteredAttributes.map(attribute => {

          const filteredChilds = attribute.attributeChilds.filter(child => {
            return Itemvariation.some(item => item.variation.some(variant => variant.attr === child.attrId));
          });
          return {
            ...attribute,
            attributeChilds: filteredChilds
          };
        });
        setFilteredAttributeData(filteredAttributeData)
      }
    }
  }, [currentUser, AttributeData, Itemvariation]);



  useEffect(() => {
    if (alertSuccess) {
      const timeoutId = setTimeout(() => {
        setAlertSuccess(false);
      }, 500); // 3 seconds
      return () => clearTimeout(timeoutId);
    }
  }, [alertSuccess]);

  let isActiveValue1 = null;
  if (Itemvariation?.length > 0) {

    const filteredItems = Itemvariation[0].variation.filter(item => item.attr_prnt_nm.toLowerCase() === "color" || item.attr_prnt_nm.toLowerCase() === "colour");
    if (filteredItems.length > 0) {
      // Assuming isActive is a property of the filtered item 
      isActiveValue1 = filteredItems[0].isActive;
    } else {
      // Handle case where no items match the filter condition
      console.log("No items with attr_prnt_nm === 'color' found");
    }

  }


  const images = [];

  if (item && item.images) {
    for (let i = 0; i < Math.min(item.images.length); i++) {
      images.push(`${imageBaseUrl}${item.images[i]}`);
    }
  }


  const ArrowButtonPrevious = ({ onClick }) => {
    return (
      <button
        onClick={onClick}
        style={{ backgroundColor: "transparent", border: "none" }}
      >
        <img
          src={ArrowLeft}
          alt={"Left Arrow"}
          style={{
            width: "50px",
            height: "50px",

          }}
        />
      </button>
    );
  };

  const ArrowButtonNext = ({ onClick }) => {
    return (
      <button
        onClick={onClick}
        style={{ backgroundColor: "transparent", border: "none" }}
      >
        <img
          src={ArrowRight}
          alt={"Left Arrow"}
          style={{
            width: "50px",
            height: "50px",

          }}
        />
      </button>
    );
  };

  const [imageIndex, setImageIndex] = useState(0)

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 4, // Number of slides visible at once vertically
    slidesToScroll: 2, // Number of slides to scroll at once vertically
    vertical: true, // Enables vertical sliding
    verticalSwiping: true, // Enables vertical swiping for mouse/touch input
    prevArrow: <ArrowButtonPrevious />,
    nextArrow: <ArrowButtonNext />,
    responsive: [
      {
        breakpoint: 800, // screen width of 800px or less
        settings: {
          slidesToShow: 4, // Show 2 slides at a time on small screens
          slidesToScroll: 2, // Scroll 1 slide at a time on small screens
          vertical: false, // Ensures vertical scrolling is still enabled
          verticalSwiping: false
        }
      }
    ]
  };



  const ChangeImage = (index) => {
    setImageIndex(index)
  }



  const toggleSlider = (itemData) => {
    console.log(itemData);
    setItemDataLens(itemData)
    setSliderOpen(true);
  };
  const toggleSliderClose = () => {
    // console.log(editData);
    setSliderOpen(false);
  };
  const [isModalOpenInfo, setIsModalOpenInfo] = useState(false);
  const [modalBox, setModalBox] = useState(false);



  const toggleModal = () => {
    setShowModalTechnical(!showModalTechnical);
  };

  const toggleModalInfo = () => {
    setIsModalOpenInfo(!isModalOpenInfo);
  };
  const hideModal3 = () => {
    setModalBox(false);
  };
  const handleModalClick = (e) => {
    // Prevent the event from propagating to parent elements
    e.stopPropagation();
  };

  const closeInfoModal = () => {
    setIsModalOpenInfo(false);
  };


  return (
    <div>
      <div className="ProductDetailPage">
        <div className="ProductDetailPage-left">
          <div className="ProductDetailPage-imagebox1" style={{ justifyContent: "center" }}>
            <div className=" ProductDetailPage-imagebox1-theme3">
              <div className="Flex-ImagesTheme_Lc Flex-ImagesTheme_Lc-theme-2">
                {images.length > 4 ? (
                  <Slider {...settings} className="slick-slider-custom">
                    {images.map((imageUrl, index) => (
                      <div key={index} className="Flex-ImagesTheme_Lc"  >
                        <div
                          onClick={() => ChangeImage(index)}
                          className="ProductDetailPage-smallimg-theme lense-view-container-theme3 lense-view-container-theme-2"
                        >
                          <div
                            style={{
                              backgroundImage: `url(${imageUrl})`,
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "100%",
                              width: "100%",
                              borderRadius: '3px'

                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </Slider>
                ) : (
                  <>
                    {images.map((imageUrl, index) => (
                      <div key={index} className="Flex-ImagesTheme_Lc" style={{ width: "100px" }}>
                        <div
                          onClick={() => ChangeImage(index)}
                          className="ProductDetailPage-smallimg-theme lense-view-container-theme3 lense-view-container-theme-2"
                        >
                          <div
                            style={{
                              backgroundImage: `url(${imageUrl})`,
                              backgroundPosition: "center",
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "100%",
                              width: "100%",
                              borderRadius: '8px'
                            }}
                          />
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>


          <div className="modaldisplay-parent" >
            <div className="Modal-Display">
              <label className="close_modal-theme3"  ><i class="fa-solid fa-xmark"></i></label>
              <div style={{ display: "flex", justifyItems: "baseline", justifyContent: "center" }}>
                <div className="ing-size-xx-xl-theme3" style={{ margin: "0px" }}>
                  <ProductSwap_LC items={item} imageIndex={imageIndex} />
                </div>
              </div>
            </div>
          </div>

          {/* <div className="ProductDetailPage-imagebox2">{activeComponent}</div> */}



        </div>
        <div className="ProductDetailPage-right">
          <div className="productdetailbox">
            <p className="productdetail-name font-2">
              {item.name}
            </p>

            <div
              className="productdetail-name"
              style={{ display: "flex", alignItems: "center" }}
            >
              <p className="product-detail-price">
                <i className="fa-solid fa-indian-rupee-sign"></i> {item.salePrice}
              </p>
              <p className="product-detail-price-2">
                <i className="fa-solid fa-indian-rupee-sign"></i> {item.salePrice * 3}
              </p>
            </div>
            {/* <p className="productdetail-name"> */}
            <div>
              <p className="product-detail-desc">
                {item.productAlias}
              </p>
            </div>
            {/* <div className="color-section-pd">
                <p style={{ marginBottom: "0" }}>Color</p>
                <div className="color-option-pd">
                  <div className="outline-coloroption">
                    <div className="color-option-box1"></div>
                  </div>
                  <div className="outline-coloroption">
                    <div className="color-option-box2"></div>
                  </div>
                  <div className="outline-coloroption">
                    <div className="color-option-box3"></div>
                  </div>
                  <div className="outline-coloroption">
                    <div className="color-option-box4"></div>
                  </div>
                  <div className="outline-coloroption">
                    <div className="color-option-box5"></div>
                  </div>
                </div>
              </div> */}

            {filteredAttributeData && filteredAttributeData.length > 0 && (
              <div className="attributesField">
                {(item.productType == "3" || item.productType == 3) && filteredAttributeData.length > 0 && filteredAttributeData.map(category => (
                  <div key={category.attributeId}>

                    <h3 style={{ fontSize: "15px" }}>{category.attributeName}
                      {/* {category.attributeId} */}
                    </h3>
                    <div className="sizeVarient">
                      {category.attributeChilds.map(attribute => {
                        const index = selectedParents.indexOf(category.attributeId);
                        const isSelected = index !== -1 && selectedAttributes[index] === attribute.attrId;

                        const attrValuesAvaliable = [...new Set(AvaliableVariation?.flatMap(item => item.variation.map(v => v.attr)))];
                        const index1 = attrValuesAvaliable?.indexOf(attribute.attrId)
                        const isAvaliable = index1 !== -1 && attrValuesAvaliable[index1] == attribute.attrId;
                        console.log(attribute.attrId, attrValuesAvaliable[index1], isAvaliable, index1);
                        const itemVariation = Itemvariation.find(variation =>
                          variation.variation.some(variant => variant.attr === attribute.attrId)
                        );
                        const imageId = item.images.length > 0 ? item.images[0] : 0;

                        const cntrlType = itemVariation ? itemVariation.cntrlTypeImgTxt : 2;
                        const displayContent = cntrlType === 1 && imageId
                          ? <img src={`${imageBaseUrl}${imageId}`} alt={attribute.attrName} width={40} height={40} />
                          : attribute.attrName

                        return (
                          <div
                            key={attribute.attrId}
                            className={`${isSelected ? 'selectedAttribute' : 'notSelectedAttribute'} ${ClickdParentId !== category.attributeId ? (isAvaliable ? 'Avaliable' : 'notAvailable') : ''}`}
                            // className={`${isSelected ? 'selectedAttribute' : 'notSelectedAttribute'} ${ClickdParentId === attribute.attrId ? (isAvaliable ? 'Avaliable' : 'notAvailable'}):("") `}
                            onClick={() => SettingAttribute(attribute.attrId, category.attributeId, isAvaliable, ClickdParentId)}
                          >
                            {displayContent}
                            {/* {ClickdParentId} --- {category.attributeId} */}
                            {/*
                                                    */}
                            {/* {attribute.attrId} */}
                            {/* {isAvaliable ? 'True' : 'false'} */}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                ))}
              </div>
            )}


            {/* </p> */}
            <div className="productdetail-bottom-box">
              {/* <div className="product-detail-button-box">
                <div
                  // to="/cart2" 
                  onClick={() => AddToCart(item.id)} className="product-detail-button">
                  Buy Frame Only{" "}
                </div>
                <Link to="/buywithlens" className="product-detail-button">
                  Buy With Lens
                </Link>
              </div> */}


              <div className="productdetail-bottom-box">
                <div className="product-detail-button-box-theme3 ">
                  <div
                    onClick={() => AddToCart(item.id)} className="product-detail-button-theme3">
                    Buy Now{" "}
                  </div>
                  {item.productCategory == "1" ? (
                    <div onClick={() => toggleSlider(item)}
                      className="product-detail-button-theme3">
                      Buy With Lens
                    </div>
                  ) : null}
                </div>
                <div className="productdetail-name">
                  <p className="product-detail-return">
                    Return Policy : 7 day easy return policy
                  </p>
                  {/* <p
                                     onClick={openproductenquiry}
                                    className="product-detail-enquiry"
                                >
                                    Product Enquiry
                                </p> */}
                </div>
                <div className="Flex-Technical-Arrow" onClick={toggleModal}>
                  <div className="Technical-Down-Lc">Technical information</div>
                  <div className="">
                    {/* <i className="fa-solid fa-angle-down right-arrow-iconLC"></i> */}
                    <i
                      className={`fa-solid fa-angle-${showModalTechnical ? "up" : "down"
                        } right-arrow-iconLC`}
                    ></i>
                  </div>
                </div>
                {showModalTechnical && (
                  <div className="Features-Technical-Box">
                    <table>

                      <div className="" style={{ margin: '0' }} dangerouslySetInnerHTML={{ __html: item.shortDescription }} />
                      <tr className="">
                        <td
                          className="Font-TechInfo_Lc Color-ShowAllInfo-Lc"
                          onClick={toggleModalInfo}
                        >
                          Show All Information
                        </td>
                        <td className="Font-TechInfo_Lc"></td>
                      </tr>
                    </table>
                  </div>
                )}

                {isModalOpenInfo && (
                  <div onClick={hideModal3} className="modal-back">
                    <div
                      onClick={handleModalClick}
                      style={{ zIndex: "5" }}
                      className="OpticalField-modal increase_modal"
                    >
                      <div style={{ padding: "5px" }}>
                        <div className="Modal-Title-Lc">Technical information
                          <label
                            className="close_modal-theme3"
                            onClick={closeInfoModal}
                          >
                            <i class="fa-solid fa-xmark"></i>
                          </label>
                        </div>
                        <div>
                          <div className="" style={{ margin: '0' }} dangerouslySetInnerHTML={{ __html: item.longDescription }} />
                          {/* <div className="Modal-Lc-theme3">
                                                    <ModalTechnicalInforamation />
                                                </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                )}

              </div>
              <div className="productdetail-name">
                {/* <p className="product-detail-return">
                  Return Policy : 7 day easy return policy
                </p>
                <p
                  onClick={openproductenquiry}
                  className="product-detail-enquiry"
                >
                  Product Enquiry
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="ProductDetailPage-bottom">
        <div className="ProductDetailPage-bottom-1">
          <div onClick={opendesc1} className="accordion-box">
            Description
            {description ? (
              <div>
                <i className="fa-solid fa-minus minus-btn"></i>
              </div>
            ) : (
              <div>
                <i className="fa-solid fa-plus plus-btn"></i>
              </div>
            )}
          </div>
          {description && (
            <div className="Description-accordian-box">
              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Nostrum
                laborum, nemo pariatur minima minus totam sit accusantium
                impedit sunt mollitia repellat adipisci dolorum iure
                exercitationem nihil, libero neque recusandae consequatur
                numquam repellendus. Quibusdam alias placeat animi labore dolor
                corporis delectus nemo nam quas provident sint sequi, eos,
                deleniti, perferendis sed.
              </p>
            </div>
          )}
          <div onClick={opendesc2} className="accordion-box">
            Features
            {features ? (
              <div>
                <i className="fa-solid fa-minus minus-btn"></i>
              </div>
            ) : (
              <div>
                <i className="fa-solid fa-plus plus-btn"></i>
              </div>
            )}
          </div>
          {features && (
            <div className="Features-accordian-box">
              <table>
                <tr className="dark-tablerow">
                  <td> Frame Material</td>
                  <td>Metal</td>
                </tr>
                <tr className="light-tablerow">
                  <td> Lens Material</td>
                  <td>Polycarbonate</td>
                </tr>
                <tr className="dark-tablerow">
                  <td>Frame Color</td>
                  <td>Black</td>
                </tr>
                <tr className="light-tablerow">
                  <td>Shape</td>
                  <td>Square</td>
                </tr>
                <tr className="dark-tablerow">
                  <td>Brand</td>
                  <td>KILLER</td>
                </tr>
                <tr className="light-tablerow">
                  <td>Gender</td>
                  <td>Men, Women</td>
                </tr>
                <tr className="dark-tablerow">
                  <td>Dimensions</td>
                  <td>48-20-140</td>
                </tr>
                <tr className="light-tablerow">
                  <td>SKUID</td>
                  <td>E12B10286</td>
                </tr>
                <tr className="dark-tablerow">
                  <td>Product type</td>
                  <td>eyeglasses</td>
                </tr>
                <tr className="light-tablerow">
                  <td>Country of Origin</td>
                  <td>India</td>
                </tr>
                <tr className="dark-tablerow">
                  <td>Manufactured by</td>
                  <td>Scavin International Exim Pvt. Ltd.</td>
                </tr>
                <tr className="light-tablerow">
                  <td>Sold by</td>
                  <td>EyeMyEye Pvt Ltd</td>
                </tr>
                <tr className="dark-tablerow">
                  <td>Raw Material Sourced from</td>
                  <td> India </td>
                </tr>
              </table>
            </div>
          )}
          <div onClick={opendesc3} className="accordion-box">
            Specification
            {specification ? (
              <div>
                <i className="fa-solid fa-minus minus-btn"></i>
              </div>
            ) : (
              <div>
                <i className="fa-solid fa-plus plus-btn"></i>
              </div>
            )}
          </div>
          {specification && (
            <div className="Specification-accordian-box">
              <p>✔ Full Frame</p>
              <p>✔ Light Weight and Comfortable</p>
              <p>✔ Strong and Durable</p>
              <p>✔ Free Eyeglasses Case with Cleaning Cloth</p>
            </div>
          )}

          <div
            onClick={opendesc4}
            className="accordion-box"
            style={{ borderBottom: "0" }}
          >
            Review & Rating
            {review ? (
              <div>
                <i className="fa-solid fa-minus minus-btn"></i>
              </div>
            ) : (
              <div>
                <i className="fa-solid fa-plus plus-btn"></i>
              </div>
            )}
          </div>
          {review && <ReviewAndRating />}
        </div>
      </div> */}



      <Banner1 />


      <ReletedProductsShow
        apiData={apiData}
        itemsPerPageRelated={itemsPerPageRelated}
      />



      {/* <SimilerProductSection1
        heading="Similer Products"
        productComponent={<ProductCart2 />}
      /> */}

      {productEnquiry && (
        <ProductEnquiryModal handleClick={openproductenquiry} />
      )}
      {alertSuccess && (<AlertSuccess />)}
      {loading && (<Loader1 />)}
    </div>
  );
};

export default ProductDetailPage;
