import React from "react";
import OrderBox from "./OrderBox";
import OrderBox_Lc from "./OrderBox_Lc";

const MyOrder = () => {
  return (
    <div className="admin-myorder">
      <label
        className="title"
        style={{
          marginBottom: "0",
          borderBottom: "1px solid rgb(230, 230, 230)",
        }}
      >
        My Order
      </label>
      <div className="favourite-box-container">
        {/* <div className='myorder-box'></div>
            <div className='myorder-box'></div>
            <div className='myorder-box'></div>
            <div className='myorder-box'></div> */}
            
            {/* <OrderBox/> */}

            {/* <OrderBox/> */}

            {/* <OrderBox/> */}

            <OrderBox_Lc/>

          </div>

    </div>
  );
};

export default MyOrder;
