import React, { useContext, useEffect, useState, useMemo, useRef } from "react";
import contactlens from "../../Assets/Image_lc/contactlens-cat.jpeg";
import monthly from "../../Assets/Image_lc/ContectLenses/monthly.webp";
import { Link } from 'react-router-dom';
import MyContext from "../../MyContext";
import { useLocation, useParams } from 'react-router-dom';


const ContactLenseCart = () => {
    const { imageBaseUrl, itemGroupData,currentUser, shopBanner } = useContext(MyContext);
    console.log(shopBanner);

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }

    const location = useLocation();
    const query = useQuery();
    const productId = query.get('productId');
    console.log(productId);
    console.log(itemGroupData);

    const ImageId = shopBanner.filter(item => item.showInCategoryGroup == productId).map(image => image.bannerImageId)
    console.log(ImageId);

    return (

        <div>
            <div className="contactlens-theme2">
                <div className="contact-lens-order">
                    ORDER WITH CONTACT LENS EXPERT - GIVE MISSED CALL ON
                    <span className="contno-theme2">  +91 847-000-7367</span>
                </div>
                <div className="contact-cat-lens">
                    {/* <img className="category-contactlens" src={contactlens} alt="" /> */}
                    {ImageId.length > 0 && (
                        <img
                            style={{
                                height: ImageId.length === 0 ? "0" : "max-content"
                            }}
                            className="category-contactlens"
                            src={ImageId.length > 0 ? `${imageBaseUrl}${ImageId[0]}` : ""}
                            alt=""
                        />
                    )
                    }
                </div>

                <div className="container-cl">
                    {itemGroupData
                        .filter(subItem => subItem.primaryGroup === false && subItem.parentGrp == productId
                            && subItem.showInMenu === true
                        )
                        .map((item, index) => (

                            <div className="column-box-contactlens" key={index}>
                                <div className="Sub-header-contains" >
                                    <h2 className="sub-group-contlens">
                                        {/* <Link to={`/productsearch?productId=${item.d2_Id}`} className="sub-group-contlens">{item.menuName}</Link> */}
                                        {productId ? <p>Coming Soon</p>: <p>No product selected.</p>}
                                    </h2>

                                </div>
                                <div className="">
                                    {itemGroupData.filter(data => data.parentGrp == item.d1_Id).map(item => (
                                        <Link to={`/productsearch?productId=${item.d2_Id}`} key={item.id} className="flex-monthly-clens">
                                            {item.images && item.images[0] ? (
                                                <div>
                                                    <img className="" src={`${imageBaseUrl}${item.images[0]}`} alt={item.label} />
                                                </div>
                                            ) : (
                                                <div style={{ display: "none" }}>
                                                    <img className="" src="" alt="" />
                                                </div>
                                            )}
                                            <div className="monthly-margin-cl">
                                                <label className="lbl-monthly-cl">{item.menuName}</label>
                                            </div>
                                            <div className="arrow-right-cl">
                                                <i className="fa-solid fa-angle-right"></i>
                                            </div>
                                        </Link>
                                    ))}
                                </div>
                            </div>

                        ))

                    }

                </div>

            </div>
        </div>
    );
};

export default ContactLenseCart;
