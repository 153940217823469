import React, { useEffect, useState, useContext } from "react";
import AddAddressModalProfle from "../Modal/AddAddressModalProfle";
import AddAddress from "../../../API/API_SERVISES/AddAddress";
import Loader1 from "../Loaders/Loader1";
import MyContext from "../../MyContext";

const MyAddress = (props) => {
  const [selectedAddress, setSelectedAddress] = useState(null);


  const [EditData, setEditData] = useState();

  // const handleCheckboxChange = (addressIndex) => {
  //   setSelectedAddress(addressIndex);
  // };

  const { DefaultAddressChange,
    setDefaultAddressChange } = useContext(MyContext)

  const handleCheckboxChange = async (addressIndex, id) => {
    var getaddress = await AddAddress.getAddressById(id, myAccountID);
    if (getaddress.isDefault == false) {
      const updatedValues = {
        ...getaddress,
        "isDefault": true,
      };
      await AddAddress.updateAddress(updatedValues, myAccountID);
    }
    setDefaultAddressChange(!DefaultAddressChange)
    alert("Updated Default Address");
    fetchAddress()
  };

  // const editaddress = () => {
  //   alert("edit");
  // };

  // const deleteaddress = () => {
  //   alert("delete");
  // };

  const editaddress = async (id) => {
    var getaddress = await AddAddress.getAddressById(id, myAccountID);
    // console.log(data);
    console.log(getaddress);
    setEditData(getaddress);
    setAddressModal(true);
  };

  const deleteaddress = async (id) => {
    if (window.confirm("Are you sure you want to delete this address?")) {
      setLoading(true);
      try {
        var deletedata = await AddAddress.deleteAddress(id, myAccountID);
        if (deletedata) {
          alert("Address Deleted");
          fetchAddress();
        } else {
          alert("Error Occurred");
        }
      } catch (error) {
        alert("Error Occurred: " + error.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const [addresses, setAddAddresses] = useState([]);

  const [myAccountID, setMyAccountID] = useState();
  const [loading, setLoading] = useState(true);
  // const [addresses, setAddAddresses] = useState([]);


  const [myAccountdata, setMyAccountdata] = useState([]);
  console.log(myAccountdata)


  useEffect(() => {
    // Retrieve data from local storage when the component mounts
    const storedData = localStorage.getItem('accountLogin');

    if (storedData) {
      // If data is found, update the state
      setMyAccountdata(storedData);
    }
  }, []);


  const fetchAddress = async () => {

    var loginDataString = localStorage.getItem("accountLogin");
    var loginData = JSON.parse(loginDataString);
    console.log(loginData.d1Id);
    setMyAccountID(loginData.d1Id)
    var addresses = await AddAddress.getAddress(loginData.d1Id);
    if (addresses) {
      setAddAddresses(addresses);
      setAddressModal(false);
      setLoading(false);
    } else {
      alert("Error Occoured");
    }
  };

  useEffect(() => {
    const fetchAddress = async () => {
      var loginDataString = localStorage.getItem("accountLogin");
      var loginData = JSON.parse(loginDataString);
      console.log(loginData.d1Id);
      setMyAccountID(loginData.d1Id)
      var addresses = await AddAddress.getAddress(loginData.d1Id);
      if (addresses) {
        setAddAddresses(addresses);
        setAddressModal(false);
        setLoading(false);
      } else {
        alert("Error Occoured");
      }
    };

    fetchAddress();
  }, []);

  console.log(addresses);

  const [addressModal, setAddressModal] = useState(false);
  const openAddressmodal = () => {
    setAddressModal(!addressModal);
    setEditData("")
  };

  return (
    <div>
      <div style={{ display: "block", height: "90vh" }} className="master-container">
        <div style={{ height: "78vh", borderRadius: "15px" }} className="card-cart cart height">
          
          <label className="title">
            SAVED ADDRESS
          </label>
          <button className="add-address-button" onClick={openAddressmodal}>
            <i className="fa-solid fa-plus add-address-admin"></i>  ADD NEW ADDRESS
          </button>
          <div className="straight-line-address"></div>
          {addresses && addresses.length > 0 ? (
            addresses.map((address, index) => (
              <div className="address-box" key={index} >
                <div onClick={() => handleCheckboxChange(index, address.id)}>
                  <label className="custom-checkbox address-logo">
                    <input
                      type="checkbox"
                      name="addressCheckbox"
                      value={address.isDefault}
                      checked={address.isDefault}
                      disabled
                    // checked={selectedAddress === index}
                    // onChange={() => handleCheckboxChange(index)}
                    />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div>
                  <p className="address-text address-name-x">{address.name}</p>
                  <p className="address-text-xl">{address.address}</p>
                  <p className="address-text-xl">{address.city} - <span>{address.pincode}</span></p> 
                  {/* <p className="address-text-xl">{address.countryCode} - <span>{address.state}</span></p>  */}
                  <p className="address-text-xl">Phone - {address.countryCallCode} {address.mobile}</p>

                </div>
                <div className="adress-delete-edit">
                  <i onClick={() => editaddress(address.id)} style={{ color: "#305197", fontSize: "15px" }} className="fa-regular fa-pen-to-square fs-b"></i>
                  <i onClick={() => deleteaddress(address.id)} style={{ color: "#e02121", fontSize: "15px" }} className="fa-solid fa-trash fs-b "></i>
                </div>
              </div>
            ))
          ) : (
            <div></div>
          )}
        </div>
      </div>
      {addressModal && (
        <AddAddressModalProfle
          data={EditData}
          modelOpen={true}
          fetchAddress={fetchAddress}
          modalclose={openAddressmodal}
        />
      )}
      {loading && <Loader1 />}
    </div>
  );
};

export default MyAddress;
