import React, { useContext, useEffect, useState } from "react";
import Prescription from "../../Assets/Image_lc/prescription.png";
import call from "../../Assets/Image_lc/call back.jpg";
import ModelPrescriptionManually from "./ModelPrescriptionManually";
import ModelSavedPrescription from "./ModelSavedPrescription";
import ModelUploadPrescription from "./ModelUploadPrescription";
import ModelRequestACallBack from "./ModelRequestACallBack";
import Transaction from "../../../API/API_SERVISES/Transaction";
import ItemMaster from "../../../API/API_SERVISES/ItemMaster";
import MyContext from "../../MyContext";
import transitions from "@material-ui/core/styles/transitions";
import enterManually from "../../Assets/Images/enterManually.png";
import uploadPrescription from "../../Assets/Images/uploadPrescription.png";
import PayStack from "../../../API/API_SERVISES/PayStack";
import Loader1 from "../Loaders/Loader1";

const OrderBox_Lc = () => {
  const [isClicked, setIsClicked] = useState({});
  const [isModalOpenPower, setIsModalOpenPower] = useState({});

  const { imageBaseUrl, companyData } = useContext(MyContext);

  const [loading, setLoading] = useState(true);

  const toggleClass = (vouchNo) => {
    setIsClicked((prevState) => ({
      ...prevState,
      [vouchNo]: !prevState[vouchNo], // Toggle only the specific modal state
    }));
    // setIsClicked(!isClicked);
  };

  const tableData = [
    {
      left: {
        sph: -1.5,
        cyl: -0.75,
        axis: 90,
        add: 1.0,
        pupil: 2.5,
      },
      right: {
        sph: -1.25,
        cyl: -0.5,
        axis: 85,
        add: 1.0,
        pupil: 2.5,
      },
    },
    // Additional rows here...
  ];

  const [isSubmitted, setIsSubmitted] = useState(false);

  // Function to be passed down to child to update the state
  const handleSubmitEyePower = () => {
    setIsSubmitted(true); // When the user clicks the submit button
  };
  /****************************************************** */

  const [refId, setRefId] = useState();
  const [itemData, setItemData] = useState({
    itemId: 0,
    itemName: "",
    imgId: 0,
    lensType: "",
    vouchNo: "",
    transId: 0,
    refId: "",
    linkedItem: "",
    mastId2: 0,
  });

  const [isModalOpenManually, setIsModalOpenManually] = useState(false);

  const toggleModalManually = (
    refId,
    itemId,
    itemName,
    imgId,
    lensType,
    vouchNo,
    transId,
    linkedItm,
    mastId2
  ) => {
    setRefId(refId);
    setItemData((prevValues) => ({
      ...prevValues,
      itemId: itemId,
      itemName: itemName,
      imgId: imgId,
      lensType: lensType,
      vouchNo: vouchNo,
      transId: transId,
      refId: refId,
      linkedItem: linkedItm,
      mastId2: mastId2,
    }));
    setIsModalOpenManually(!isModalOpenManually);
  };

  const closeModalManually = () => {
    setIsModalOpenManually(false);
  };

  /************************************************ */
  // const fetchLensData = async () => {
  //     setLoading(true);
  //     try {
  //         const loginDataString = localStorage.getItem("accountLogin");
  //         if (loginDataString) {
  //             const loginData = JSON.parse(loginDataString);
  //             const data = await Transaction.getSaleLensDataByTransId(0, loginData.d2Id);
  //             const filterData = data.filter(item => !item.imgId); // Simplified the condition
  //             setSaveLensData(filterData);
  //             setLoading(false);
  //         }
  //     } catch (error) {
  //         console.log(error);
  //         setLoading(false);
  //     }
  //     finally {
  //         setLoading(false);
  //     }
  // };

  const [isModalOpenSaved, setIsModalOpenSaved] = useState(false);
  const [savedLensData, setSaveLensData] = useState();

  const toggleModalSaved = async (
    refId,
    itemId,
    itemName,
    imgId,
    lensType,
    vouchNo,
    transId,
    linkedItm,
    mastId2
  ) => {
    setRefId(refId);
    setItemData((prevValues) => ({
      ...prevValues,
      itemId: itemId,
      itemName: itemName,
      imgId: imgId,
      lensType: lensType,
      vouchNo: vouchNo,
      transId: transId,
      refId: refId,
      linkedItem: linkedItm,
      mastId2: mastId2,
    }));
    //await fetchLensData();
    setIsModalOpenSaved(!isModalOpenSaved);
  };

  const closeModalSaved = () => {
    setIsModalOpenSaved(false);
  };

  /************************************************ */

  const [isModalOpenUpload, setIsModalOpenUpload] = useState(false);

  const toggleModalUpload = (
    refId,
    itemId,
    itemName,
    imgId,
    lensType,
    vouchNo,
    transId,
    linkedItm,
    mastId2
  ) => {
    setLoading(true);
    setRefId(refId);
    setItemData((prevValues) => ({
      ...prevValues,
      itemId: itemId,
      itemName: itemName,
      imgId: imgId,
      lensType: lensType,
      vouchNo: vouchNo,
      transId: transId,
      refId: refId,
      linkedItem: linkedItm,
      mastId2: mastId2,
    }));
    setIsModalOpenUpload(!isModalOpenUpload);
    setLoading(false);
  };

  const closeModalUpload = async () => {
    setIsModalOpenUpload(false);
  };

  /****************************************************** */

  const [isModalOpenCallBack, setIsModalOpenCallBack] = useState(false);

  const toggleModalCallBack = () => {
    setIsModalOpenCallBack(!isModalOpenCallBack);
  };

  const closeModalCallBack = () => {
    setIsModalOpenCallBack(false);
  };

  /*************************************************** */
  const toggleModalPower = (vouchNo, value) => {
    if (value == false) {
      setIsModalOpenPower({});
    } else {
      setIsModalOpenPower((prevState) => ({
        ...prevState,
        [vouchNo]: !prevState[vouchNo], // Toggle only the specific modal state
      }));
    }
    // setIsModalOpenPower(prevState => !prevState);
  };

  const [myOrderdata, setMyOrderData] = useState();

  const [lensData, setLensData] = useState();

  const fetchMyOrderData = async () => {
    setLoading(true);
    setTimeout(async () => {
      try {
        const userLogin = localStorage.getItem("accountLogin");
        const userArray = userLogin ? JSON.parse(userLogin) : null;
        if (userArray) {
          const data = await Transaction.getAccountOrder(userArray.d2Id);
          console.log(data);
          if (data && data.length > 0) {
            const filteredData = data.filter((item) => item.RecoType == 4);
            setMyOrderData(filteredData);
            // console.log("recoType4", filteredData)

             const refFilter =
              filteredData &&
              filteredData.filter(
                (item, index, self) =>
                  index ===
                  self.findIndex(
                    (t) =>
                      t.TransId === item.TransId
                    //&& t.RefId === item.RefId
                  )
              );

            console.log("refFilter", refFilter);
            // let lensDataa = [];
            // for (const data of filteredData) {
            //   try {
            //     // const tansLensData = await Transaction.GetTransLensData(data.RefId);
            //     const tansLensData = await Transaction.getSaleLensDataByTransId(
            //       data.TransId,
            //       0
            //     );
            //     lensDataa.push({
            //       refId: data.RefId,
            //       mastId2: data.MastId2,
            //       vouchNo: data.VouchNo,
            //       transaction: tansLensData,
            //       // transaction: tansLensData.result
            //     });
            //   } catch (error) {
            //     console.error(
            //       `Failed to load data for RefId: ${data.RefId}`,
            //       error
            //     );
            //     setLoading(false);
            //   }
            // }
            // console.log(lensDataa);
            // setLensData(lensDataa);
            setLoading(false);
          }
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }, 1000);
  };
  const [runCount, setRunCount] = useState(0);

  useEffect(() => {
    //if (runCount < 3) {
    fetchMyOrderData();
    //setRunCount(runCount + 1);
    //}
  }, []);

  function formatDate(dateString) {
    const date = new Date(dateString);

    // Get the day, month, year, hours, minutes, and AM/PM
    const day = String(date.getDate()).padStart(2, "0"); // Pad single digit day with leading zero
    const month = date.toLocaleString("default", { month: "short" }); // Get abbreviated month name (e.g., "Jan")
    const year = date.getFullYear();

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0"); // Pad single digit minutes with leading zero
    let ampm = "AM";

    if (hours >= 12) {
      ampm = "PM";
      if (hours > 12) {
        hours -= 12; // Convert 24-hour to 12-hour format
      }
    } else if (hours === 0) {
      hours = 12; // Handle midnight
    }

    // Format as dd Jan yyyy hh:mm AM/PM
    // return `${day} ${month} ${year} ${hours}:${minutes} ${ampm}`;
    return `${day} ${month} ${year}`;
  }

  const insertPayment = async (totalAmt) => {
    var dataRes = {
      status: true,
      message: "Authorization URL created",
      data: {
        authorization_url: "https://checkout.paystack.com/3ni8kdavz62431k",
        access_code: "3ni8kdavz62431k",
        reference: "re4lyvq3s3",
      },
    };

    var data = {
      email: "customer@email.com",
      amount: totalAmt, //"20000",
      currency: "KES",
      callback_url: "https://localhost:3000/PaymentResponse", //"https://lens2cart.com/PaymentResponse"
    };
    var response = await PayStack.CreatePayment(data);
    if (response.status === 200) {
      const url = response.data.data.authorization_url;
      localStorage.setItem(
        "PayStackPayment", (response.data.data.reference)
      );
      window.location.href = url;
    }
  };

  return (
    <div className="myOrderBoxScroll">
      {myOrderdata &&
        myOrderdata
          .filter(
            (item, index, self) =>
              index === self.findIndex((t) => t.RefId === item.RefId)
          )
          // .slice().reverse()
          .map((data, index) => {
            return (
              <div
                className={`custom-div my-order-table order-mybox-height`}
                id={data.RefId}
                key={data.RefId}
              >
                <div className="order-box-top-top">
                  <div className="order-box-top">
                    <div className="ord-detail-admin">
                      <div className="flex-ord-date-time">
                        <div className="flex-ord-no">
                          <p className="ordertxt-1-order">Order No </p>
                          <p className="ordertxt-1-ordno">:&nbsp;</p>
                          <p className="ordertxt-1-ordno">{data.VouchNo}</p>
                        </div>
                        <div className="flex-ord-no">
                          <p className="ordertxt-1-order">Order Date </p>
                          <p className="ordertxt-1-ordno">:&nbsp;</p>
                          <p className="ordertxt-1-ordno">
                            {formatDate(data.VouchDate)}
                          </p>
                        </div>
                      </div>
                      {/* <p className="ordertxt-2">
                                    Service Type : Table | Table No : PERGOLA 2
                                </p>
                                <p className="ordertxt-2">Delivery Date : 02/11/2023</p>
                                <p className="ordertxt-2">Delivery Time Slot : 17:00 To 17:30</p> */}
                    </div>

                    <div className="ord-detail-admin">

                    <div className="flex-ord-date-time">
                        <div className="flex-ord-no">
                          <p className="ordertxt-1-order">Total Price </p>
                          <p className="ordertxt-1-ordno">:&nbsp;</p>
                          <p className="ordertxt-1-ordno">
                        {companyData.currencySymbol} {(Number(data.BillAmt)).toFixed(2)}

                      </p>
                        </div>
                        <div className="flex-ord-no">
                          <p className="ordertxt-1-order">Total Discount </p>
                          <p className="ordertxt-1-ordno">:&nbsp;</p>
                          <p className="ordertxt-1-ordno">
                        {companyData.currencySymbol} {(Number(data.BillDiscount)).toFixed(2)}

                      </p>
                        </div>
                      </div>


                      
                     
                    </div>
                  </div>

                  {data.PaymentStatus == "False" && (
                    <div className="order-box-top pay-ord-border">
                      <div className="ord-detail-admin">
                        <div className="">
                          <div className="Complete-payment">
                            Complete Your Payment
                          </div>
                          <div className="processed-pymt">
                            Order will be processed after payment
                          </div>
                        </div>
                      </div>
                      <div className="order-detail-box-list-pay">
                        <button
                          className="btn-pay-now"
                          onClick={() => insertPayment(data.BillAmt)}
                        >
                          PAY NOW
                        </button>
                      </div>
                    </div>
                  )}
                  <div>
                    <div
                      style={{
                        borderRadius: "15px",
                        boxShadow: "none",
                        width: "100%",
                        padding: "0",
                        marginBottom: "0",
                      }}
                      className=""
                    >
                      {/* <label className="title"></label> */}
                      {data.PaymentStatus == "False" && (
                        <div className="flex-stop-pay">
                          <div className="elipse-stop-triang">
                            <i class="fa-solid fa-triangle-exclamation triangle-stop"></i>
                          </div>
                          <div className="power-submission-pay">
                            <div className="sub-payment">Payment</div>
                            <div className="processed-pymt">
                              Complete your payment & process order
                            </div>
                          </div>
                        </div>
                      )}
                      <div
                        className="favourite-box-container"
                        style={{ height: "auto", paddingBottom: "10px" }}
                      >
                        <div className="fav-box-width">
                          {/* {myOrderdata && myOrderdata
                                                    .filter(item => item.VouchNo == data.VouchNo)
                                                    // .filter((item, index, self) =>
                                                    //     index === self.findIndex((t) => (
                                                    //         t.LinkedItm === item.LinkedItm
                                                    //     ))
                                                    // )
                                                    .sort((a, b) => a.VouchNo == b.VouchNo)
                                                    .map((item, index) => ( */}

                          {myOrderdata &&
                            myOrderdata
                              .sort(
                                (a, b) => Number(b.TransId) - Number(a.TransId)
                              )
                              .filter((item) => item.VouchNo === data.VouchNo)
                              .reduce((acc, item) => {
                                // Group items by LinkedItm
                                const group = acc.find(
                                  (g) =>
                                    g.LinkedItm === item.LinkedItm &&
                                    g.LinkedItm !== "" &&
                                    g.LinkedItm !== "0"
                                );
                                if (group) {
                                  group.items.push(item);
                                } else {
                                  acc.push({
                                    LinkedItm: item.LinkedItm,
                                    items: [item],
                                  });
                                }
                                return acc;
                              }, [])
                              .map((group, groupIndex) => {
                                const groupedItems = group.items;
                                if (groupedItems.length === 2) {
                                  // Identify first and second items based on LensType
                                  const firstItem = groupedItems.find(
                                    (item) =>
                                      item.LensType == "NA" ||
                                      item.LensType == ""
                                  );
                                  const secondItem = groupedItems.find((item) =>
                                    [
                                      "0",
                                      "1",
                                      "2",
                                      "3",
                                      "4",
                                      "5",
                                      "6",
                                    ].includes(item.LensType)
                                  );
                                  if (firstItem) {
                                    return (
                                      <>
                                        <div
                                          className="flex-ord-sold"
                                          key={firstItem}
                                        >
                                          <div className="review-box-image order-image-box">
                                            <img
                                              className="favourite-img fav-image-ord"
                                              src={`${imageBaseUrl}${firstItem.IImgId}`}
                                              alt=""
                                            />
                                          </div>
                                          <div className="review-box-detail item-details-width-ord">
                                            <div
                                              className="review-box-options--theme3"
                                              style={{
                                                borderBottom: "none",
                                                padding: "0px",
                                              }}
                                            >
                                              <div className="name-top-item">
                                                {firstItem.MasterName}
                                              </div>
                                              <div className="prize-55">
                                                {companyData.currencySymbol}{" "}
                                                {firstItem.Qty *
                                                  (firstItem.PriceUnit -
                                                    Number(
                                                      firstItem.ItemDescriptn ||
                                                        0
                                                    ))}
                                              </div>
                                            </div>
                                            {/* <div className="review-box-detail-line-2">
                                                                                                <div className="lens-txt">
                                                                                                    Lens :{" "}
                                                                                                    <div className="review-product-name">
                                                                                                        Sold by Lens2cart Solutions Pvt Ltd.
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div style={{ display: "flex", alignItems: "center", width: "60px", }}>{" "}</div>
                                                                                            </div> */}
                                            <p
                                              className="review-price-item"
                                              style={{
                                                display: "flex",
                                                justifyContent: "flex-start",
                                              }}
                                            >
                                              <label
                                                style={{ color: "darkGrey" }}
                                              >
                                                Qty : {firstItem.Qty} x{" "}
                                                {companyData.currencySymbol}{" "}
                                                {firstItem.PriceUnit} ={" "}
                                                {companyData.currencySymbol}{" "}
                                                {firstItem.Qty *
                                                  firstItem.PriceUnit}
                                              </label>
                                              {/* <i className="fa-solid fa-indian-rupee-sign" style={{ marginTop: "10px" }}></i> */}
                                            </p>
                                            {secondItem?.MasterName &&
                                              secondItem?.MasterName.length >
                                                1 && (
                                                <div
                                                  className="review-box-options--theme3"
                                                  style={{
                                                    borderBottom: "none",
                                                    padding: "8px 0px",
                                                  }}
                                                >
                                                  <div className="Lens-color-cart3page">
                                                    LENS -{" "}
                                                    {secondItem?.MasterName}
                                                  </div>
                                                  <div className="prize-55">
                                                    {companyData.currencySymbol}{" "}
                                                    {secondItem?.PriceUnit}
                                                  </div>
                                                </div>
                                              )}
                                            {/* {secondItem?.coatingName && secondItem?.coatingName.length > 1 && (
                                                                                        <div className="review-box-options--theme3" style={{ borderBottom: "none", padding: "0px" }}>
                                                                                            <div className="Lens-color-cart3page">COATING - {secondItem?.coatingName}</div>
                                                                                            <div className="prize-55">
                                                                                                <i className="fa-solid fa-indian-rupee"></i> {secondItem?.coatingPrice}
                                                                                            </div>
                                                                                        </div>
                                                                                    )} */}
                                            {firstItem?.MasterDescriptn &&
                                              firstItem?.MasterDescriptn !=
                                                "" && (
                                                <div
                                                  className="review-box-options--theme3"
                                                  style={{
                                                    borderBottom: "none",
                                                    padding: "0px",
                                                  }}
                                                >
                                                  <div className="Lens-color-cart3page">
                                                    LENSES THICKNESS -{" "}
                                                    {firstItem?.MasterDescriptn}
                                                  </div>
                                                  <div className="prize-55">
                                                    {companyData.currencySymbol}{" "}
                                                    {Number(
                                                      firstItem?.ItemDescriptn
                                                    ) || 0}
                                                  </div>
                                                </div>
                                              )}
                                            {/* <p className="review-price-item" style={{ display: "flex", justifyContent: "flex-start" }}>
                                                                                        <label style={{ color: "darkGrey" }}>Qty : {secondItem?.Qty}  x   ₹{secondItem?.PriceUnit}  =  ₹{secondItem?.Qty * secondItem?.PriceUnit}</label>
                                                                                         <i className="fa-solid fa-indian-rupee-sign" style={{ marginTop: "10px" }}></i>
                                                                                    </p> */}
                                          </div>
                                        </div>
                                        {/* {( //lensData == null || lensData == undefined || //lens.refId == data.RefId &&
                                                                                lensData?.filter(lens => lens.refId == data.RefId && lens.mastId2 == firstItem.MastId2 && lens?.transaction?.length == 0).length > 0) && (
                                                                                    console.log("lensDataa", lensData),
                                                                                    <div className="flex-add-power" style={{ marginTop: "10px" }}>
                                                                                        <div>Submit "POWER" to complete order</div>
                                                                                        <div className="flex-chevron-icon" onClick={() => toggleModalPower(firstItem.MastId2)}>
                                                                                            <div className="Add-Power-ord">ADD POWER</div>
                                                                                            <div> <i className={`fa-solid ${isModalOpenPower ? 'fa-chevron-up' : 'fa-chevron-down'} icon-downUp-chev`} ></i></div>
                                                                                        </div>
                                                                                    </div>
                                                                                )} */}

                                        {/* {isModalOpenPower[secondItem?.MastId2] && (
                                                                                <div style={{ marginBottom: "1px solid lightGrey" }}>
                                                                                    <div className="flex-add-manually-prescription">
                                                                                        <div className="Prescription-manually" onClick={() => toggleModalManually(firstItem.RefId, secondItem?.MastId2, firstItem.MasterName, firstItem.IImgId, secondItem?.LensType, firstItem.VouchNo, firstItem.TransId, firstItem.LinkedItm, firstItem.MastId2)}>
                                                                                            <div className="Manually-pres-cart5">
                                                                                                <img className="prescription-image" src={enterManually} alt="" />
                                                                                            </div>
                                                                                            <div className="manually-name-prescription">Enter Prescription Manually</div>
                                                                                        </div>
                                                                                        <div className="Prescription-manually" onClick={() => toggleModalSaved(firstItem.RefId, secondItem?.MastId2, firstItem.MasterName, firstItem.IImgId, secondItem?.LensType, firstItem.VouchNo, firstItem.TransId, firstItem.LinkedItm, firstItem.MastId2)} style={{ marginLeft: "5px" }}>
                                                                                            <div className="Manually-pres-cart5">
                                                                                                <img className="prescription-image" src={Prescription} alt="" />
                                                                                            </div>
                                                                                            <div className="manually-name-prescription">Use Saved Prescription</div>
                                                                                        </div>
                                                                                        <div className="Prescription-manually" onClick={() => toggleModalUpload(firstItem.RefId, secondItem?.MastId2, firstItem.MasterName, firstItem.IImgId, secondItem?.LensType, firstItem.VouchNo, firstItem.TransId, firstItem.LinkedItm, firstItem.MastId2)} style={{ marginLeft: "5px" }}>
                                                                                            <div className="Manually-pres-cart5">
                                                                                                <img className="prescription-image" src={uploadPrescription} alt="" />
                                                                                            </div>
                                                                                            <div className="manually-name-prescription">Upload Prescription</div>
                                                                                        </div>
                                                                                        <div className="Prescription-manually" onClick={toggleModalCallBack} style={{ marginLeft: "5px" }}>
                                                                                            <div className="Manually-pres-cart5">
                                                                                                <img className="prescription-image" src={call} alt="" />
                                                                                            </div>
                                                                                            <div className="manually-name-prescription">Request A Call Back</div>
                                                                                        </div>
                                                                                    </div>
                                                                                   
                                                                                </div>
                                                                            )} */}

                                        {/* ************************ Enter prescription Manually *************** */}

                                        {/* {isModalOpenManually && (
                                                                                <div className="modal-back">
                                                                                    <div
                                                                                        style={{ zIndex: "5" }}
                                                                                        className="OpticalField-modal increase_modal_cart5_manually ">
                                                                                        <div className="model-manually-pres" style={{ padding: "5px" }}>
                                                                                            <div className="Modal-Title-Lc ">
                                                                                                <label
                                                                                                    className="close_modal-theme3"
                                                                                                    onClick={closeModalManually}
                                                                                                >
                                                                                                    <i class="fa-solid fa-xmark"></i>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="technical-scroll-detail model-manually">
                                                                                                <ModelPrescriptionManually onContinue={handleSubmitEyePower} isSubmitted={isSubmitted} refId={refId}
                                                                                                    itemData={itemData} closeModal={closeModalManually} setIsModalOpenPower={setIsModalOpenPower} fetchMyOrderData={fetchMyOrderData}
                                                                                                    toggleModalPower={toggleModalPower} setRunCount={setRunCount} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )} */}

                                        {/* ************************ Use Saved Prescription *************** */}
                                        {/* {isModalOpenSaved && (
                                                                                <div className="modal-back">
                                                                                    <div
                                                                                        style={{ zIndex: "5" }}
                                                                                        className="OpticalField-modal increase_modal_cart5"
                                                                                    >
                                                                                        <div className="model-manually-pres" style={{ padding: "5px" }}>
                                                                                            <div className="Modal-Title-Lc ">
                                                                                                <label
                                                                                                    className="close_modal-theme3"
                                                                                                    onClick={closeModalSaved}
                                                                                                >
                                                                                                    <i class="fa-solid fa-xmark"></i>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="technical-scroll-detail model-Saved-prescription" style={{ height: "85vh" }}>
                                                                                                <ModelSavedPrescription refId={refId} itemData={itemData} closeModal={closeModalSaved} isSelectShow={true}
                                                                                                    setIsModalOpenPower={setIsModalOpenPower} fetchMyOrderData={fetchMyOrderData} toggleModalPower={toggleModalPower}
                                                                                                    setRunCount={setRunCount} lensData={savedLensData} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )} */}

                                        {/* ************************ Upload Prescription *************** */}
                                        {/* {isModalOpenUpload && (
                                                                                <div className="modal-back">
                                                                                    <div style={{ zIndex: "5" }} className="OpticalField-modal increase_modal_cart5_manually">
                                                                                        <div className="model-manually-pres" style={{ padding: "5px" }}>
                                                                                            <div className="Modal-Title-Lc ">
                                                                                                <label className="close_modal-theme3" onClick={closeModalUpload}>
                                                                                                    <i class="fa-solid fa-xmark"></i>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="technical-scroll-detail model-upload-padd">
                                                                                                <ModelUploadPrescription refId={refId} itemData={itemData} setIsModalOpenPower={setIsModalOpenPower} closeModal={closeModalUpload}
                                                                                                    toggleModalPower={toggleModalPower} fetchMyOrderData={fetchMyOrderData} setRunCount={setRunCount} />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )} */}
                                        {/* ************************ Request a Call back *************** */}
                                        {/* {isModalOpenCallBack && (
                                                                                <div className="modal-back">
                                                                                    <div

                                                                                        style={{ zIndex: "5" }}
                                                                                        className="OpticalField-modal increase_modal_cart5_manually ">
                                                                                        <div className="model-manually-pres" style={{ padding: "5px" }}>
                                                                                            <div className="Modal-Title-Lc ">
                                                                                                <label
                                                                                                    className="close_modal-theme3"
                                                                                                    onClick={closeModalCallBack}
                                                                                                >
                                                                                                    <i class="fa-solid fa-xmark"></i>
                                                                                                </label>
                                                                                            </div>
                                                                                            <div className="technical-scroll-detail model-manually">
                                                                                                <ModelRequestACallBack />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            )} */}

                                        {/* {isSubmitted && ( */}
                                        {lensData && lensData.length > 0 ? (
                                          (() => {
                                            const filteredLenses =
                                              lensData.filter(
                                                (lense) =>
                                                  lense.refId ==
                                                    firstItem.RefId &&
                                                  lense.mastId2 ==
                                                    secondItem?.MastId2 &&
                                                  lense.transaction?.some(
                                                    (item1) =>
                                                      item1.rItemId_d2 ==
                                                      secondItem.MastId2
                                                  ) &&
                                                  // lense.transaction?.filter(item1 => item1.rItemId_d2 == secondItem?.MastId2) &&
                                                  lense.transaction?.length > 0
                                              );
                                            console.log(
                                              "filterData",
                                              filteredLenses
                                            );
                                            console.log(
                                              "filterData1",
                                              firstItem
                                            );
                                            console.log(
                                              "filterData2",
                                              secondItem
                                            );
                                            if (filteredLenses.length == 0) {
                                              return (
                                                <div>
                                                  {/* <div className="flex-add-power" style={{ marginTop: "10px" }} onClick={() => toggleModalPower(secondItem?.MastId2)}>
                                                                                                    <div>Submit "POWER" to complete order</div>
                                                                                                    <div className="flex-chevron-icon">
                                                                                                        <div className="Add-Power-ord">ADD POWER</div>
                                                                                                        <div> <i className={`fa-solid ${isModalOpenPower ? 'fa-chevron-up' : 'fa-chevron-down'} icon-downUp-chev`} ></i></div>
                                                                                                    </div>
                                                                                                </div> */}
                                                </div>
                                              );
                                            } else if (
                                              filteredLenses &&
                                              filteredLenses[0]?.transaction &&
                                              filteredLenses[0]?.transaction[0]
                                                ?.imgId != "" &&
                                              filteredLenses[0]?.transaction[0]
                                                ?.imgId != "0"
                                            ) {
                                              return (
                                                <div style={{}}>
                                                  <img
                                                    className="favourite-img fav-image-ord"
                                                    style={{ height: "150px" }}
                                                    src={`${imageBaseUrl}${filteredLenses[0]?.transaction[0]?.imgId}`}
                                                    alt="My Prescription"
                                                  />
                                                </div>
                                              );
                                            } else {
                                              return (
                                                <>
                                                  {filteredLenses &&
                                                    filteredLenses[0].transaction
                                                      .filter(
                                                        (item1) =>
                                                          item1.rItemId_d2 ==
                                                          secondItem?.MastId2
                                                      )
                                                      .map(
                                                        (lens, idx) => (
                                                          console.log(
                                                            "lensee",
                                                            lens
                                                          ),
                                                          // console.log("lensee1", lens?.filter(item1 => item1.rItemId_d2 == firstItem.MastId2)),
                                                          console.log(
                                                            "lensee2",
                                                            filteredLenses[0]
                                                              .transaction
                                                          ),
                                                          (
                                                            <div key={idx}>
                                                              <div className="Prescription-show-table">
                                                                {lens?.partyName !=
                                                                  null &&
                                                                  lens?.partyName !=
                                                                    "" && (
                                                                    <div className="customer-name-order">
                                                                      {
                                                                        lens?.partyName
                                                                      }
                                                                    </div>
                                                                  )}
                                                                <div className="overflow-table-pres">
                                                                  <table className="table-prescription-data-ord">
                                                                    <thead>
                                                                      <tr>
                                                                        {secondItem?.LensType ==
                                                                          "1" ||
                                                                        secondItem?.LensType ==
                                                                          "3" ||
                                                                        secondItem?.LensType ==
                                                                          "5" ||
                                                                        secondItem?.LensType ==
                                                                          "6" ? ( //Bifocal, Progressive, Tinted Bifocal, Tinted Progressive
                                                                          <>
                                                                            <th></th>
                                                                            <th className="table-power-font">
                                                                              Spherical(SPH)
                                                                            </th>
                                                                            <th className="table-power-font">
                                                                              Cylindrical(CYL)
                                                                            </th>
                                                                            <th className="table-power-font">
                                                                              Axis(0-180)
                                                                            </th>
                                                                            <th className="table-power-font">
                                                                              Additional
                                                                              Power(AP)
                                                                            </th>
                                                                          </>
                                                                        ) : (
                                                                          <>
                                                                            <th></th>
                                                                            <th className="table-power-font">
                                                                              Spherical(SPH)
                                                                            </th>
                                                                            <th className="table-power-font">
                                                                              Cylindrical(CYL)
                                                                            </th>
                                                                            <th className="table-power-font">
                                                                              Axis(0-180)
                                                                            </th>
                                                                          </>
                                                                        )}
                                                                      </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                      <tr>
                                                                        {secondItem?.LensType ==
                                                                          "1" ||
                                                                        secondItem?.LensType ==
                                                                          "3" ||
                                                                        secondItem?.LensType ==
                                                                          "5" ||
                                                                        secondItem?.LensType ==
                                                                          "6" ? ( // 0- Single, 4-Tinted Single
                                                                          <>
                                                                            <td>
                                                                              LEFT
                                                                            </td>
                                                                            <td className="prescription-number">
                                                                              {
                                                                                lens?.lSphNv
                                                                              }
                                                                            </td>
                                                                            <td className="prescription-number">
                                                                              {
                                                                                lens?.lCylNv
                                                                              }
                                                                            </td>
                                                                            <td className="prescription-number">
                                                                              {
                                                                                lens?.lAxisNv
                                                                              }
                                                                            </td>
                                                                            <td className="prescription-number">
                                                                              {
                                                                                lens?.lAdd
                                                                              }
                                                                            </td>
                                                                          </>
                                                                        ) : (
                                                                          <>
                                                                            <td>
                                                                              LEFT
                                                                            </td>
                                                                            <td className="prescription-number">
                                                                              {
                                                                                lens?.lSphDv
                                                                              }
                                                                            </td>
                                                                            <td className="prescription-number">
                                                                              {
                                                                                lens?.lCylDv
                                                                              }
                                                                            </td>
                                                                            <td className="prescription-number">
                                                                              {
                                                                                lens?.lAxisDv
                                                                              }
                                                                            </td>
                                                                          </>
                                                                        )}
                                                                      </tr>
                                                                      <tr>
                                                                        {secondItem?.LensType ==
                                                                          "1" ||
                                                                        secondItem?.LensType ==
                                                                          "3" ||
                                                                        secondItem?.LensType ==
                                                                          "5" ||
                                                                        secondItem?.LensType ==
                                                                          "6" ? ( //Bifocal
                                                                          <>
                                                                            <td>
                                                                              RIGHT
                                                                            </td>
                                                                            <td className="prescription-number">
                                                                              {
                                                                                lens?.rSphNv
                                                                              }
                                                                            </td>
                                                                            <td className="prescription-number">
                                                                              {
                                                                                lens?.rCylNv
                                                                              }
                                                                            </td>
                                                                            <td className="prescription-number">
                                                                              {
                                                                                lens?.rAxisNv
                                                                              }
                                                                            </td>
                                                                            <td className="prescription-number">
                                                                              {
                                                                                lens?.rAdd
                                                                              }
                                                                            </td>
                                                                          </>
                                                                        ) : (
                                                                          <>
                                                                            <td>
                                                                              RIGHT
                                                                            </td>
                                                                            <td className="prescription-number">
                                                                              {
                                                                                lens?.rSphDv
                                                                              }
                                                                            </td>
                                                                            <td className="prescription-number">
                                                                              {
                                                                                lens?.rCylDv
                                                                              }
                                                                            </td>
                                                                            <td className="prescription-number">
                                                                              {
                                                                                lens?.rAxisDv
                                                                              }
                                                                            </td>
                                                                          </>
                                                                        )}
                                                                      </tr>
                                                                    </tbody>
                                                                  </table>
                                                                </div>
                                                              </div>
                                                            </div>
                                                          )
                                                        )
                                                      )}
                                                </>
                                              );
                                            }
                                          })()
                                        ) : (
                                          <div></div>
                                        )}
                                      </>
                                    );
                                  }
                                }
                                return groupedItems.map((item, index) => (
                                  <div
                                    key={`item-${groupIndex}-${index}`}
                                    className="flex-ord-sold"
                                  >
                                    <div className="review-box-image order-image-box">
                                      <img
                                        className="favourite-img fav-image-ord"
                                        src={`${imageBaseUrl}${item.IImgId}`}
                                        alt=""
                                      />
                                    </div>
                                    <div className="review-box-detail item-details-width-ord">
                                      <div
                                        className="review-box-options--theme3"
                                        style={{
                                          borderBottom: "none",
                                          padding: "0px",
                                        }}
                                      >
                                        <div className="name-top-item">
                                          {item.MasterName}
                                        </div>
                                        <div className="prize-55">
                                          {companyData.currencySymbol}{" "}
                                          {item.Qty * item.PriceUnit}
                                        </div>
                                      </div>
                                      <p
                                        className="review-price-item"
                                        style={{
                                          display: "flex",
                                          justifyContent: "flex-start",
                                        }}
                                      >
                                        <label style={{ color: "darkGrey" }}>
                                          Qty : {item.Qty} x{" "}
                                          {companyData.currencySymbol}{" "}
                                          {item.PriceUnit} ={" "}
                                          {companyData.currencySymbol}{" "}
                                          {item.Qty * item.PriceUnit}
                                        </label>
                                      </p>
                                    </div>
                                  </div>
                                ));
                              })}
                          {/* ********************************* Order Status ******************************** */}
                          <div className="flex-order-delivery">
                            <div className="order-process">
                              <div className="Green-Line"></div>
                              <div className="order-start-icon">
                                <i class="fa-solid fa-cart-plus"></i>
                              </div>
                              <div className="process-by-order order-color">
                                Order Created
                              </div>
                            </div>
                            <div className="order-process">
                              <div className="Green-Line-dispatch"></div>
                              <div className="circle-icon-ord">
                                <i class="fa-regular fa-circle"></i>
                              </div>
                              <div className="process-by-order">
                                Prepare For Dispatch
                              </div>
                            </div>
                            <div className="order-process">
                              <div className="Green-Line-way"></div>
                              <div className="circle-icon-ord">
                                <i class="fa-regular fa-circle"></i>
                              </div>
                              <div className="process-by-order">On the Way</div>
                            </div>
                            <div className="order-process">
                              <div className="circle-icon-ord">
                                <i class="fa-regular fa-circle"></i>
                              </div>
                              <div className="process-by-order">Delivered</div>
                            </div>
                          </div>
                          {/* <div>
                                                    <div>
                                                        <div></div>
                                                        <div>Enter Priscription Manually</div>
                                                    </div>
                                              </div> */}
                        </div>

                        {/* ************************* Overall Total ************************** */}
                        <>
                          <div className="flex-ord-details">
                            <div
                              className="order-details-open"
                              onClick={() => toggleClass(data.VouchNo)}
                            >
                              Order Details
                            </div>
                          </div>
                          {isClicked[data.VouchNo] && (
                            <div className="order-box-top">
                              <div className="address-detail-width">
                                <p className="ordertxt-1-delivery">
                                  Delivery Address
                                </p>
                                <p className="ordertxt-1-name">
                                  {data.EcomDelAddress}
                                </p>
                                {/* <p className="ordertxt-2-address">
                                                                Adarsh Nagar
                                                            </p>
                                                            <p className="ordertxt-2-address">Durg - 492001</p>
                                                            <p className="ordertxt-2-address">Chhattisgarh, India</p>
                                                            <p className="ordertxt-2-email">techcherry@gmail.com</p>
                                                            <p className="ordertxt-2-number">+91-9876543210</p> */}
                              </div>
                              <div className="address-detail-width">
                                <p className="ordertxt-1-delivery">
                                  Price Breakup:
                                </p>
                                <div className="flex-subtotal">
                                  <p className="ordertxt-1-subtotal">
                                    Subtotal
                                  </p>
                                  <p className="ordertxt-1-subtotal">
                                    {companyData.currencySymbol} {(Number(data.BillAmt)).toFixed(2)}

                                  </p>
                                </div>
                                <div className="flex-autodiscount">
                                  <p className="ordertxt-1-subtotal">
                                    Auto Discount
                                  </p>
                                  <p className="ordertxt-1-subtotal">
                                    {companyData.currencySymbol} 0
                                  </p>
                                </div>
                                <div>
                                  <div className="flex-subtotal-netamt">
                                    <p className="ordertxt-1-Amount">
                                      Net Amount
                                    </p>
                                    <p className="ordertxt-1-Amount">
                                    {companyData.currencySymbol} {(Number(data.BillAmt)).toFixed(2)}

                                    </p>
                                  </div>
                                  <div className="flex-subtotal">
                                    <p className="ordertxt-1-Convenience">
                                      Convenience Fees
                                    </p>
                                    <p className="ordertxt-1-Convenience">
                                      Free
                                    </p>
                                  </div>
                                </div>
                                <div className="flex-subtotal-netamt">
                                  <p className="ordertxt-1-Amount">Total</p>
                                  <p className="ordertxt-1-Amount">
                                    {companyData.currencySymbol} {(Number(data.BillAmt)).toFixed(2)}

                                  </p>
                                </div>
                              </div>
                            </div>
                          )}
                        </>

                        {/* <div className="favourite-box">
                                                <div className="review-box-image">
                                                    <img className="favourite-img"
                                                        src="https://happychashma.com/shop/images/product/Green_Year_9017_Metal_Half_Rim_52_Rectangle_Black_502_1.jpg"
                                                        alt=""
                                                    />
                                                </div>
                                                <div className="review-box-detail">
                                                    <div className="review-box-detail-line-1">
                                                        <div className="review-box-name">
                                                            Peyush Bansal Shark Tank Monza Red Full Rim Hustler
                                                            Eyeglasses....
                                                        </div>
                                                        <div className="review-box-section">
                                                            <img className="fa-trash" src={dlt} alt="" />
                                                        </div>
                                                    </div>
                                                    <div className="review-box-detail-line-2">
                                                        <div className="lens-txt">
                                                            Lens :{" "}
                                                            <div className="review-product-name">
                                                                Black Full Frame Eyeglasses
                                                            </div>
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems: "center",
                                                                width: "60px",
                                                            }}
                                                        >
                                                            {" "}
                                                            <p className="review-price">
                                                                <i className="fa-solid fa-indian-rupee-sign"></i> 1500
                                                            </p>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      <div>
        {/* <div
                    style={{
                        display: "flex",
                        alignItems: "flex-end",
                        justifyContent: "flex-end",
                        width: "100%",
                        margin: "5px 0px"
                    }}
                >
                    <button
                        onClick={() => previousPage()}
                        //disabled={!canPreviousPage}
                        className="table-list-button"
                    >
                        <i className="fa-solid fa-chevron-left"></i>
                    </button>
                    <span className="table-list-page-txt">
                        Page -
                        <strong style={{ margin: "0px 3px" }}>
                            {pageIndex}
                        </strong>
                    </span>
                    <button
                        onClick={() => nextPage()}
                        //disabled={!canNextPage}
                        className="table-list-button"
                    >
                        <i className="fa-solid fa-chevron-right"></i>
                    </button>
                </div> */}
      </div>
      {loading && <Loader1 />}
    </div>
  );
};

export default OrderBox_Lc;
